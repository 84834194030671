import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import { ErrorMessage } from 'components/ErrorMessage';
import { PasswordSetupForm } from 'components/forms/PasswordSetupForm';

import { useAuth } from 'hooks/useAuth';
import { usePasswordSetupChallenge } from 'api/auth';
import { ChallengeName } from 'consts/auth';
import { AuthPasswordSetupInputSchema } from 'api/models/AuthPasswordSetupInputSchema';

export const PasswordSetup = () => {
  const { username } = useAuth();
  const { error, isLoading, mutate } = usePasswordSetupChallenge();

  const handleSubmit = ({ password }: AuthPasswordSetupInputSchema) => {
    mutate({
      password,
      username,
      challenge_type: ChallengeName.NEW_PASSWORD_REQUIRED,
    });
  };

  return (
    <>
      <Text mb={2} align="center" fontSize="xl" fontWeight="semibold">
        Set up password
      </Text>
      <Text mb={4} align="center">
        It is your first login to the TCC. Please reset your temporary password.
      </Text>
      <Box p={4} bg="white" boxShadow="base" borderRadius="base">
        <PasswordSetupForm onSubmit={handleSubmit} isLoading={isLoading} fieldErrors={error?.fields} />
        <ErrorMessage message={error?.error} />
      </Box>
    </>
  );
};
