import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BoxProps, Text } from '@chakra-ui/react';

import { Autocomplete } from '.';
import { HighlightedText } from 'components/HighlightedText';

import { debounce } from 'utils/utils';
import { resolveUrl } from 'utils/resolveUrl';
import { routes } from 'consts/routes';
import { useBleDeviceSuggestionsQuery } from 'api/bleDevices';
import { BleDeviceSchema } from 'api/models/BleDeviceSchema';

export const BleDevicesAutocomplete = (styleProps: BoxProps) => {
  const [query, setQuery] = useState<string>('');
  const navigate = useNavigate();
  const { data, isLoading } = useBleDeviceSuggestionsQuery(query);

  const debouncedSetQuery = debounce(setQuery, 300);

  return (
    <Autocomplete
      items={data}
      placeholder="Search by name, mac"
      renderSuggestion={BleDeviceSuggestion}
      onInputChange={debouncedSetQuery}
      onSuggestionClick={(bleDevice) =>
        navigate(resolveUrl(routes.BLE_DEVICES.DETAILS, { bleDeviceId: bleDevice.mac }, {}, { noEncode: true }))
      }
      isLoading={isLoading}
      {...styleProps}
    />
  );
};

export const BleDeviceSuggestion = (bleDevice: BleDeviceSchema, query: string) => {
  if (!bleDevice.name) {
    return (
      <Text fontWeight="semibold">
        <HighlightedText text={bleDevice.mac} highlight={query} />
      </Text>
    );
  }
  return (
    <Text fontWeight="semibold">
      <HighlightedText text={bleDevice.name} highlight={query} />
      <Text as="span">{' - '}</Text>
      <HighlightedText text={bleDevice.mac} highlight={query} />
    </Text>
  );
};
