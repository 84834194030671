import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useToast } from '@chakra-ui/react';

import { ChallengeName } from 'consts/auth';
import { routes } from 'consts/routes';
import { useInitialQuery } from 'api/auth';
import { AuthLoginSchema } from 'api/models/AuthLoginSchema';
import { ErrorSchema } from 'api/models/ErrorSchema';
import { UserSchema } from 'api/models/UserSchema';

type ChallengeType = AuthLoginSchema['ChallengeName'];
type ChallengeParamsType = AuthLoginSchema['ChallengeParameters'];

type AuthContextType = {
  login: (challenge: ChallengeType, challengeParams?: ChallengeParamsType) => void;
  logout: () => void;
  isInitial: boolean;
  isLoggedIn: boolean;
  username: string;
  loggedUser?: UserSchema;
};

type AuthProviderProps = {
  children: React.ReactNode;
};

const AuthContext = React.createContext<AuthContextType | null>(null);

const useAuthHook = (): AuthContextType => {
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const { data: loggedUser, isSuccess, isError, error, refetch } = useInitialQuery();
  const [isInitial, setIsInitial] = useState<boolean>(true);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('');

  // Set the logged-in state when the initial query is fetched successfully
  useEffect(() => {
    // Navigate to the login page only if an unauthorized error occurred (401)
    if (isSuccess) {
      setIsLoggedIn(true);
    } else if (error && error.status !== 401) {
      setIsLoggedIn(true);
      toast({
        description: error?.error || 'Something went wrong getting user information.',
        status: 'error',
        isClosable: true,
      });
    }
  }, [isSuccess, error, toast]);

  // Set the initial state manually (don't use isLoading from useInitialQuery) due to the differences between updates (re-renders) other states
  useEffect(() => {
    if (isSuccess || isError) {
      setIsInitial(false);
    }
  }, [isSuccess, isError]);

  // Refetch user info (initial query) after login to the app
  useEffect(() => {
    isLoggedIn && error?.status === 401 && refetch();
  }, [isLoggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  const login = (challenge: ChallengeType, challengeParams?: ChallengeParamsType) => {
    if (challengeParams?.USER_ID_FOR_SRP) {
      setUsername(challengeParams.USER_ID_FOR_SRP);
    }

    switch (challenge) {
      case ChallengeName.NEW_PASSWORD_REQUIRED:
        navigate(routes.AUTH.PASSWORD_SETUP);
        break;
      case ChallengeName.MFA_SETUP:
        navigate(routes.AUTH.MFA_SETUP);
        break;
      case ChallengeName.SOFTWARE_TOKEN_MFA:
        navigate(routes.AUTH.MFA_VERIFICATION, {
          state: location.state,
        });
        break;
      default:
        setIsLoggedIn(true);
        break;
    }
  };

  const logout = () => {
    setIsLoggedIn(false);
  };

  return {
    login,
    logout,
    username,
    loggedUser,
    isLoggedIn,
    isInitial,
  };
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('Missing AuthProvider');
  }

  return context;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const auth = useAuthHook();
  const queryClient = useQueryClient();
  const toast = useToast();

  useEffect(() => {
    // Handle an unauthorized error for logged user
    const handleError = (error: ErrorSchema) => {
      const { logout, isLoggedIn } = auth;

      if (error.status === 401 && isLoggedIn) {
        toast({
          description: `${error.error} Please login again.`,
          status: 'error',
          isClosable: true,
        });
        logout();
      }
    };

    // Define default options and handlers for all the requests
    queryClient.setDefaultOptions({
      queries: {
        retry: 1,
        onError: (error) => handleError(error as ErrorSchema),
      },
      mutations: {
        retry: 1,
        onError: (error) => handleError(error as ErrorSchema),
      },
    });
  }, [auth, queryClient, toast]);

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
