import { useState } from 'react';
import { Flex, List, ListIcon, ListItem, Text } from '@chakra-ui/react';
import { BsTrash } from 'react-icons/bs';

import { Autocomplete } from 'components/Autocomplete';
import { DeviceSuggestion } from 'components/Autocomplete/DevicesAutocomplete';
import { ConfirmDeleteAlert } from 'components/ConfirmDeleteAlert';
import { ErrorMessage } from 'components/ErrorMessage';
import { Loader } from 'components/Loader';

import { debounce } from 'utils/utils';
import { useDeviceSuggestionsQuery } from 'api/devices';
import { useDeviceAdd, useDeviceDelete, useGroupDevicesQuery } from 'api/deviceGroups';
import { DeviceSchema } from 'api/models/DeviceSchema';

type GroupDevicesFormProps = {
  groupId: string;
};

export const GroupDevicesForm = ({ groupId }: GroupDevicesFormProps) => {
  const [query, setQuery] = useState<string>('');
  const [deletedDevice, setDeletedDevice] = useState<number>();

  // TODO Check whether fetching device group details is necessary
  const { data: devices, isFetching: isDevicesFetching } = useGroupDevicesQuery(groupId);
  const { data: suggestions, isLoading: isSuggestionsLoading } = useDeviceSuggestionsQuery(query);
  const { error: addingError, mutate: addDevice, isLoading: isAdding } = useDeviceAdd(groupId);
  const {
    error: deletingError,
    mutate: deleteDevice,
    isLoading: isDeleting,
  } = useDeviceDelete(groupId, deletedDevice, () => setDeletedDevice(undefined));

  const debouncedSetQuery = debounce(setQuery, 300);
  const handleDeviceAdd = (device: DeviceSchema) => {
    addDevice({ devices_ids: [device.id] });
  };

  return (
    <>
      <Text py="2" fontSize="sm">
        Devices
      </Text>
      <Autocomplete
        items={suggestions}
        placeholder="Add device to the group"
        renderSuggestion={DeviceSuggestion}
        onInputChange={debouncedSetQuery}
        onSuggestionClick={handleDeviceAdd}
        isLoading={isSuggestionsLoading}
      />
      <List spacing={1} mt={2} position="relative" overflowY="auto" maxHeight="240px">
        <ListItem>
          <Loader isLoading={isDevicesFetching || isAdding || isDeleting} size="sm" />
        </ListItem>
        {devices?.devices?.map((device) => (
          <ListItem key={device.id} _even={{ bg: 'gray.100' }} _hover={{ bg: 'aquaLight' }}>
            <Flex px={2} py={1} alignItems="center" justifyContent="space-between">
              <>
                {device.id}
                <ListIcon
                  as={BsTrash}
                  title="Delete device"
                  m={0}
                  color="red.500"
                  cursor="pointer"
                  onClick={() => setDeletedDevice(device.id)}
                />
              </>
            </Flex>
          </ListItem>
        ))}
      </List>
      <ErrorMessage message={addingError?.error || deletingError?.error} />
      <ConfirmDeleteAlert
        isOpen={!!deletedDevice}
        isLoading={isDeleting}
        onCancel={() => setDeletedDevice(undefined)}
        onDelete={deleteDevice}
        header={`Deleting device: ${deletedDevice}`}
      />
    </>
  );
};
