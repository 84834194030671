import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';

import { BleDeviceSchema } from 'api/models/BleDeviceSchema';
import { useNavigate } from 'react-router-dom';
import { useBleDeviceNoticesQuery, useBleDeviceQuery } from 'api/bleDevices';
import { routes } from 'consts/routes';
import { resolveUrl } from 'utils/resolveUrl';

type SelectedBleDeviceNoticeContextType = {
  selectedBleDevice: BleDeviceSchema | null;
  setSelectedBleDevice: Dispatch<SetStateAction<BleDeviceSchema | null>>;
};

type SelectedBleDeviceNoticeProviderProps = {
  children: React.ReactNode;
};

const SelectedBleDeviceNoticeContext = React.createContext<SelectedBleDeviceNoticeContextType | null>(null);

export const useSelectedBleDevice = () => {
  const context = useContext(SelectedBleDeviceNoticeContext);
  if (!context) {
    throw new Error('Missing SelectedBleDeviceNoticeProvider');
  }
  const { selectedBleDevice, setSelectedBleDevice } = context;
  const navigate = useNavigate();
  const [bleDeviceId, setBleDeviceId] = useState<string>();

  const { data: deviceDetails } = useBleDeviceQuery(bleDeviceId || '', (data) => {
    setSelectedBleDevice(data);
  });

  const { data: bleDeviceNotices } = useBleDeviceNoticesQuery(bleDeviceId, undefined);

  useEffect(() => {
    if (deviceDetails && bleDeviceNotices) {
      const bleDevice = deviceDetails.ble_devices?.[0];
      const bleDeviceLastNotice = bleDeviceNotices.pages?.[0].notices?.[0];
      handleSelectedBleDeviceChange({ ...bleDevice, location: bleDeviceLastNotice.location });
    }
  }, [deviceDetails, bleDeviceNotices]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectedBleDeviceChange = (bleDevice: BleDeviceSchema | string | null) => {
    if (typeof bleDevice === 'string') {
      // Fetch POI details (the case of multiple POIs at the same place)
      setBleDeviceId(bleDevice);
    } else {
      setSelectedBleDevice(bleDevice);
      !bleDevice
        ? navigate(routes.BLE_DEVICES.ROOT)
        : navigate(resolveUrl(routes.BLE_DEVICES.DETAILS, { bleDeviceId: bleDevice.mac }));
    }
  };

  return {
    selectedBleDevice,
    setSelectedBleDevice: handleSelectedBleDeviceChange,
  };
};

export const SelectedBleDeviceProvider = ({ children }: SelectedBleDeviceNoticeProviderProps) => {
  const [selectedBleDevice, setSelectedBleDevice] = useState<BleDeviceSchema | null>(null);

  return (
    <SelectedBleDeviceNoticeContext.Provider
      value={{
        selectedBleDevice,
        setSelectedBleDevice,
      }}
    >
      {children}
    </SelectedBleDeviceNoticeContext.Provider>
  );
};
