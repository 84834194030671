import React from 'react';
import { Box, Heading } from '@chakra-ui/react';

import { DeviceGroupsList } from 'components/DeviceGroupsList';

export const DeviceGroups = () => {
  return (
    <>
      <Box mx={4} mt={4}>
        <Heading as="h5" size="sm">
          Device groups
        </Heading>
      </Box>
      <DeviceGroupsList />
    </>
  );
};
