import React, { useContext, useReducer } from 'react';

import { DeviceHistorySchema } from 'api/models/DeviceHistorySchema';
import { BleDeviceNoticeSchema } from 'api/models/BleDeviceNoticeSchema';

export enum ActionKind {
  ShowPath = 'SHOW_PATH',
  HidePath = 'HIDE_PATH',
  ShowBLEPath = 'SHOW_BLE_PATH',
  HideBLEPath = 'HIDE_BLE_PATH',
  ShowThresholds = 'SHOW_THRESHOLDS',
  HideThresholds = 'HIDE_THRESHOLDS',
  EnableHours = 'ENABLE_HOURS',
  DisableHours = 'DISABLE_HOURS',
  TurnOnWideScreen = 'TURN_ON_WIDE_SCREEN',
  TurnOffWideScreen = 'TURN_OFF_WIDE_SCREEN',
  SetDeviceHistory = 'SET_DEVICE_HISTORY',
  SetBLEDeviceHistory = 'SET_BLE_DEVICE_HISTORY',
  ClearState = 'CLEAR_STATE',
}

type StateType = {
  isPathVisible: boolean;
  isBLEPathVisible: boolean;
  areThresholdsVisible: boolean;
  isHoursEnabled: boolean;
  isWideScreen: boolean;
  deviceHistory: DeviceHistorySchema['history'] | null;
  bleDeviceHistory: BleDeviceNoticeSchema[] | null;
};

type ActionType = {
  type: ActionKind;
  payload?: DeviceHistorySchema['history'];
  bleDevicesNotices?: BleDeviceNoticeSchema[];
};

type DeviceHistoryContextType = {
  state: StateType;
  dispatch: React.Dispatch<ActionType>;
};

type DeviceHistoryProviderProps = {
  children: React.ReactNode;
};

const initialState = {
  isPathVisible: false,
  isBLEPathVisible: false,
  areThresholdsVisible: true,
  isHoursEnabled: false,
  isWideScreen: false,
  deviceHistory: null,
  bleDeviceHistory: null,
};

const reducer = (state: StateType, action: ActionType) => {
  switch (action.type) {
    case ActionKind.ShowPath:
      return { ...state, isPathVisible: true };
    case ActionKind.HidePath:
      return { ...state, isPathVisible: false };
    case ActionKind.ShowBLEPath:
      return { ...state, isBLEPathVisible: true };
    case ActionKind.HideBLEPath:
      return { ...state, isBLEPathVisible: false };
    case ActionKind.ShowThresholds:
      return { ...state, areThresholdsVisible: true };
    case ActionKind.HideThresholds:
      return { ...state, areThresholdsVisible: false };
    case ActionKind.EnableHours:
      return { ...state, isHoursEnabled: true };
    case ActionKind.DisableHours:
      return { ...state, isHoursEnabled: false };
    case ActionKind.TurnOnWideScreen:
      return { ...state, isWideScreen: true };
    case ActionKind.TurnOffWideScreen:
      return { ...state, isWideScreen: false };
    case ActionKind.SetDeviceHistory:
      return { ...state, deviceHistory: action.payload ?? null };
    case ActionKind.SetBLEDeviceHistory:
      return { ...state, bleDeviceHistory: action.bleDevicesNotices ?? null };
    case ActionKind.ClearState:
      return initialState;
  }
};

const DeviceHistoryContext = React.createContext<DeviceHistoryContextType>({
  state: initialState,
  dispatch: () => null,
});

export const useMapHistory = () => {
  const context = useContext(DeviceHistoryContext);
  if (!context) {
    throw new Error('Missing DeviceHistoryProvider');
  }

  return context;
};

export const DeviceHistoryProvider = ({ children }: DeviceHistoryProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <DeviceHistoryContext.Provider value={{ state, dispatch }}>{children}</DeviceHistoryContext.Provider>;
};
export default useMapHistory;
