import React from 'react';
import { Flex, Button, Radio, RadioGroup, Divider } from '@chakra-ui/react';
import { object, SchemaOf, string } from 'yup';

import { Form } from 'components/forms/Form';
import { Select } from 'components/forms/Select';
import { SubmitButton } from 'components/forms/SubmitButton';

import { useForm } from 'hooks/useForm';
import { PoiGroupDeleteSchema } from 'api/models/PoiGroupDeleteSchema';
import { PoiGroupSchema } from 'api/models/PoiGroupSchema';

type PoiGroupDeleteFormProps = {
  poiGroups?: PoiGroupSchema[];
  onSubmit: (values: PoiGroupDeleteSchema) => void;
  onCancel: () => void;
  isLoading?: boolean;
};

type PoiGroupDeleteFormValuesSchema = { deleteType: string; group?: string };

enum PoiGroupDeleteType {
  delete = 'delete',
  deleteAndMove = 'deleteAndMove',
  deleteAndDelete = 'deleteAndDelete',
}

const validationSchema: SchemaOf<PoiGroupDeleteFormValuesSchema> = object({
  deleteType: string().required().default(PoiGroupDeleteType.delete),
  group: string().when('deleteType', {
    is: (value: string) => value === PoiGroupDeleteType.deleteAndMove,
    then: string().required(),
    otherwise: string(),
  }),
});

export const PoiGroupDeleteForm = ({ poiGroups, onSubmit, onCancel, isLoading }: PoiGroupDeleteFormProps) => {
  const form = useForm<PoiGroupDeleteFormValuesSchema>({
    validationSchema,
  });
  const selectedOption = form.watch('deleteType');

  const handleSubmit = ({ deleteType, group }: PoiGroupDeleteFormValuesSchema) => {
    const payload: PoiGroupDeleteSchema = {};

    if (deleteType === PoiGroupDeleteType.deleteAndMove) {
      payload.new_group_id = group;
    } else if (deleteType === PoiGroupDeleteType.deleteAndDelete) {
      payload.with_pois = true;
    }

    onSubmit(payload);
  };

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <RadioGroup
        name="deleteType"
        defaultValue={PoiGroupDeleteType.delete}
        colorScheme="primaryScheme"
        onChange={(value) => form.setValue('deleteType', value as string)}
      >
        <Radio value={PoiGroupDeleteType.delete}>Delete the group</Radio>
        <Divider my={4} borderColor="gray.400" />
        <Radio value={PoiGroupDeleteType.deleteAndMove} mb={2}>
          Delete the group and move POIs to another group
        </Radio>
        <Select
          name="group"
          placeholder="Select group..."
          isLabelOnTop={true}
          isDisabled={selectedOption !== PoiGroupDeleteType.deleteAndMove}
          items={poiGroups?.map(({ id, name }) => ({ value: id, label: name }))}
        />
        <Divider my={4} borderColor="gray.400" />
        <Radio value={PoiGroupDeleteType.deleteAndDelete}>Delete the group and all the POIs from this group</Radio>
      </RadioGroup>
      <Flex justifyContent="space-between" mt={8}>
        <Button onClick={onCancel}>Cancel</Button>
        <SubmitButton isLoading={isLoading}>Delete</SubmitButton>
      </Flex>
    </Form>
  );
};
