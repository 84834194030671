import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';

import { resolveUrl } from 'utils/resolveUrl';
import { routes } from 'consts/routes';
import { useDeviceSuggestionsQuery } from 'api/devices';
import { DeviceSchema } from 'api/models/DeviceSchema';

type SelectedDeviceContextType = {
  selectedDevice: DeviceSchema | null;
  setSelectedDevice: Dispatch<SetStateAction<DeviceSchema | null>>;
};

type SelectedDeviceProviderProps = {
  children: React.ReactNode;
};

const SelectedDeviceContext = React.createContext<SelectedDeviceContextType | null>(null);

export const useSelectedDevice = () => {
  const context = useContext(SelectedDeviceContext);
  if (!context) {
    throw new Error('Missing SelectedDeviceProvider');
  }

  const { deviceId: deviceIdParam } = useParams<{ deviceId: string }>();
  const navigate = useNavigate();
  const toast = useToast();
  const [query, setQuery] = useState<string>();
  const { data: deviceDetails } = useDeviceSuggestionsQuery(query);
  const { selectedDevice, setSelectedDevice } = context;

  useEffect(() => {
    if (deviceDetails?.length) {
      handleSelectedDeviceChange(deviceDetails[0], deviceIdParam !== query);
    } else if (deviceDetails && !deviceDetails.length) {
      deviceIdParam && navigate(routes.DEVICES.ROOT);
      toast({
        description: `You don't have access to this device.`,
        status: 'error',
        isClosable: true,
      });
    }
  }, [deviceDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectedDeviceChange = (device: DeviceSchema | string | number | null, redirect: boolean = true) => {
    if (typeof device === 'string' || typeof device === 'number') {
      // Fetch device details (cases: multiple devices at the same place and start app with device details)
      setQuery(device.toString());
    } else {
      setSelectedDevice(device);
      if (redirect) {
        !device ? navigate(routes.DEVICES.ROOT) : navigate(resolveUrl(routes.DEVICES.DETAILS, { deviceId: device.id }));
      }
    }
  };

  return {
    selectedDevice,
    setSelectedDevice: handleSelectedDeviceChange,
  };
};

export const SelectedDeviceProvider = ({ children }: SelectedDeviceProviderProps) => {
  const [selectedDevice, setSelectedDevice] = useState<DeviceSchema | null>(null);

  return (
    <SelectedDeviceContext.Provider value={{ selectedDevice, setSelectedDevice }}>
      {children}
    </SelectedDeviceContext.Provider>
  );
};
