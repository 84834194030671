import { Box } from '@chakra-ui/react';
import React from 'react';
interface CollapseProps {
  isOpen?: boolean;
  children?: React.ReactNode;
  height?: number;
}
export const Collapse = ({ isOpen, children, height, ...styleProps }: CollapseProps) => {
  return (
    <Box
      maxHeight={isOpen ? height ?? 250 : 0}
      opacity={isOpen ? 1 : 0}
      transition="max-height 0.3s ease,opacity 0.6s ease"
      overflow={isOpen ? '' : 'hidden'}
      {...styleProps}
    >
      {children}
    </Box>
  );
};
export default Collapse;
