import { useNavigate } from 'react-router-dom';
import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { RiBarcodeFill } from 'react-icons/ri';

import { resolveUrl } from 'utils/resolveUrl';
import { routes } from 'consts/routes';

import { BleDeviceSchema } from 'api/models/BleDeviceSchema';

type BleDeviceListItemProps = {
  bleDevice: BleDeviceSchema;
  isDeviceMode?: boolean;
};

export const BleDeviceListItem = ({ bleDevice, isDeviceMode }: BleDeviceListItemProps) => {
  const navigate = useNavigate();
  const { mac, name } = bleDevice;

  return (
    <Box px={4} py={2} _odd={{ bg: 'gray.100' }} _hover={{ bg: 'aquaLight' }}>
      <Link
        onClick={() => navigate(resolveUrl(routes.BLE_DEVICES.DETAILS, { bleDeviceId: mac }, {}, { noEncode: true }))}
        variant="unstyled"
      >
        <Flex alignItems="center" p="2">
          <RiBarcodeFill className="inline-icon" />
          <Text>
            <Text as="span" pl={1}>
              {mac} {name && '-'} {name}
            </Text>
          </Text>
        </Flex>
      </Link>
    </Box>
  );
};
