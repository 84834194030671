import { Controller, FieldError, useFormContext } from 'react-hook-form';
import {
  FormControlProps,
  Input as ChakraInput,
  Switch,
  Textarea,
  ThemingProps,
  FormLabel,
  Text,
} from '@chakra-ui/react';
import { Options } from 'flatpickr/dist/types/options';

import { DatePicker } from 'components/DatePicker';
import { FormControl } from 'components/forms/FormControl';

import { get } from 'utils/utils';
import { InputSwitchLabeledProps, SwitchValueDefaultLabels, SwitchValue } from './Switch';
import { InputTextareaProps } from './Textarea';

export type InputBaseProps = FormControlProps & {
  type: 'text' | 'number' | 'textarea' | 'switch' | 'switch-labeled' | 'date' | 'password' | 'hidden';
  name: string;
  placeholder?: string;
  label?: string;
  size?: ThemingProps['size'];
  isLabelOnTop?: boolean;
  datePickerOptions?: Options;
  disabled?: boolean;
};

type InputProps = InputBaseProps & InputSwitchLabeledProps & InputTextareaProps;

export const Input = ({
  type,
  name,
  placeholder,
  label,
  size,
  isLabelOnTop,
  datePickerOptions,
  disabled,
  switchConfig,
  textareaConfig,
  ...formControlStyles
}: InputProps) => {
  const {
    control,
    register,
    formState: { errors },
    watch,
  } = useFormContext();
  const error: FieldError = get(errors, name);
  const value = watch(name);
  const registered = register(name);

  const renderField = () => {
    switch (type) {
      case 'switch':
      case 'switch-labeled':
        const labels = switchConfig || SwitchValueDefaultLabels;
        const label = value ? labels[value as SwitchValue] : labels.false;

        return (
          <FormLabel size={size} htmlFor={name} textAlign="end">
            {type === 'switch-labeled' && (
              <Text as="span" fontSize="sm" pr="4">
                {label}
              </Text>
            )}
            <Switch
              id={name}
              defaultChecked={value}
              disabled={disabled}
              placeholder={placeholder}
              size={size}
              {...registered}
            />
          </FormLabel>
        );
      case 'textarea':
        return (
          <Textarea
            disabled={disabled}
            placeholder={placeholder}
            size={size}
            rows={2}
            resize="none"
            focusBorderColor="primary"
            {...textareaConfig}
            {...registered}
          />
        );
      case 'date': {
        return (
          <Controller
            control={control}
            name={name}
            render={({ field: { name, value, ref, onBlur, ...rest }, formState }) => {
              const defaultValue = value && !formState.touchedFields[name] ? `${value}` : undefined;
              return (
                <DatePicker
                  inputProps={{ focusBorderColor: 'primary', size, placeholder, name, onBlur }}
                  inputRef={ref as () => void}
                  options={datePickerOptions}
                  asyncDefaultDate={defaultValue}
                  {...rest}
                />
              );
            }}
          />
        );
      }
      default:
        return (
          <ChakraInput
            disabled={disabled}
            type={type}
            placeholder={placeholder}
            size={size}
            focusBorderColor="primary"
            step={type === 'number' ? 'any' : undefined}
            {...registered}
          />
        );
    }
  };

  return (
    <FormControl error={error} label={label} isLabelOnTop={isLabelOnTop} {...formControlStyles}>
      {renderField()}
    </FormControl>
  );
};
