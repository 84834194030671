import React, { useEffect } from 'react';
import { Box, Button, Center } from '@chakra-ui/react';

import { Loader } from 'components/Loader';
import { PoisListItem } from './PoisListItem';

import { usePoisGeofencePaginatedQuery } from 'api/poisGeofence';
import { paginationPageSize } from 'consts/common';
import { PoiGeofenceSchema } from 'api/models/PoiGeofenceSchema';

type PoisListProps = {
  pois?: PoiGeofenceSchema[];
  totalPois: number;
  isLoading: boolean;
};

/**
 * PoisList displays a list of POIs, which consists of the 'POIs' prop (the first page of paginated data)
 * and the 'data' variable (the rest of the pages). It is because the POIs list needs to be synchronized with data
 * displayed on the map. If the map is, for example, zoomed, only the first page will be shown.
 */
export const PoisList = ({ pois, totalPois, isLoading }: PoisListProps) => {
  const {
    data,
    clearData,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage = totalPois > paginationPageSize,
  } = usePoisGeofencePaginatedQuery();

  useEffect(() => {
    // Clear paginated data when POIs list is updated (display only the first page)
    if (data) {
      clearData();
    }
  }, [pois]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box position="relative" minHeight={16} overflowY="auto">
      {pois?.map((poi) => (
        <PoisListItem key={poi.id} poi={poi} />
      ))}
      {data?.pages?.map((page, index) => (
        <React.Fragment key={index}>
          {page.pois?.map((poi) => (
            <PoisListItem key={poi.id} poi={poi} />
          ))}
        </React.Fragment>
      ))}
      {hasNextPage && (
        <Center py={4}>
          <Button
            onClick={() => fetchNextPage()}
            isLoading={isFetchingNextPage}
            colorScheme="primaryScheme"
            variant="outline"
          >
            Load more
          </Button>
        </Center>
      )}
      <Loader isLoading={isLoading} top={4} />
    </Box>
  );
};
