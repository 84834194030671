import React, { useEffect, useState } from 'react';
import { Box, Button, Center } from '@chakra-ui/react';

import { BleDevicesNoticesListItem } from './BleDevicesNoticesListItem';
import { DatePicker } from 'components/DatePicker';
import { Loader } from 'components/Loader';

import { useBleDevicesNoticesQuery, useBleDevicesQuery } from 'api/bleDevices';
import { DateRangeSchema } from 'api/models/DateRangeSchema';
import { BleDeviceSchema } from 'api/models/BleDeviceSchema';
import { BleDeviceNoticeSchema } from 'api/models/BleDeviceNoticeSchema';

const endDate = new Date();

export const BleDevicesNoticesList = () => {
  const [devicesData, setDevicesData] = useState<BleDeviceSchema[]>([]);
  const [rangeParams, setRangeParams] = useState<DateRangeSchema | undefined>();
  const {
    data: bleDevices,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useBleDevicesNoticesQuery(rangeParams);
  useEffect(() => {
    if (bleDevices) {
      const notices: BleDeviceNoticeSchema[] = [];
      bleDevices.pages.forEach((page) => {
        if (page.notices) {
          notices.push(...page.notices);
        }
      });
    }
  }, [bleDevices]);

  const macs = bleDevices?.pages.reduce((newMacs, c) => {
    Array.from(new Set(c.notices.map((n) => n.mac))).forEach((mac) => {
      if (devicesData.findIndex((device) => device.mac === mac) === -1) {
        newMacs.push(mac);
      }
    });

    return newMacs;
  }, new Array<string>());

  useBleDevicesQuery(macs?.length ? macs : undefined, (data) => {
    if (data?.ble_devices) {
      setDevicesData([...devicesData, ...data.ble_devices]);
    }
  });

  return (
    <>
      <Box mx={4} mb={4}>
        <DatePicker
          onChange={setRangeParams}
          inputProps={{ focusBorderColor: 'primary', placeholder: 'Filter by time range', allowClear: true }}
          options={{
            maxDate: endDate,
            mode: 'range',
            enableTime: true,
            allowInput: true,
          }}
        />
      </Box>
      <Box position="relative" minHeight={16} overflowY="auto">
        {bleDevices?.pages?.map((page, pageIndex) => (
          <React.Fragment key={pageIndex}>
            {page.notices?.map((bleDevice, itemIndex) => (
              <BleDevicesNoticesListItem
                key={`${pageIndex}-${itemIndex}`}
                notice={bleDevice}
                device={devicesData.find((i) => i.mac === bleDevice.mac)}
              />
            ))}
          </React.Fragment>
        ))}
        {hasNextPage && (
          <Center py={4}>
            <Button
              onClick={() => fetchNextPage()}
              isLoading={isFetchingNextPage}
              colorScheme="primaryScheme"
              variant="outline"
              size="sm"
            >
              Load more
            </Button>
          </Center>
        )}
        <Loader isLoading={isLoading} top={4} />
      </Box>
    </>
  );
};
