import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  fonts: {
    body: 'Open Sans',
    heading: 'Open Sans',
  },
  colors: {
    overlay: 'hsla(0, 0%, 0%, 0.1)',
    aqua: 'hsl(195, 51%, 49%)',
    aquaLight: 'hsl(195, 51%, 90%)',
    primary: 'hsl(216, 69%, 23%)',
    primaryLight: 'hsl(216, 69%, 94%)',
    primaryScheme: {
      '50': '#e5eefa',
      '100': '#a9c5ef',
      '200': '#6c9ce4',
      '300': '#3074d9',
      '400': '#1e54a4',
      '500': '#123363',
      '600': '#123363',
      '700': '#123363',
      '800': '#123363',
      '900': '#123363',
    },
  },
  sizes: {
    headerHeight: '46px',
    sidebarWidth: '370px',
    doubleSidebarWidth: '700px',
    fullSidebarWidth: '50vw',
  },
  styles: {
    global: {
      body: {
        backgroundColor: 'gray.50',
      },
      '#root': {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
      },
      '.inline-icon': {
        display: 'inline-block',
        verticalAlign: 'middle',
      },
    },
  },
  components: {
    Alert: {
      sizes: {
        sm: {
          container: {
            px: 2,
            py: 1,
            fontSize: 'sm',
          },
          icon: {
            width: 4,
            height: 5,
          },
        },
      },
    },
    Link: {
      variants: {
        primary: ({ colorScheme = 'primaryScheme' }) => ({
          color: `${colorScheme}.500`,
          _hover: {
            color: `${colorScheme}.400`,
          },
        }),
        unstyled: {},
      },
      defaultProps: {
        variant: 'primary',
      },
    },
  },
});
