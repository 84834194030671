export enum ObjectType {
  'device.battery' = 'device.battery',
  'device.data' = 'device.data',
  'device.position' = 'device.position',
  'device.firmware' = 'device.firmware',
  'device.flight' = 'device.flight',
  'device.item' = 'device.item',
  'device.trip' = 'device.trip',
}

export enum ActionType {
  'charged' = 'charged',
  'charging' = 'charging',
  'discharging' = 'discharging',
  'running_low' = 'running_low',
  'changed' = 'changed',
  'entered' = 'entered',
  'exited' = 'exited',
  'updated' = 'updated',
  'update_started' = 'update_started',
  'missed_flight' = 'missed_flight',
  'risk_detected' = 'risk_detected',
  'arrived' = 'arrived',
  'completed' = 'completed',
  'delayed' = 'delayed',
  'left_gate' = 'left_gate',
  'took_off' = 'took_off',
  'landed' = 'landed',
  'cancelled' = 'cancelled',
  'etd_changed' = 'etd_changed',
  'eta_changed' = 'eta_changed',
  'std_changed' = 'std_changed',
  'atd_changed' = 'atd_changed',
}

export const actionTypes = {
  [ObjectType['device.battery']]: [
    ActionType['charged'],
    ActionType['charging'],
    ActionType['discharging'],
    ActionType['running_low'],
  ],
  [ObjectType['device.data']]: [ActionType['changed']],
  [ObjectType['device.position']]: [ActionType['entered'], ActionType['exited'], ActionType['changed']],
  [ObjectType['device.firmware']]: [ActionType['updated'], ActionType['update_started']],
  [ObjectType['device.flight']]: [
    ActionType['left_gate'],
    ActionType['took_off'],
    ActionType['landed'],
    ActionType['arrived'],
    ActionType['delayed'],
    ActionType['cancelled'],
    ActionType['etd_changed'],
    ActionType['eta_changed'],
    ActionType['std_changed'],
    ActionType['atd_changed'],
  ],
  [ObjectType['device.item']]: [ActionType['missed_flight'], ActionType['risk_detected'], ActionType['arrived']],
  [ObjectType['device.trip']]: [ActionType['completed'], ActionType['delayed']],
} as const;

export enum SeverityType {
  'info' = 'info',
  'warning' = 'warning',
  'failure' = 'failure',
  'fatal' = 'fatal',
}

export const severityTypes = [
  SeverityType.info,
  SeverityType.warning,
  SeverityType.failure,
  SeverityType.fatal,
] as const;

export const statusTypes = ['occurred', 'pending', 'sent', 'failed', 'retry'] as const;

export const webhookStatuses = ['pending', 'sent', 'failed', 'retry'] as const;
