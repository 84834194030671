import React, { useRef, useState } from 'react';
import { startOfToday, subWeeks } from 'date-fns';
import { Box, Button, Center, Text, useDisclosure } from '@chakra-ui/react';
import { IoChevronDown } from 'react-icons/io5';

import { NewNotificationsListItem } from './NewNotificationsListItem';
import { Collapse } from 'components/Collapse';
import { DatePicker } from 'components/DatePicker';
import { Loader } from 'components/Loader';
import { NewNotificationFiltersForm } from 'components/forms/NewNotificationFiltersForm';

import { usePaginationCursor } from 'hooks/usePaginationCursor';
import { toUtc } from 'utils/datetime';
import { useNewNotificationsQuery } from 'api/notifications';
import { DateRangeSchema } from 'api/models/DateRangeSchema';
import { NewNotificationSchema } from 'api/models/NewNotificationSchema';
import { NewNotificationFiltersSchema } from 'api/models/NewNotificationFiltersSchema';

const startDate = startOfToday();

export const NewNotificationsList = () => {
  const endDate = useRef<Date>(new Date());
  const [timeRange, setTimeRange] = useState<DateRangeSchema>({
    start: toUtc(startDate).toISOString(),
    end: endDate.current.toISOString(),
  });
  const [filters, setFilters] = useState<NewNotificationFiltersSchema | null>(null);
  const { isOpen: areFiltersOpen, onToggle: onFiltersToggle } = useDisclosure();
  const { data, isFetching } = useNewNotificationsQuery(timeRange, filters);
  const { dataToDisplay, isNextPage, nextPage } = usePaginationCursor<NewNotificationSchema>(data?.events);

  return (
    <>
      <Text mb={1} fontSize="sm" fontWeight="semibold">
        Time range
      </Text>
      <DatePicker
        onChange={setTimeRange}
        inputProps={{ size: 'sm', focusBorderColor: 'primary' }}
        options={{
          defaultDate: [startDate, endDate.current],
          minDate: subWeeks(endDate.current, 6),
          maxDate: endDate.current,
          mode: 'range',
          enableTime: true,
          allowInput: true,
        }}
      />
      <Button
        mt={2}
        size="sm"
        variant="unstyled"
        colorScheme="primaryScheme"
        rightIcon={<IoChevronDown />}
        onClick={onFiltersToggle}
      >
        Filters
      </Button>
      <Collapse isOpen={areFiltersOpen}>
        <NewNotificationFiltersForm onSubmit={setFilters} formSize="sm" />
      </Collapse>
      <Box position="relative" minHeight={16} mt={4}>
        {!isFetching && dataToDisplay.length === 0 ? (
          <Text>There is no notifications history</Text>
        ) : (
          dataToDisplay?.map((event) => <NewNotificationsListItem key={event.id} notification={event} />)
        )}
        {isNextPage && (
          <Center py={4}>
            <Button onClick={nextPage} colorScheme="primaryScheme" variant="outline" size="sm">
              Load more
            </Button>
          </Center>
        )}
        <Loader isLoading={isFetching} top={4} />
      </Box>
    </>
  );
};
