import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, Link, Spinner, Text, Tooltip } from '@chakra-ui/react';
import { IoBusinessSharp, IoLocationSharp, IoMoon } from 'react-icons/io5';
import { RiBarcodeFill } from 'react-icons/ri';

import { useSelectedBleDevice } from 'hooks/useSelectedBleDeviceNotice';
import { formatDatetime, fromNow } from 'utils/datetime';
import { resolveUrl } from 'utils/resolveUrl';
import { routes } from 'consts/routes';
import { BleDeviceNoticeSchema } from 'api/models/BleDeviceNoticeSchema';
import { BleDeviceSchema } from 'api/models/BleDeviceSchema';

type BleDevicesNoticesListItemProps = {
  notice: BleDeviceNoticeSchema;
  device?: BleDeviceSchema;
};

export const BleDevicesNoticesListItem = ({ notice, device }: BleDevicesNoticesListItemProps) => {
  const navigate = useNavigate();
  const { setSelectedBleDevice } = useSelectedBleDevice();

  const { created_at, device_id, location, filter_alias } = notice;

  if (!device) {
    return (
      <Flex py={2}>
        <Spinner mx="auto" />
      </Flex>
    );
  }

  return (
    <Flex px={4} py={2} alignItems="center" _odd={{ bg: 'gray.100' }} _hover={{ bg: 'aquaLight' }}>
      <Box width="50%">
        <Text>
          <Link
            onClick={() => {
              setSelectedBleDevice({ ...device, location: notice.location });
              navigate(resolveUrl(routes.BLE_DEVICES.DETAILS, { bleDeviceId: device.mac }, {}, { noEncode: true }));
            }}
            variant="unstyled"
          >
            {device.name || notice.mac}
          </Link>
        </Text>
      </Box>
      <Box fontSize="xs">
        <Text>
          <IoMoon className="inline-icon" />
          <Text as="span" pl={1}>
            <Tooltip label={formatDatetime(created_at)}>{fromNow(created_at)}</Tooltip>
          </Text>
        </Text>
        <Text>
          <RiBarcodeFill className="inline-icon" />
          <Text as="span" pl={1}>
            {device_id}
          </Text>
        </Text>
        <Text>
          <IoLocationSharp className="inline-icon" />
          <Text as="span" pl={1}>
            <Link
              onClick={() => {
                setSelectedBleDevice({ ...device, location: notice.location });
              }}
              variant="unstyled"
            >
              {`${location.lat?.toFixed(4)}, ${location.lon?.toFixed(4)}`}
            </Link>
          </Text>
        </Text>
        <Text>
          <IoBusinessSharp className="inline-icon" />
          <Text as="span" pl={1}>
            <Link>{filter_alias}</Link>
          </Text>
        </Text>
      </Box>
    </Flex>
  );
};
