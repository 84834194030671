import React from 'react';
import { Alert, AlertIcon, AlertProps } from '@chakra-ui/react';

type ErrorMessageProps = AlertProps & {
  message?: string;
};

export const ErrorMessage = ({ message, ...props }: ErrorMessageProps) => {
  if (!message) {
    return null;
  }

  return (
    <Alert status="error" size="sm" mt={2} {...props}>
      <AlertIcon />
      {message}
    </Alert>
  );
};
