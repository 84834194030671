import React from 'react';
import { object, string, SchemaOf, ref } from 'yup';

import { Form } from 'components/forms/Form';
import { Input } from 'components/forms/Input';
import { SubmitButton } from 'components/forms/SubmitButton';

import { useForm } from 'hooks/useForm';
import { passwordPolicy } from 'consts/auth';
import { AuthPasswordResetConfirmationInputSchema } from 'api/models/AuthPasswordResetConfirmationInputSchema';
import { ErrorSchema } from 'api/models/ErrorSchema';

type PasswordResetConfirmationFormProps = {
  onSubmit: (values: AuthPasswordResetConfirmationInputSchema) => void;
  isLoading?: boolean;
  fieldErrors?: ErrorSchema<AuthPasswordResetConfirmationInputSchema>['fields'];
};

const validationSchema: SchemaOf<AuthPasswordResetConfirmationInputSchema> = object({
  username: string().required(),
  password: string().matches(passwordPolicy.regExp, passwordPolicy.errorMsg).required(),
  matching_password: string()
    .oneOf([ref('password')], 'Passwords must match')
    .required(),
  confirmation_code: string().required(),
});

export const PasswordResetConfirmationForm = ({
  onSubmit,
  isLoading,
  fieldErrors,
}: PasswordResetConfirmationFormProps) => {
  const form = useForm<AuthPasswordResetConfirmationInputSchema>({ validationSchema, fieldErrors });

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Input type="text" name="username" label="Username" isLabelOnTop={true} />
      <Input type="password" name="password" label="New password" isLabelOnTop={true} />
      <Input type="password" name="matching_password" label="Confirm new password" isLabelOnTop={true} />
      <Input type="text" name="confirmation_code" label="Confirmation code" isLabelOnTop={true} />
      <SubmitButton isLoading={isLoading} width="full" mt={2}>
        Save
      </SubmitButton>
    </Form>
  );
};
