import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import { ErrorMessage } from 'components/ErrorMessage';
import { PasswordResetForm } from 'components/forms/PasswordResetForm';

import { usePasswordReset } from 'api/auth';

export const PasswordReset = () => {
  const { error, isLoading, mutate } = usePasswordReset();

  return (
    <>
      <Text mb={2} align="center" fontSize="xl" fontWeight="semibold">
        Reset password
      </Text>
      <Text mb={4} align="center">
        Please enter your username to reset your password.
      </Text>
      <Box p={4} bg="white" boxShadow="base" borderRadius="base">
        <PasswordResetForm onSubmit={mutate} isLoading={isLoading} fieldErrors={error?.fields} />
        <ErrorMessage message={error?.error} />
      </Box>
    </>
  );
};
