import React, { useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
} from '@chakra-ui/react';

import { DevicesList } from 'components/DevicesList';
import { DevicesAutocomplete } from 'components/Autocomplete/DevicesAutocomplete';
import { DeviceFiltersForm } from 'components/forms/DeviceFiltersForm';

import { useMainData } from 'pages/Main';
import { useDeviceFilters } from 'hooks/useDeviceFilters';
import { DeviceFiltersSchema } from 'api/models/DeviceFiltersSchema';

const getFiltersNumber = (filters?: DeviceFiltersSchema) => {
  return filters ? Object.values(filters).filter((v) => v !== '').length : 0;
};

export const Devices = () => {
  const { devicesData, isDevicesDataLoading } = useMainData();
  const { deviceFilters, setDeviceFilters } = useDeviceFilters();
  const [activeFiltersNumber, setActiveFiltersNumber] = useState<number>(getFiltersNumber(deviceFilters));

  const handleFiltersChange = (values: DeviceFiltersSchema) => {
    setActiveFiltersNumber(getFiltersNumber(values));
    setDeviceFilters(values);
  };

  const handleFiltersReset = () => {
    setActiveFiltersNumber(0);
    setDeviceFilters(undefined);
  };

  return (
    <>
      <Box m={4}>
        <Heading as="h5" size="sm">
          Devices
        </Heading>
        <DevicesAutocomplete my={4} />
        <Accordion allowToggle>
          <AccordionItem borderWidth="1px" borderRadius="base">
            {({ isExpanded }) => (
              <>
                <h2>
                  <AccordionButton borderBottomWidth={isExpanded ? '1px' : undefined}>
                    <Box flex="1" textAlign="left">
                      Map filters ({activeFiltersNumber})
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel position="relative" px={4} py={2}>
                  <DeviceFiltersForm
                    onSubmit={handleFiltersChange}
                    onReset={handleFiltersReset}
                    initialValues={deviceFilters}
                  />
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>
      </Box>
      <DevicesList
        devices={devicesData?.devices}
        total={devicesData?.count ?? 0}
        isLoading={isDevicesDataLoading ?? false}
      />
    </>
  );
};
