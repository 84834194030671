export enum PoiType {
  system = 'system',
  partner = 'partner',
  trip = 'trip',
}

export enum SystemPoiSubtype {
  airport = 'airport',
  port = 'port',
}
