import React from 'react';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';

import { ErrorMessage } from 'components/ErrorMessage';
import { PoiGroupForm } from 'components/forms/PoiGroupForm';

import { useDefaultPoiGroupsQuery, usePoiGroupUpdate, usePoiGroupQuery } from 'api/poiGroups';
import { PoiGroupSchema } from 'api/models/PoiGroupSchema';

type EditPoiGroupModalProps = {
  groupId: string;
  onClose: () => void;
};

export const EditPoiGroupModal = ({ groupId, onClose }: EditPoiGroupModalProps) => {
  const { error, mutate, isLoading } = usePoiGroupUpdate(groupId, onClose);
  const { data: defaultData } = useDefaultPoiGroupsQuery();
  const { data: groupDetails } = usePoiGroupQuery(groupId);

  // Transform PoiGroupSchema into PoiGroupInputSchema
  const { id, ...initialValues } = groupDetails || ({} as PoiGroupSchema);

  return (
    <Modal isOpen={!!groupId} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Editing POI group</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <PoiGroupForm
            onSubmit={mutate}
            initialValues={initialValues}
            defaultValues={defaultData}
            isLoading={isLoading}
            fieldErrors={error?.fields}
          />
          <ErrorMessage message={error?.error} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
