import React from 'react';
import { Button, Flex, Heading, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { routes } from 'consts/routes';

export const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Flex flex={1} flexDirection="column" alignItems="center" justifyContent="center">
      <Heading as="h1" size="2xl">
        Page not found
      </Heading>
      <Text fontSize="xl" my={4}>
        We are sorry, but we can't find the page you are looking for.
      </Text>
      <Button colorScheme="primaryScheme" onClick={() => navigate(routes.ROOT)}>
        Go to homepage
      </Button>
    </Flex>
  );
};
