/**
 * A customized popup showed on the map.
 * Based on https://developers.google.com/maps/documentation/javascript/examples/overlay-popup
 */

export type PopupType = google.maps.OverlayView & { setPosition: Function };

export class Popup extends google.maps.OverlayView {
  popup: HTMLDivElement;
  position: google.maps.LatLng | null;
  isInitialized: boolean;
  onClose?: Function;

  constructor(content: HTMLDivElement, onClose?: Function) {
    super();
    this.position = null;
    this.onClose = () => {
      onClose?.();
    };

    const close = document.createElement('div');
    if (close) {
      close.classList.add('gmap-popup-close');
    }

    if (content) {
      content.classList.add('gmap-popup-content');
      content.appendChild(close);
    }

    const container = document.createElement('div');

    if (container && content) {
      container.classList.add('gmap-popup-container');
      container.appendChild(content);
    }

    this.popup = document.createElement('div');
    if (this.popup) {
      this.popup.classList.add('gmap-popup-wrapper');
      this.popup.appendChild(container);
    }

    // Field to prevent multiple events calling
    this.isInitialized = false;

    // Stop clicks, etc., from popup up to the map
    Popup.preventMapHitsAndGesturesFrom(this.popup);
  }

  /** Called when the popup is added to the map. */
  onAdd() {
    if (!this.isInitialized) {
      this.isInitialized = true;
      const closeButton = this.popup.querySelector('.gmap-popup-close');

      closeButton?.addEventListener('click', () => {
        this.setMap(null);
        this.onClose?.();
      });
    }

    this.getPanes()!.floatPane.appendChild(this.popup);
  }

  /** Called when the popup is removed from the map. */
  onRemove() {
    if (this.popup.parentElement) {
      this.popup.parentElement.removeChild(this.popup);
    }
  }

  /** Called each frame when the popup needs to draw itself. */
  draw() {
    const divPosition = this.getProjection().fromLatLngToDivPixel(this.position)!;

    // Hide the popup when it is far out of view
    const display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? 'block' : 'none';

    if (display === 'block') {
      this.popup.style.left = divPosition.x + 'px';
      this.popup.style.top = divPosition.y + 'px';
    }

    if (this.popup.style.display !== display) {
      this.popup.style.display = display;
    }
  }

  /** Closes the popup. */
  close() {
    const closeButton: HTMLElement | null = this.popup.querySelector('.popup-close');
    closeButton?.click();
  }

  /** Sets up the position. */
  setPosition(position: google.maps.LatLng) {
    const currentPosition = this.position;
    this.position = position;
    currentPosition && this.draw();
  }
}
