import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import { ErrorMessage } from 'components/ErrorMessage';
import { LoginForm } from 'components/forms/LoginForm';

import { useLogin } from 'api/auth';

export const Login = () => {
  const { error, isLoading, mutate } = useLogin();

  return (
    <>
      <Text mb={4} align="center" fontSize="xl" fontWeight="semibold">
        Log in to TCC
      </Text>
      <Box p={4} bg="white" boxShadow="base" borderRadius="base">
        <LoginForm onSubmit={mutate} isLoading={isLoading} fieldErrors={error?.fields} />
        <ErrorMessage message={error?.error} />
      </Box>
    </>
  );
};
