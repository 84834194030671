import React from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Box, IconButton, Tooltip, VStack } from '@chakra-ui/react';
import {
  RiArrowGoBackLine,
  RiBluetoothLine,
  RiInformationLine,
  RiLineChartLine,
  RiMapPinFill,
  RiSettings3Line,
} from 'react-icons/ri';
import { IoAirplaneOutline } from 'react-icons/io5';

import { useMapHistory } from 'hooks/useMapHistory';
import { useMapInstance } from 'hooks/useMapInstance';
import { useSelectedDevice } from 'hooks/useSelectedDevice';
import { useSelectedPoi } from 'hooks/useSelectedPoi';
import { useSelectedBleDevice } from 'hooks/useSelectedBleDeviceNotice';
import { resolveUrl } from 'utils/resolveUrl';
import { routes } from 'consts/routes';
import { theme } from 'styles/theme';

export const DetailsContainer = () => {
  const { deviceId, poiId, bleDeviceId } = useParams<{ deviceId: string; poiId: string; bleDeviceId: string }>();
  const navigate = useNavigate();
  const {
    state: { isWideScreen },
  } = useMapHistory();
  const { selectedBleDevice, setSelectedBleDevice } = useSelectedBleDevice();
  const { selectedDevice, setSelectedDevice } = useSelectedDevice();
  const { selectedPoi, setSelectedPoi } = useSelectedPoi();
  const { showOnTheMap } = useMapInstance();
  const location = deviceId ? selectedDevice?.location : poiId ? selectedPoi?.location : undefined;

  return (
    <>
      <Box position="absolute" top={0} right={0} bottom={0} left={0} bg="white" overflowY="auto">
        <Outlet />
      </Box>
      <Box
        position="absolute"
        left={theme.sizes.sidebarWidth}
        mx={2}
        zIndex="docked"
        transition="left 500ms"
        sx={
          isWideScreen
            ? { [`@media (min-width: ${theme.sizes.doubleSidebarWidth})`]: { left: theme.sizes.fullSidebarWidth } }
            : {}
        }
      >
        <VStack spacing={0} bgColor="white" borderRadius="base">
          <Tooltip label="Back to the list" placement="right" hasArrow>
            <IconButton
              aria-label="Back to the list"
              icon={<RiArrowGoBackLine size={30} />}
              variant="ghost"
              onClick={() => {
                deviceId && selectedDevice && setSelectedDevice(null);
                poiId && selectedPoi && setSelectedPoi(null);
                bleDeviceId && selectedBleDevice && setSelectedBleDevice(null);
                navigate(routes.DEVICES.ROOT);
              }}
            />
          </Tooltip>
          {(deviceId || poiId) && (
            <Tooltip label="Locate on the map" placement="right" hasArrow>
              <IconButton
                aria-label="Locate on the map"
                icon={<RiMapPinFill size={30} />}
                variant="ghost"
                disabled={!location}
                onClick={() => location && showOnTheMap(location)}
              />
            </Tooltip>
          )}
          {deviceId && (
            <>
              <Tooltip label="Device details" placement="right" hasArrow>
                <IconButton
                  aria-label="Device details"
                  icon={<RiInformationLine size={30} />}
                  variant="ghost"
                  onClick={() => navigate(resolveUrl(routes.DEVICES.DETAILS, { deviceId }))}
                />
              </Tooltip>
              <Tooltip label="Device settings" placement="right" hasArrow>
                <IconButton
                  aria-label="Device settings"
                  icon={<RiSettings3Line size={30} />}
                  variant="ghost"
                  onClick={() => navigate(resolveUrl(routes.DEVICES.SETTINGS, { deviceId }))}
                />
              </Tooltip>
              <Tooltip label="Flights" placement="right" hasArrow>
                <IconButton
                  aria-label="Flights"
                  icon={<IoAirplaneOutline size={30} />}
                  variant="ghost"
                  onClick={() => navigate(resolveUrl(routes.DEVICES.FLIGHTS, { deviceId }))}
                />
              </Tooltip>
              <Tooltip label="Device history" placement="right" hasArrow>
                <IconButton
                  aria-label="Device history"
                  icon={<RiLineChartLine size={30} />}
                  variant="ghost"
                  onClick={() => navigate(resolveUrl(routes.DEVICES.HISTORY, { deviceId }))}
                />
              </Tooltip>
              <Tooltip label="BLE device notices" placement="right" hasArrow>
                <IconButton
                  aria-label="BLE device notices"
                  icon={<RiBluetoothLine size={30} />}
                  variant="ghost"
                  onClick={() => navigate(resolveUrl(routes.DEVICES.BLE_DEVICE_NOTICES, { deviceId }))}
                />
              </Tooltip>
            </>
          )}
        </VStack>
      </Box>
    </>
  );
};
