import { Box, Heading } from '@chakra-ui/react';

import { BleDevicesAutocomplete } from 'components/Autocomplete/BleDevicesAutocomplete';
import { BleDeviceList } from 'components/BleDeviceList';

export const BleDevicesRoute = () => {
  return (
    <>
      <Box m={4}>
        <Heading as="h5" size="sm">
          BLE devices
        </Heading>
        <BleDevicesAutocomplete mt={4} />
      </Box>
      <BleDeviceList />
    </>
  );
};
