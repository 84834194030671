import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

import { PoiGroupDeleteForm } from 'components/forms/PoiGroupDeleteForm';

import { usePoiGroupDelete } from 'api/poiGroups';
import { PoiGroupSchema } from 'api/models/PoiGroupSchema';

type DeletePoiGroupModalProps = {
  groups?: PoiGroupSchema[];
  groupId?: string;
  onClose: () => void;
};

export const DeletePoiGroupModal = ({ groups, groupId, onClose }: DeletePoiGroupModalProps) => {
  const { isOpen } = useDisclosure({ isOpen: !!groupId });
  const { mutate, isLoading } = usePoiGroupDelete(groupId, () => onClose());

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Deleting POI group</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={4}>
          <PoiGroupDeleteForm
            poiGroups={groups?.filter((group) => group.id !== groupId)}
            onSubmit={mutate}
            onCancel={onClose}
            isLoading={isLoading}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
