import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  FormErrorMessage,
  HStack,
  PinInput as ChakraPinInput,
  PinInputProps as ChakraPinInputProps,
  PinInputField,
} from '@chakra-ui/react';

import { get } from 'utils/utils';

type PinInputProps = Omit<ChakraPinInputProps, 'children'> & {
  name: string;
};

export const PinInput = ({ name, ...rest }: PinInputProps) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name);

  register(name);

  return (
    <>
      <HStack>
        <ChakraPinInput
          type="number"
          otp={true}
          focusBorderColor="primary"
          isInvalid={!!error}
          onChange={(value) => setValue(name, value)}
          {...rest}
        >
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
        </ChakraPinInput>
      </HStack>
      <FormErrorMessage mt={0} fontSize="xs">
        {error?.message}
      </FormErrorMessage>
    </>
  );
};
