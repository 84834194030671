import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';

import { useNotificationsTest } from 'api/notifications';
import { NotificationsTestForm } from 'components/forms/NotificationsTestForm';
import { ErrorMessage } from 'components/ErrorMessage';
import { useState } from 'react';
import { NotificationsTestSchema } from 'api/models/NotificationsTestSchema';
import { NotificationsTestHistory } from './NotificationsTestHistory';

type NotificationsTestProps = {
  isOpen: boolean;
  onClose: () => void;
};

export type NotificationsTestHistorySchema = NotificationsTestSchema & { sent_at: Date; error?: string };

export const NotificationsTest = ({ isOpen, onClose }: NotificationsTestProps) => {
  const { isLoading, mutate: send, error } = useNotificationsTest();
  const [history, setHistory] = useState<NotificationsTestHistorySchema[]>([]);

  const onSubmit = (data: NotificationsTestSchema) => {
    send(data, {
      onSuccess: () => {
        setHistory([{ ...data, sent_at: new Date() }, ...history]);
      },
      onError: (err) => {
        setHistory([{ ...data, sent_at: new Date(), error: err.error }, ...history]);
      },
    });
  };
  return (
    <Drawer isOpen={isOpen} placement="right" size="lg" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Test Notifications</DrawerHeader>
        <DrawerBody>
          <Box position="relative" height="100%" width="100%" overflowY="auto">
            <NotificationsTestForm onSubmit={onSubmit} formSize="sm" isLoading={isLoading} />
            <NotificationsTestHistory history={history} />
            <ErrorMessage message={error?.error} />
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
