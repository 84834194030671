import React from 'react';
import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay } from '@chakra-ui/react';

import { NewNotificationsList } from './NewNotificationsList';

type NewNotificationsDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const NewNotificationsDrawer = ({ isOpen, onClose }: NewNotificationsDrawerProps) => {
  return (
    <Drawer isOpen={isOpen} placement="right" size="lg" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>New notifications history</DrawerHeader>
        <DrawerBody>{isOpen && <NewNotificationsList />}</DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
