import {
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';

import { EmailChange } from 'components/EmailChange';
import { MfaChange } from 'components/MfaChange';
import { PasswordChange } from 'components/PaswordChange';
import { Users } from 'components/Users';
import { APIKeys } from 'components/APIKeys';

type AccountSettingsProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const AccountSettings = ({ isOpen, onClose }: AccountSettingsProps) => {
  return (
    <Drawer isOpen={isOpen} placement="right" size="lg" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Account settings</DrawerHeader>
        <DrawerBody>
          <Tabs isLazy orientation="vertical" colorScheme="primaryScheme" alignItems="flex-start">
            <TabList>
              <Tab justifyContent="flex-start" whiteSpace="nowrap">
                Users
              </Tab>
              <Tab justifyContent="flex-start" whiteSpace="nowrap">
                Password change
              </Tab>
              <Tab justifyContent="flex-start" whiteSpace="nowrap">
                Email change
              </Tab>
              <Tab justifyContent="flex-start" whiteSpace="nowrap">
                MFA change
              </Tab>
              <Divider />
              <Tab justifyContent="flex-start" whiteSpace="nowrap">
                API Keys
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel pt={0} pl={12}>
                <Users />
              </TabPanel>
              <TabPanel pt={0} pl={12}>
                <PasswordChange />
              </TabPanel>
              <TabPanel pt={0} pl={12}>
                <EmailChange />
              </TabPanel>
              <TabPanel pt={0} pl={12}>
                <MfaChange />
              </TabPanel>
              <TabPanel pt={0} pl={12}>
                <APIKeys />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
