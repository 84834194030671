export enum ChallengeName {
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
  MFA_SETUP = 'MFA_SETUP',
  SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA',
}

// Password policy based on the AWS Cognito requirements https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
export const passwordPolicy = {
  regExp: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^\$\*\.\[\]\{\}\(\)\?"!@#%&\/\\,><':;|_~`])(?=.{8,})/, // eslint-disable-line no-useless-escape
  errorMsg:
    'Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character',
};
