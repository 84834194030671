import { Center } from '@chakra-ui/react';
import { object, string, SchemaOf } from 'yup';

import { Form } from 'components/forms/Form';
import { SubmitButton } from 'components/forms/SubmitButton';

import { useForm } from 'hooks/useForm';
import { AuthAPIKeyInputSchema } from 'api/models/AuthAPIKeyInputSchema';
import { Input } from './Input';

type APIKeyFormProps = {
  onSubmit: (values: AuthAPIKeyInputSchema) => void;
  isLoading?: boolean;
  isSubmitButtonFull?: boolean;
};

const validationSchema: SchemaOf<AuthAPIKeyInputSchema> = object({
  api_key: string().required(),
  secret_key: string().required(),
});

export const APIKeyForm = ({ onSubmit, isLoading, isSubmitButtonFull }: APIKeyFormProps) => {
  const form = useForm<AuthAPIKeyInputSchema>({ validationSchema });

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Input type="text" name="api_key" placeholder="API Key" size="sm" flex={1} />
      <Input type="text" name="secret_key" placeholder="Secret Key" size="sm" flex={1} />
      <Center>
        <SubmitButton isLoading={isLoading} width="full" mt={2}>
          Submit
        </SubmitButton>
      </Center>
    </Form>
  );
};
