import { useMutation } from 'react-query';
import { useToast } from '@chakra-ui/react';

import { axios } from './axios';
import { urls } from './urls';
import { EmptySchema } from './models/EmptySchema';
import { ErrorSchema } from './models/ErrorSchema';
import { EmailChangeInputSchema } from './models/EmailChangeInputSchema';
import { EmailChangeSchema } from './models/EmailChangeSchema';
import { EmailChangeVerificationInputSchema } from './models/EmailChangeVerificationInputSchema';
import { PasswordChangeInputSchema } from './models/PasswordChangeInputSchema';

const usePasswordChange = (onSuccess?: () => void) => {
  const toast = useToast();

  return useMutation<EmptySchema, ErrorSchema<PasswordChangeInputSchema>, PasswordChangeInputSchema>(
    (values) => axios.post(urls.ACCOUNT.PASSWORD_CHANGE, values),
    {
      onSuccess: () => {
        onSuccess?.();
        toast({
          description: 'Your password has been changed.',
          status: 'success',
          isClosable: true,
        });
      },
    }
  );
};

const useEmailChange = () => {
  return useMutation<EmailChangeSchema, ErrorSchema<EmailChangeInputSchema>, EmailChangeInputSchema>((values) =>
    axios.post(urls.ACCOUNT.EMAIL_CHANGE, values)
  );
};

const useEmailChangeVerification = () => {
  const toast = useToast();

  return useMutation<EmptySchema, ErrorSchema<EmailChangeVerificationInputSchema>, EmailChangeVerificationInputSchema>(
    (values) => axios.post(urls.ACCOUNT.EMAIL_CHANGE_VERIFICATION, values),
    {
      onSuccess: () => {
        toast({
          description: 'Your email has been changed.',
          status: 'success',
          isClosable: true,
        });
      },
    }
  );
};

export { usePasswordChange, useEmailChange, useEmailChangeVerification };
