import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';

import { AuthHeader } from 'components/templates/Header';

import { useAuth } from 'hooks/useAuth';
import { routes } from 'consts/routes';

type AuthLayoutProps = {
  isUserRequired?: boolean;
};

export const AuthLayout = ({ isUserRequired }: AuthLayoutProps) => {
  const location = useLocation();
  const { isLoggedIn, username } = useAuth();
  const locationState = location.state as { from: string };

  if (isLoggedIn) {
    return <Navigate to={locationState?.from ?? routes.ROOT} replace />;
  } else if (isUserRequired && !username) {
    return <Navigate to={routes.AUTH.LOGIN} replace />;
  }

  return (
    <>
      <AuthHeader />
      <Flex justifyContent="center">
        <Box flexBasis="480px" mx={4}>
          <Outlet />
        </Box>
      </Flex>
    </>
  );
};
