import React, { useEffect } from 'react';

import { CheckboxGroup } from 'components/forms/CheckboxGroup';
import { Form } from 'components/forms/Form';

import { useForm } from 'hooks/useForm';
import { PoiFiltersSchema } from 'api/models/PoiFiltersSchema';

type PoiFiltersFormProps = {
  onSubmit: (values: PoiFiltersSchema) => void;
  initialValues?: PoiFiltersSchema;
};

export const PoiFiltersForm = ({ onSubmit, initialValues }: PoiFiltersFormProps) => {
  const form = useForm<PoiFiltersSchema>({ defaultValues: { type: [] } });

  useEffect(() => {
    // Set initial values only once
    initialValues && form.reset(initialValues);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form form={form} onSubmit={onSubmit} submitOnChange={true}>
      <CheckboxGroup name="type" options={[{ label: 'Show only partner POIs', value: 'partner' }]} />
    </Form>
  );
};
