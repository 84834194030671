import React, { ForwardedRef, forwardRef } from 'react';
import { Text, List, ListItem } from '@chakra-ui/react';

import { useSelectedDevice } from 'hooks/useSelectedDevice';

type MultipleDevicesPopupProps = {
  deviceIds: number[];
};

export const MultipleDevicesPopup = forwardRef(
  ({ deviceIds }: MultipleDevicesPopupProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { setSelectedDevice } = useSelectedDevice();

    return (
      <div ref={ref}>
        <Text fontWeight="semibold" pb={2}>
          Devices list devu
        </Text>
        <List maxH="320px" overflowY="auto">
          {deviceIds.map((deviceId) => (
            <ListItem
              key={deviceId}
              onClick={() => setSelectedDevice(deviceId)}
              p={1}
              cursor="pointer"
              _odd={{ bg: 'gray.100' }}
              _hover={{ bg: 'aquaLight' }}
            >
              {deviceId}
            </ListItem>
          ))}
        </List>
      </div>
    );
  }
);

export const MemoizedMultipleDevicesPopup = React.memo(MultipleDevicesPopup);
