import React from 'react';
import { Box, Heading } from '@chakra-ui/react';

import { PoiGroupsList } from 'components/PoiGroupsList';

export const PoiGroups = () => {
  return (
    <>
      <Box mx={4} mt={4}>
        <Heading as="h5" size="sm">
          POI groups
        </Heading>
      </Box>
      <PoiGroupsList />
    </>
  );
};
