import React, { useEffect } from 'react';
import { Box, Button, Center } from '@chakra-ui/react';

import { DevicesListItem } from './DevicesListItem';
import { Loader } from 'components/Loader';

import { useSelectedDevice } from 'hooks/useSelectedDevice';
import { paginationPageSize } from 'consts/common';
import { useDevicesGeofencePaginatedQuery } from 'api/devicesGeofence';
import { DeviceSchema } from 'api/models/DeviceSchema';

type DevicesListProps = {
  devices?: DeviceSchema[];
  total: number;
  isLoading: boolean;
};

/**
 * DevicesList displays a list of devices, which consists of the 'devices' prop (the first page of paginated data)
 * and the 'data' variable (the rest of the pages). It is because the devices list needs to be synchronized with data
 * displayed on the map. If the map is, for example, zoomed, only the first page will be shown.
 */
export const DevicesList = ({ devices, total, isLoading }: DevicesListProps) => {
  const { setSelectedDevice } = useSelectedDevice();
  const {
    data,
    clearData,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage = total > paginationPageSize,
  } = useDevicesGeofencePaginatedQuery();

  useEffect(() => {
    // Clear paginated data when devices list is updated (display only the first page)
    if (data) {
      clearData();
    }
  }, [devices]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box position="relative" minHeight={16} overflowY="auto">
      {devices?.map((device) => (
        <DevicesListItem key={device.id} device={device} onClick={() => setSelectedDevice(device)} />
      ))}
      {data?.pages?.map((page, index) => (
        <React.Fragment key={index}>
          {page.devices?.map((device) => (
            <DevicesListItem key={device.id} device={device} onClick={() => setSelectedDevice(device)} />
          ))}
        </React.Fragment>
      ))}
      {hasNextPage && (
        <Center py={4}>
          <Button
            onClick={() => fetchNextPage()}
            isLoading={isFetchingNextPage}
            colorScheme="primaryScheme"
            variant="outline"
          >
            Load more
          </Button>
        </Center>
      )}
      <Loader isLoading={isLoading} top={4} />
    </Box>
  );
};
