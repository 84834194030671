import React, { ReactElement } from 'react';
import { useCombobox } from 'downshift';
import { Box, Input, ThemingProps } from '@chakra-ui/react';

import { Loader } from 'components/Loader';

type AutocompleteProps<TItem> = {
  items?: TItem[];
  placeholder?: string;
  renderSuggestion: (item: TItem, query: string) => ReactElement;
  onSuggestionClick: (selectedItem: TItem) => void;
  onInputChange: (value: string | undefined) => void;
  isLoading: boolean;
  inputSize?: ThemingProps['size'];
};

const minInputLength = 3;

export const Autocomplete = <TItem,>({
  items = [],
  placeholder,
  renderSuggestion,
  onSuggestionClick,
  onInputChange,
  isLoading,
  inputSize,
  ...props
}: AutocompleteProps<TItem>) => {
  const {
    getComboboxProps,
    getInputProps,
    getItemProps,
    getMenuProps,
    highlightedIndex,
    inputValue,
    isOpen,
    openMenu,
  } = useCombobox({
    items,
    onInputValueChange: ({ inputValue }) => {
      if ((inputValue?.length ?? 0) >= minInputLength) onInputChange(inputValue);
    },
    onSelectedItemChange: ({ selectedItem }) => {
      selectedItem && onSuggestionClick(selectedItem);
    },
    stateReducer: (state, actionAndChanges) => {
      const { type, changes } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
          return {
            ...changes,
            inputValue: '',
          };
        default:
          return changes;
      }
    },
  });
  const showItems = isOpen && !isLoading && (inputValue?.length ?? 0) >= minInputLength;

  return (
    <Box {...getComboboxProps()} position="relative" {...props}>
      <Loader isLoading={isLoading} size="sm" />
      <Input
        {...getInputProps({
          onFocus: () => {
            if (!showItems) {
              openMenu();
            }
          },
        })}
        placeholder={placeholder}
        focusBorderColor="primary"
        size={inputSize}
      />
      <Box
        {...getMenuProps()}
        position="absolute"
        width="full"
        maxHeight="250px"
        mt={0.5}
        overflow="auto"
        visibility={showItems ? 'visible' : 'hidden'}
        zIndex="dropdown"
        bg="white"
        boxShadow="base"
        rounded="md"
      >
        {showItems &&
          items.map((item, index) => (
            <Box
              {...getItemProps({ item, index })}
              key={index}
              px={4}
              py={2}
              bg={highlightedIndex === index && 'aquaLight'}
              cursor="pointer"
            >
              {renderSuggestion(item, inputValue)}
            </Box>
          ))}
      </Box>
    </Box>
  );
};
