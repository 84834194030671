import React from 'react';
import { Divider, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';

import { ErrorMessage } from 'components/ErrorMessage';
import { GroupDevicesForm } from 'components/forms/GroupDevicesForm';
import { DeviceGroupForm } from 'components/forms/DeviceGroupForm';

import { useDeviceGroupUpdate } from 'api/deviceGroups';
import { DeviceGroupSchema } from 'api/models/DeviceGroupSchema';

type EditDeviceGroupModalProps = {
  group?: DeviceGroupSchema;
  onClose: () => void;
};

export const EditDeviceGroupModal = ({ group, onClose }: EditDeviceGroupModalProps) => {
  const { error, mutate, isLoading } = useDeviceGroupUpdate(group?.id, onClose);

  // Transform DeviceGroupSchema into DeviceGroupInputSchema
  const initialValues = group && { name: group.name };

  return (
    <Modal isOpen={!!group} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Editing device group</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={4}>
          <DeviceGroupForm
            onSubmit={mutate}
            initialValues={initialValues}
            isLoading={isLoading}
            fieldErrors={error?.fields}
          />
          <ErrorMessage message={error?.error} />
          <Divider py="2" />
          {group && <GroupDevicesForm groupId={group.id} />}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
