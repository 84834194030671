import { Box, Heading } from '@chakra-ui/react';

import { BleDevicesNoticesList } from 'components/BleDevicesNoticesList';
import { BleDevicesAutocomplete } from 'components/Autocomplete/BleDevicesAutocomplete';

export const BleDevicesNoticesRoute = () => {
  return (
    <>
      <Box m={4}>
        <Heading as="h5" size="sm">
          BLE latest notices
        </Heading>
        <BleDevicesAutocomplete mt={4} />
      </Box>
      <BleDevicesNoticesList />
    </>
  );
};
