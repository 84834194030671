import React from 'react';
import {
  Box,
  BoxProps,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

import { ErrorMessage } from 'components/ErrorMessage';
import { PoiForm } from 'components/forms/PoiForm';

import { usePoiCreate } from 'api/pois';

export const AddPoiModal = (styleProps: BoxProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { error, mutate, isLoading } = usePoiCreate(onClose);

  return (
    <Box {...styleProps}>
      <Button onClick={onOpen} size="sm" variant="outline" width="full" colorScheme="primaryScheme">
        Add new POI
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Adding POI</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={4}>
            <PoiForm onSubmit={mutate} isLoading={isLoading} fieldErrors={error?.fields} />
            <ErrorMessage message={error?.error} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
