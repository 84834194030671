import React, { useEffect } from 'react';
import { useSelect } from 'downshift';
import { FieldError, useController, useFormContext } from 'react-hook-form';
import { Box, Center, FormControlProps, Select as ChakraSelect, ThemingProps } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { FormControl } from 'components/forms/FormControl';

import { get } from 'utils/utils';
import { RiCloseCircleFill } from 'react-icons/ri';

export type SelectItemType = { label: string; value?: string | number };

type SelectProps = FormControlProps & {
  name: string;
  items?: SelectItemType[];
  placeholder?: string;
  label?: string;
  size?: ThemingProps['size'];
  isLabelOnTop?: boolean;
  isClearable?: Boolean;
};

const SelectButton = styled(ChakraSelect)``;

export const Select = ({
  name,
  items = [],
  placeholder = 'Select...',
  label,
  size,
  isLabelOnTop,
  isClearable,
  ...formControlStyles
}: SelectProps) => {
  const {
    control,
    setValue,
    formState: { errors, touchedFields },
  } = useFormContext();
  const { field } = useController({
    control,
    name,
  });
  const {
    getItemProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
    isOpen,
    reset,
    selectItem,
    selectedItem,
  } = useSelect({
    items,
    initialSelectedItem: items?.find((item) => item.value === field.value),
    onSelectedItemChange: ({ selectedItem }) => {
      selectedItem && field.onChange(selectedItem.value);
    },
  });
  const error: FieldError = get(errors, name);

  // Set initial selected item asynchronously
  useEffect(() => {
    if (field.value && !touchedFields[name]) {
      const item = items?.find((item) => item.value === field.value);
      item && selectItem(item);
    } else if (!field.value) {
      reset();
    }
  }, [field.value]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClear = () => {
    setValue(name, null);
  };

  return (
    <FormControl error={error} label={label} isLabelOnTop={isLabelOnTop} {...formControlStyles}>
      <Box position="relative">
        <SelectButton
          as={Box}
          {...getToggleButtonProps({
            ref: field.ref,
            onClick: field.onBlur,
            disabled: formControlStyles.isDisabled,
          })}
          size={size}
        >
          <Center height="full" justifyContent="space-between" color={!selectedItem ? 'gray.500' : ''}>
            {!selectedItem?.label ? (
              placeholder
            ) : (
              <>
                {selectedItem?.label}
                {isClearable && <RiCloseCircleFill title="Clear" onClick={handleClear} cursor="pointer" />}
              </>
            )}
          </Center>
        </SelectButton>
        <Box
          {...getMenuProps()}
          position="absolute"
          width="full"
          maxHeight="250px"
          mt={0.5}
          overflow="auto"
          visibility={isOpen ? 'visible' : 'hidden'}
          zIndex="dropdown"
          bg="white"
          boxShadow="base"
          rounded="md"
        >
          {isOpen &&
            items.map((item, index) => (
              <Box
                {...getItemProps({ item, index })}
                key={`${item.value}${index}`}
                px={4}
                py={2}
                bg={highlightedIndex === index && 'aquaLight'}
                fontSize={size}
              >
                {item.label}
              </Box>
            ))}
        </Box>
      </Box>
    </FormControl>
  );
};
