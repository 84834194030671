import React from 'react';
import { object, string, SchemaOf } from 'yup';

import { Form } from 'components/forms/Form';
import { Input } from 'components/forms/Input';
import { SubmitButton } from 'components/forms/SubmitButton';

import { useForm } from 'hooks/useForm';
import { AuthPasswordResetInputSchema } from 'api/models/AuthPasswordResetInputSchema';
import { ErrorSchema } from 'api/models/ErrorSchema';

type PasswordResetFormProps = {
  onSubmit: (values: AuthPasswordResetInputSchema) => void;
  isLoading?: boolean;
  fieldErrors?: ErrorSchema<AuthPasswordResetInputSchema>['fields'];
};

const validationSchema: SchemaOf<AuthPasswordResetInputSchema> = object({
  username: string().required(),
});

export const PasswordResetForm = ({ onSubmit, isLoading, fieldErrors }: PasswordResetFormProps) => {
  const form = useForm<AuthPasswordResetInputSchema>({ validationSchema, fieldErrors });

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Input type="text" name="username" label="Username" isLabelOnTop={true} />
      <SubmitButton isLoading={isLoading} width="full" mt={2}>
        Send
      </SubmitButton>
    </Form>
  );
};
