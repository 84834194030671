import { useQuery } from 'react-query';

import { axios } from './axios';
import { urls } from './urls';
import { resolveUrl } from 'utils/resolveUrl';
import { DeviceSchema } from './models/DeviceSchema';
import { DeviceSuggestionsSchema } from './models/DeviceSuggestionsSchema';

const useDeviceSuggestionsQuery = (query?: string | number) => {
  const queryParams = {
    query,
    fields: ['name', 'tracker_id'],
  };

  return useQuery<DeviceSchema[]>(
    [urls.DEVICES.SUGGESTIONS, queryParams],
    async () => {
      const data: DeviceSuggestionsSchema = await axios.get(resolveUrl(urls.DEVICES.SUGGESTIONS, {}, queryParams));
      let results: DeviceSchema[] = [];
      data.suggestions.forEach((suggestion) => (results = results.concat(suggestion.trackers)));
      // Return suggestions array without duplicates
      return results.filter((device, index, array) => array.findIndex((d) => d.id === device.id) === index);
    },
    {
      enabled: !!query,
      refetchOnWindowFocus: false,
    }
  );
};

export { useDeviceSuggestionsQuery };
