import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useToast } from '@chakra-ui/react';

import { axios } from './axios';
import { urls } from './urls';
import { resolveUrl } from 'utils/resolveUrl';
import { EmptySchema } from './models/EmptySchema';
import { ErrorSchema } from './models/ErrorSchema';
import { UserCognitoSchema } from './models/UserCognitoSchema';
import { UserInputSchema } from './models/UserInputSchema';
import { UsersSchema } from './models/UsersSchema';

interface UserDeleteSchema {
  userId: string;
}

const useUsersQuery = () => {
  return useQuery<UsersSchema>(urls.USERS.BASE, () => axios.get(urls.USERS.BASE), {
    keepPreviousData: true,
  });
};

const useUserCreate = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation<UserCognitoSchema, ErrorSchema<UserInputSchema>, UserInputSchema>(
    (values) => axios.post(urls.USERS.BASE, values),
    {
      onSuccess: () => {
        onSuccess?.();
        queryClient.invalidateQueries(urls.USERS.BASE);
        toast({
          description: 'The user has been added.',
          status: 'success',
          isClosable: true,
        });
      },
    }
  );
};

const useUserDelete = () => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation<EmptySchema, ErrorSchema<UserDeleteSchema>, UserDeleteSchema>(
    (values) => axios.delete(resolveUrl(urls.USERS.DETAILS, values)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(urls.USERS.BASE);
        toast({
          description: 'The user has been deleted.',
          status: 'success',
          isClosable: true,
        });
      },
      onError: (error) => {
        toast({
          description: error.error,
          status: 'error',
          isClosable: true,
        });
      },
    }
  );
};

export { useUsersQuery, useUserCreate, useUserDelete };
