import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import { ErrorMessage } from 'components/ErrorMessage';
import { MfaVerificationForm } from 'components/forms/MfaVerificationForm';

import { useAuth } from 'hooks/useAuth';
import { useMfaChallenge } from 'api/auth';
import { ChallengeName } from 'consts/auth';
import { AuthMfaVerificationInputSchema } from 'api/models/AuthMfaVerificationInputSchema';

export const MfaVerification = () => {
  const { username } = useAuth();
  const { error, isLoading, mutate } = useMfaChallenge();

  const handleSubmit = ({ user_code }: AuthMfaVerificationInputSchema) => {
    mutate({
      user_code,
      username,
      challenge_type: ChallengeName.SOFTWARE_TOKEN_MFA,
    });
  };

  return (
    <>
      <Text mb={2} align="center" fontSize="xl" fontWeight="semibold">
        Verify yourself
      </Text>
      <Text mb={4} align="center">
        Enter an unique one-time password from your two-factor authentication app.
      </Text>
      <Box p={4} bg="white" boxShadow="base" borderRadius="base">
        <MfaVerificationForm onSubmit={handleSubmit} isLoading={isLoading} isSubmitButtonFull={true} />
        <ErrorMessage message={error?.error} />
      </Box>
    </>
  );
};
