import React from 'react';
import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
} from '@chakra-ui/react';
import { FiRefreshCw } from 'react-icons/fi';

import { NotificationsListItem } from './NotificationsListItem';
import { Loader } from 'components/Loader';

import { useNotificationsQuery } from 'api/notifications';

type NotificationsListProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const NotificationsList = ({ isOpen, onClose }: NotificationsListProps) => {
  const { data, isFetching, fetchNextPage, isFetchingNextPage, hasNextPage, refetch } = useNotificationsQuery(isOpen);

  return (
    <Drawer isOpen={isOpen} placement="right" size="lg" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          Notifications history
          <Button onClick={() => refetch()} leftIcon={<FiRefreshCw />} colorScheme="primaryScheme" size="sm" ml={4}>
            Refresh
          </Button>
        </DrawerHeader>
        <DrawerBody>
          <Box position="relative" minHeight={16} overflowY="auto">
            {data?.pages?.[0].notifications?.length === 0 ? (
              <Text>There is no notifications history</Text>
            ) : (
              data?.pages?.map((page, index) => (
                <React.Fragment key={index}>
                  {page.notifications?.map((notification) => (
                    <NotificationsListItem key={notification.id} notification={notification} />
                  ))}
                </React.Fragment>
              ))
            )}
            {hasNextPage && (
              <Center py={4}>
                <Button
                  onClick={() => fetchNextPage()}
                  isLoading={isFetchingNextPage}
                  colorScheme="primaryScheme"
                  variant="outline"
                  size="sm"
                >
                  Load more
                </Button>
              </Center>
            )}
            <Loader isLoading={isFetching} top={4} />
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
