import React, { useEffect, useRef } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { ControllerRenderProps } from 'react-hook-form';
import { CloseButton, Input, InputGroup, InputProps, InputRightElement } from '@chakra-ui/react';
import { add, endOfDay, isToday } from 'date-fns';
import flatpickr from 'flatpickr';
import { Options } from 'flatpickr/dist/types/options';
import { Instance } from 'flatpickr/dist/types/instance';

import { toUtc } from 'utils/datetime';

type DatePickerProps = Pick<ControllerRenderProps, 'onChange'> & {
  inputProps: Partial<InputProps> &
    Partial<Pick<ControllerRenderProps, 'name' | 'onBlur'>> & { placeholder?: string; allowClear?: boolean };
  inputRef?: (e: HTMLInputElement | null) => void;
  options?: Options;
  asyncDefaultDate?: string;
};

const today = new Date();

export const DatePicker = ({
  inputProps: { allowClear, ...restInputProps },
  inputRef,
  options,
  asyncDefaultDate,
  onChange,
}: DatePickerProps) => {
  const datePickerRef = useRef<HTMLInputElement | null>(null);
  const flatpickrInstance = useRef<Instance | null>(null);

  // Initialize date picker and update it after options change (https://github.com/flatpickr/flatpickr/issues/1546)
  useDeepCompareEffect(() => {
    if (datePickerRef.current) {
      flatpickrInstance.current = flatpickr(datePickerRef.current as Node, {
        onChange: (selectedDates, dateStr, instance) => {
          const { enableTime, minDate, mode } = instance.config;
          if (mode === 'single') {
            if (enableTime) {
              onChange(selectedDates[0].toISOString());
            } else {
              if (minDate && isToday(minDate) && isToday(selectedDates[0])) {
                const hours = minDate.getHours();
                const minutes = minDate.getMinutes();
                onChange(add(selectedDates[0], { hours, minutes }).toISOString());
              } else {
                onChange(toUtc(selectedDates[0]).toISOString());
              }
            }
          } else if (mode === 'range') {
            if (selectedDates.length === 2) {
              if (enableTime) {
                onChange({ start: selectedDates[0].toISOString(), end: selectedDates[1].toISOString() });
              } else {
                const today = new Date();
                const startDate = toUtc(selectedDates[0]);
                const endDate = isToday(selectedDates[1]) ? today : toUtc(endOfDay(selectedDates[1]));
                onChange({ start: startDate.toISOString(), end: endDate.toISOString() });
              }
            } else if (selectedDates.length === 0) {
              onChange({ start: undefined, end: undefined });
            }
          } else {
            onChange(selectedDates.map((date) => date.toISOString()));
          }
        },
        defaultHour: today.getHours(),
        defaultMinute: today.getMinutes(),
        time_24hr: true,
        ...options,
      });
    }

    return () => {
      flatpickrInstance.current?.destroy();
      flatpickrInstance.current = null;
    };
  }, [options]);

  useEffect(() => {
    if (asyncDefaultDate) {
      flatpickrInstance.current?.setDate(asyncDefaultDate);
    }
  }, [asyncDefaultDate]);

  return (
    <InputGroup size={restInputProps.size}>
      <Input
        type="date"
        {...restInputProps}
        ref={(e) => {
          inputRef?.(e);
          datePickerRef.current = e;
        }}
      />
      {allowClear && (
        <InputRightElement>
          <CloseButton size="sm" onClick={() => flatpickrInstance.current?.clear()} />
        </InputRightElement>
      )}
    </InputGroup>
  );
};
