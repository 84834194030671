import React from 'react';
import { object, ref, string, SchemaOf } from 'yup';

import { Form } from 'components/forms/Form';
import { Input } from 'components/forms/Input';
import { SubmitButton } from 'components/forms/SubmitButton';

import { useForm } from 'hooks/useForm';
import { passwordPolicy } from 'consts/auth';
import { AuthPasswordSetupInputSchema } from 'api/models/AuthPasswordSetupInputSchema';
import { ErrorSchema } from 'api/models/ErrorSchema';

type PasswordSetupFormProps = {
  onSubmit: (values: AuthPasswordSetupInputSchema) => void;
  isLoading?: boolean;
  fieldErrors?: ErrorSchema<AuthPasswordSetupInputSchema>['fields'];
};

const validationSchema: SchemaOf<AuthPasswordSetupInputSchema> = object({
  password: string().matches(passwordPolicy.regExp, passwordPolicy.errorMsg).required(),
  matching_password: string()
    .oneOf([ref('password')], 'Passwords must match')
    .required(),
});

export const PasswordSetupForm = ({ onSubmit, isLoading, fieldErrors }: PasswordSetupFormProps) => {
  const form = useForm<AuthPasswordSetupInputSchema>({ validationSchema, fieldErrors });

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Input type="password" name="password" label="New password" isLabelOnTop={true} />
      <Input type="password" name="matching_password" label="Confirm new password" isLabelOnTop={true} />
      <SubmitButton isLoading={isLoading} width="full" mt={2}>
        Save
      </SubmitButton>
    </Form>
  );
};
