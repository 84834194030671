import React, { useEffect } from 'react';
import { Button, Center, Divider, Text } from '@chakra-ui/react';

import { ErrorMessage } from 'components/ErrorMessage';
import { MfaQrCode } from 'components/MfaQrCode';
import { MfaVerificationForm } from 'components/forms/MfaVerificationForm';

import { useMfaSetup, useMfaVerification } from 'api/auth';

export const MfaChange = () => {
  const { clearData, data, error, isFetching, refetch } = useMfaSetup();
  const {
    mutate: verify,
    error: verificationError,
    isLoading: isVerificationLoading,
  } = useMfaVerification(false, clearData);

  useEffect(() => {
    return () => {
      clearData();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Text>
        You’re about to change your multi-factor authentication settings. This action will invalidate your current
        configuration.
      </Text>
      <Center mt={4}>
        <Button onClick={() => refetch()} isLoading={isFetching} colorScheme="primaryScheme">
          Change MFA
        </Button>
      </Center>
      {data && (
        <>
          <Divider borderColor="gray.400" my={4} />
          <MfaQrCode secretCode={data.SecretCode} mb={4} />
          <Text mb={4}>
            The app will provide you a unique one-time password that you have to enter below to enable multi-factor
            authentication.
          </Text>
          <MfaVerificationForm onSubmit={verify} isLoading={isVerificationLoading} />
          <ErrorMessage message={verificationError?.error} />
        </>
      )}
      <ErrorMessage message={error?.error} />
    </>
  );
};
