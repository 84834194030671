import React, { useEffect } from 'react';
import { BoxProps, Text, ThemingProps } from '@chakra-ui/react';

import { Autocomplete } from './';

import { useMapInstance } from 'hooks/useMapInstance';
import { usePlaceSuggestions } from 'hooks/usePlaceSuggestions';
import { PoiInputSchema } from 'api/models/PoiInputSchema';

type PlacesAutocompleteProps = BoxProps & {
  onSuggestionClick: (selectedPlace?: Partial<PoiInputSchema>) => void;
  inputSize?: ThemingProps['size'];
};

export const PlacesAutocomplete = ({ onSuggestionClick, ...props }: PlacesAutocompleteProps) => {
  const { mapInstance } = useMapInstance();
  const { isFetching, places, selectedPlace, getPlaceSuggestions, getSelectedPlaceDetails } =
    usePlaceSuggestions(mapInstance);

  useEffect(() => {
    selectedPlace && onSuggestionClick(selectedPlace);
  }, [selectedPlace]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Autocomplete
      items={places}
      placeholder="Search for address or place"
      renderSuggestion={PlaceSuggestion}
      onInputChange={getPlaceSuggestions}
      onSuggestionClick={getSelectedPlaceDetails}
      isLoading={isFetching}
      {...props}
    />
  );
};

export const PlaceSuggestion = (place: google.maps.places.AutocompletePrediction) => {
  return <Text>{place.description}</Text>;
};
