import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { usePoiQuery } from 'api/pois';
import { resolveUrl } from 'utils/resolveUrl';
import { routes } from 'consts/routes';
import { PoiGeofenceSchema } from 'api/models/PoiGeofenceSchema';

type SelectedPoiContextType = {
  selectedPoi: PoiGeofenceSchema | null;
  setSelectedPoi: Dispatch<SetStateAction<PoiGeofenceSchema | null>>;
};

type SelectedPoiProviderProps = {
  children: React.ReactNode;
};

const SelectedPoiContext = React.createContext<SelectedPoiContextType | null>(null);

export const useSelectedPoi = () => {
  const context = useContext(SelectedPoiContext);
  if (!context) {
    throw new Error('Missing SelectedPoiProvider');
  }

  const navigate = useNavigate();
  const [poiId, setPoiId] = useState<string>();
  const { data: poiDetails } = usePoiQuery(poiId);
  const { selectedPoi, setSelectedPoi } = context;

  useEffect(() => {
    if (poiDetails) {
      const { lat, lon, ...poi } = poiDetails;
      handleSelectedPoiChange({ ...poi, location: { lat, lon } });
    }
  }, [poiDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectedPoiChange = (poi: PoiGeofenceSchema | string | null) => {
    if (typeof poi === 'string') {
      // Fetch POI details (the case of multiple POIs at the same place)
      setPoiId(poi);
    } else {
      setSelectedPoi(poi);
      !poi ? navigate(routes.POIS.ROOT) : navigate(resolveUrl(routes.POIS.DETAILS, { poiId: poi.id }));
    }
  };

  return {
    selectedPoi,
    setSelectedPoi: handleSelectedPoiChange,
  };
};

export const SelectedPoiProvider = ({ children }: SelectedPoiProviderProps) => {
  const [selectedPoi, setSelectedPoi] = useState<PoiGeofenceSchema | null>(null);

  return <SelectedPoiContext.Provider value={{ selectedPoi, setSelectedPoi }}>{children}</SelectedPoiContext.Provider>;
};
