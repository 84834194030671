import React from 'react';
import { Box, Button, Collapse, useDisclosure } from '@chakra-ui/react';

import { NewNotificationWebhookListItem } from './NewNotificationWebhookListItem';

import { NotificationWebhookSchema } from 'api/models/NotificationWebhookSchema';

type NewNotificationWebhookListProps = {
  webhooks: NotificationWebhookSchema[];
};

export const NewNotificationWebhookList = ({ webhooks }: NewNotificationWebhookListProps) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <NewNotificationWebhookListItem webhook={webhooks[0]} />
      <Collapse in={isOpen} unmountOnExit={true}>
        {webhooks?.slice(1, webhooks?.length)?.map((webhook) => (
          <NewNotificationWebhookListItem key={webhook.id} webhook={webhook} />
        ))}
      </Collapse>
      {webhooks?.length > 1 && (
        <Box display="flex" justifyContent="flex-end" my={2}>
          <Button size="xs" variant="outline" colorScheme="primaryScheme" onClick={onToggle}>
            {isOpen ? 'Hide' : `Show ${webhooks?.length - 1} more`}
          </Button>
        </Box>
      )}
    </>
  );
};
