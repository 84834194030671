import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Text } from '@chakra-ui/react';

import { ErrorMessage } from 'components/ErrorMessage';
import { Loader } from 'components/Loader';
import { MfaQrCode } from 'components/MfaQrCode';
import { MfaVerificationForm } from 'components/forms/MfaVerificationForm';

import { useMfaSetup, useMfaVerification } from 'api/auth';
import { routes } from 'consts/routes';

const SessionExpiredError = () => {
  return (
    <Text fontSize="sm" fontWeight="semibold" mt={2}>
      Try to enter a new code or{' '}
      <Link as={RouterLink} to={routes.AUTH.LOGIN}>
        log in again
      </Link>
      .
    </Text>
  );
};

export const MfaSetup = () => {
  const { clearData, data, error, isFetching } = useMfaSetup(true);
  const {
    mutate: verify,
    error: verificationError,
    isLoading: isVerificationLoading,
  } = useMfaVerification(true, clearData);

  return (
    <>
      <Text mb={4} align="center" fontSize="xl" fontWeight="semibold">
        Set up multi-factor authentication
      </Text>
      <Box p={4} bg="white" boxShadow="base" borderRadius="base">
        <Text>
          Multi-factor authentication adds an extra layer of security to your account. Once enabled, each time you log
          in, you will also be prompted to enter a code generated by a two-step verification app (e.g., Google
          Authenticator).
        </Text>
        <Box position="relative" minHeight={16}>
          {data && (
            <>
              <MfaQrCode secretCode={data.SecretCode} my={4} />
              <Text mb={4}>
                The app will provide you a unique one-time password that you have to enter below to enable multi-factor
                authentication.
              </Text>
              <MfaVerificationForm onSubmit={verify} isLoading={isVerificationLoading} isSubmitButtonFull={true} />
              <ErrorMessage message={verificationError?.error} />
              {verificationError?.error && <SessionExpiredError />}
            </>
          )}
          <Loader isLoading={isFetching} />
        </Box>
        <ErrorMessage message={error?.error} />
        {error?.error && <SessionExpiredError />}
      </Box>
    </>
  );
};
