import { useInfiniteQuery, useMutation, useQuery } from 'react-query';

import { axios } from './axios';
import { urls } from './urls';
import { resolveUrl } from 'utils/resolveUrl';
import { DateRangeSchema } from './models/DateRangeSchema';
import { NewNotificationsSchema } from './models/NewNotificationsSchema';
import { NewNotificationFiltersSchema } from './models/NewNotificationFiltersSchema';
import { NotificationsSchema } from './models/NotificationsSchema';
import { useToast } from '@chakra-ui/react';
import { NotificationsTestSchema } from './models/NotificationsTestSchema';
import { ErrorSchema } from './models/ErrorSchema';

const useNewNotificationsQuery = (range?: DateRangeSchema, filters?: NewNotificationFiltersSchema | null) => {
  return useQuery<NewNotificationsSchema>(
    [urls.NOTIFICATIONS.NEW, range, filters],
    () => axios.get(resolveUrl(urls.NOTIFICATIONS.NEW, {}, { ...range, ...filters })),
    {
      enabled: !!range,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

const useNotificationsQuery = (enabled: boolean) => {
  return useInfiniteQuery<NotificationsSchema>(
    urls.NOTIFICATIONS.BASE,
    async ({ pageParam = 0 }) => await axios.get(resolveUrl(urls.NOTIFICATIONS.BASE, {}, { page: pageParam })),
    {
      enabled,
      getNextPageParam: (lastPageData) => {
        const pagesNumber = Math.ceil(lastPageData.total / lastPageData.limit);
        const nextPage = lastPageData.page + 1;
        return nextPage < pagesNumber ? nextPage : undefined;
      },
    }
  );
};

const useNotificationsTest = (onSuccess?: () => void) => {
  const toast = useToast();

  return useMutation<any, ErrorSchema<NotificationsTestSchema>, NotificationsTestSchema>(
    (values) => axios.post(urls.NOTIFICATIONS.TEST, values),
    {
      onSuccess: () => {
        onSuccess?.();
        toast({
          description: 'Test event sent!',
          status: 'success',
          isClosable: true,
        });
      },
    }
  );
};

export { useNewNotificationsQuery, useNotificationsQuery, useNotificationsTest };
