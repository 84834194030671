import React, { Dispatch, SetStateAction, useContext, useState } from 'react';

import { PoiFiltersSchema } from 'api/models/PoiFiltersSchema';

type PoiFiltersContextType = {
  poiFilters: PoiFiltersSchema | undefined;
  setPoiFilters: Dispatch<SetStateAction<PoiFiltersSchema | undefined>>;
};

type PoiFiltersProviderProps = {
  children: React.ReactNode;
};

const PoiFiltersContext = React.createContext<PoiFiltersContextType | undefined>(undefined);

export const usePoiFilters = () => {
  const context = useContext(PoiFiltersContext);
  if (!context) {
    throw new Error('Missing PoiFiltersProvider');
  }

  return context;
};

export const PoiFiltersProvider = ({ children }: PoiFiltersProviderProps) => {
  const [poiFilters, setPoiFilters] = useState<PoiFiltersSchema | undefined>(undefined);

  return <PoiFiltersContext.Provider value={{ poiFilters, setPoiFilters }}>{children}</PoiFiltersContext.Provider>;
};
