export const urls = {
  WHO_AM_I: 'user',
  AUTH: {
    LOGIN: 'auth/login',
    LOGOUT: 'user/logout',
    CHALLENGE: 'auth/challenge',
    MFA_SETUP: 'auth/mfa',
    MFA_VERIFICATION: 'auth/mfa/verify',
    PASSWORD_RESET: 'auth/password/reset',
    PASSWORD_RESET_CONFIRMATION: 'auth/password/reset/confirm',
    API_KEY: 'apikeys',
    API_KEY_DETAILS: 'apikeys/:apiKeyId',
  },
  ACCOUNT: {
    PASSWORD_CHANGE: 'user/password/change',
    EMAIL_CHANGE: 'user/email/change',
    EMAIL_CHANGE_VERIFICATION: 'user/email/verify',
  },
  USERS: {
    BASE: 'users',
    DETAILS: 'users/:userId',
  },
  DEVICES: {
    GEOFENCE: 'search/map',
    SUGGESTIONS: 'suggestions/trackers',
  },
  DEVICE: {
    COMMENTS: 'devices/:deviceId/comments',
    CONFIGURATION: 'devices/:deviceId/configuration',
    SIM: 'devices/:deviceId/sim',
    HISTORY: 'devices/:deviceId/history',
    SETTINGS: 'devices/:deviceId/settings',
    TRIP: 'devices/:deviceId/trip',
    BLE_DEVICE_NOTICES: 'devices/:deviceId/ble-devices/notices',
    FLIGHTS: 'devices/:deviceId/flights',
    FLIGHT_DETAILS: 'devices/:deviceId/flights/:flightId',
    ITEM_PHOTOS: 'devices/:deviceId/items/:itemId/photos',
  },
  DEVICE_GROUPS: {
    BASE: 'groups',
    DETAILS: 'groups/:groupId',
    DEVICES: 'groups/:groupId/devices',
    DEVICE: 'groups/:groupId/devices/:deviceId',
  },
  FLIGHTS: {
    DETAILS: 'flights/:flightNumber',
  },
  POIS: {
    BASE: 'pois',
    DETAILS: 'pois/:poiId',
    GEOFENCE: 'pois/search/map',
    SUGGESTIONS: 'suggestions/pois',
  },
  POI_GROUPS: {
    BASE: 'pois/groups',
    DETAILS: 'pois/groups/:groupId',
    DEFAULT: 'pois/groups/default',
  },
  BLE_DEVICES: {
    BASE: 'ble-devices',
    DEVICE: 'ble-devices/:bleDeviceId',
    GEOFENCE: 'search/ble-devices',
    NOTICES: 'ble-devices/notices',
    DETAILS: 'ble-devices/:bleDeviceId/notices',
    SUGGESTIONS: 'suggestions/ble-devices',
  },
  BLE_DEVICE_CONFIGURATIONS: {
    BASE: 'experimental/ble-configurations',
    DETAILS: 'experimental/ble-configurations/:bleConfigurationId',
  },
  NOTIFICATIONS: {
    BASE: 'notifications',
    NEW: 'events/history',
    TEST: 'events/test',
  },
};
