import React, { useState } from 'react';
import { Box, Button, Center, Flex } from '@chakra-ui/react';

import { Loader } from 'components/Loader';
import { ConfirmDeleteAlert } from 'components/ConfirmDeleteAlert';
import { DeviceGroupsListItem } from 'components/DeviceGroupsList/DeviceGroupsListItem';
import { AddDeviceGroupModal } from 'components/modals/AddDeviceGroupModal';
import { EditDeviceGroupModal } from 'components/modals/EditDeviceGroupModal';

import { DeviceGroupSchema } from 'api/models/DeviceGroupSchema';
import { useDeviceGroupDelete, useDeviceGroupsQuery } from 'api/deviceGroups';

export const DeviceGroupsList = () => {
  const [editedGroup, setEditedGroup] = useState<DeviceGroupSchema>();
  const [deletedGroup, setDeletedGroup] = useState<DeviceGroupSchema>();
  const { data: deviceGroups, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } = useDeviceGroupsQuery();
  const { mutate: deleteDeviceGroup, isLoading: isDeleting } = useDeviceGroupDelete(deletedGroup?.id, () =>
    setDeletedGroup(undefined)
  );

  return (
    <Flex overflow="hidden" flexDirection="column">
      <AddDeviceGroupModal m={4} />
      <Box position="relative" minHeight={16} overflowY="auto">
        {deviceGroups?.pages?.map((page, index) => (
          <React.Fragment key={index}>
            {page.groups?.map((group) => (
              <DeviceGroupsListItem
                key={group.id}
                group={group}
                onEdit={() => setEditedGroup(group)}
                onDelete={() => setDeletedGroup(group)}
              />
            ))}
          </React.Fragment>
        ))}
        {hasNextPage && (
          <Center py={4}>
            <Button
              onClick={() => fetchNextPage()}
              isLoading={isFetchingNextPage}
              colorScheme="primaryScheme"
              variant="outline"
              size="sm"
            >
              Load more
            </Button>
          </Center>
        )}
        <Loader isLoading={isLoading} top={4} />
      </Box>
      <EditDeviceGroupModal group={editedGroup} onClose={() => setEditedGroup(undefined)} />
      <ConfirmDeleteAlert
        isOpen={!!deletedGroup}
        isLoading={isDeleting}
        onCancel={() => setDeletedGroup(undefined)}
        onDelete={deleteDeviceGroup}
        header={`Deleting group: ${deletedGroup?.name}`}
      />
    </Flex>
  );
};
