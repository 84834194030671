import React, { Dispatch, SetStateAction, useContext, useState } from 'react';

import { DeviceFiltersSchema } from 'api/models/DeviceFiltersSchema';

type DeviceFiltersContextType = {
  deviceFilters: DeviceFiltersSchema | undefined;
  setDeviceFilters: Dispatch<SetStateAction<DeviceFiltersSchema | undefined>>;
};

type DeviceFiltersProviderProps = {
  children: React.ReactNode;
};

const DeviceFiltersContext = React.createContext<DeviceFiltersContextType | undefined>(undefined);

export const useDeviceFilters = () => {
  const context = useContext(DeviceFiltersContext);
  if (!context) {
    throw new Error('Missing DeviceFiltersProvider');
  }

  return context;
};

export const DeviceFiltersProvider = ({ children }: DeviceFiltersProviderProps) => {
  const [deviceFilters, setDeviceFilters] = useState<DeviceFiltersSchema | undefined>(undefined);

  return (
    <DeviceFiltersContext.Provider value={{ deviceFilters, setDeviceFilters }}>
      {children}
    </DeviceFiltersContext.Provider>
  );
};
