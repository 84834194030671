import { InputBaseProps } from './Input';

export enum SwitchValue {
  'true' = 'true',
  'false' = 'false',
}

interface SwitchConfig {
  [SwitchValue.true]: 'ON' | 'on' | 'TRUE' | 'true' | 'ENABLED' | 'enabled' | string;
  [SwitchValue.false]: 'OFF' | 'off' | 'FALSE' | 'false' | 'DISABLED' | 'disabled' | string;
}

export const SwitchValueDefaultLabels: SwitchConfig = {
  [SwitchValue.true]: 'ENABLED',
  [SwitchValue.false]: 'DISABLED',
};

export type InputSwitchLabeledProps = InputBaseProps & {
  switchConfig?: SwitchConfig;
};
