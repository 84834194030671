import React from 'react';
import { FieldValues, FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';

type FormProps<TFormValues> = Omit<React.HTMLProps<HTMLFormElement>, 'form' | 'onSubmit'> & {
  form: UseFormReturn<TFormValues>;
  onSubmit: SubmitHandler<TFormValues>;
  submitOnBlur?: boolean;
  submitOnChange?: boolean;
  children: React.ReactNode;
};

export const Form = <TFormValues extends FieldValues = FieldValues>({
  form,
  onSubmit,
  submitOnBlur,
  submitOnChange,
  children,
  ...rest
}: FormProps<TFormValues>) => {
  const nativeFormProps = {
    onSubmit: form.handleSubmit(onSubmit),
    onBlur: submitOnBlur ? form.handleSubmit(onSubmit) : undefined,
    onChange: submitOnChange ? form.handleSubmit(onSubmit) : undefined,
    ...rest,
  };

  return (
    <FormProvider {...form}>
      <form {...nativeFormProps}>{children}</form>
    </FormProvider>
  );
};
