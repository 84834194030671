import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Badge,
  Box,
  Divider,
  Flex,
  FlexProps,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react';
import { FiMenu } from 'react-icons/fi';
import { IoPowerOutline, IoPersonOutline } from 'react-icons/io5';
import { VscHistory } from 'react-icons/vsc';

import { AuthLogo, AppLogo } from './Logo';
import { AccountSettings } from 'components/AccountSettings';
import { Clock } from 'components/Clock';
import { NewNotificationsDrawer, NotificationsList } from 'components/NotificationsList';
import { NotificationsTest } from 'components/NotificationsList/NotificationsTest';

import { useAuth } from 'hooks/useAuth';
import { useSelectedDevice } from 'hooks/useSelectedDevice';
import { useSelectedPoi } from 'hooks/useSelectedPoi';
import { useSelectedBleDevice } from 'hooks/useSelectedBleDeviceNotice';

import { routes } from 'consts/routes';
import { theme } from 'styles/theme';
import { useLogout } from 'api/auth';

type HeaderTemplateProps = FlexProps & {
  children: React.ReactNode;
};

const HeaderTemplate = ({ children, ...styleProps }: HeaderTemplateProps) => {
  return (
    <Flex
      as="header"
      px={4}
      height={theme.sizes.headerHeight}
      alignItems="center"
      justifyContent="space-between"
      bg="primary"
      {...styleProps}
    >
      {children}
    </Flex>
  );
};

export const AuthHeader = () => {
  return (
    <HeaderTemplate mb={8}>
      <AuthLogo />
    </HeaderTemplate>
  );
};

export const AppHeader = () => {
  const navigate = useNavigate();
  const { isOpen: isSettingsOpen, onOpen: onSettingsOpen, onClose: onSettingsClose } = useDisclosure();
  const { isOpen: isNotificationsOpen, onOpen: onNotificationsOpen, onClose: onNotificationsClose } = useDisclosure();
  const {
    isOpen: isTestNotificationsOpen,
    onOpen: onTestNotificationsOpen,
    onClose: onTestNotificationsClose,
  } = useDisclosure();
  const {
    isOpen: isNewNotificationsOpen,
    onOpen: onNewNotificationsOpen,
    onClose: onNewNotificationsClose,
  } = useDisclosure();
  const { loggedUser } = useAuth();
  const { selectedDevice, setSelectedDevice } = useSelectedDevice();
  const { selectedBleDevice, setSelectedBleDevice } = useSelectedBleDevice();
  const { selectedPoi, setSelectedPoi } = useSelectedPoi();
  const { mutate } = useLogout();

  const handleMenuItemClick = (path: string) => {
    selectedBleDevice && setSelectedBleDevice(null);
    selectedDevice && setSelectedDevice(null);
    selectedPoi && setSelectedPoi(null);
    navigate(path);
  };

  return (
    <HeaderTemplate>
      <Flex alignItems="center">
        <Menu autoSelect={false}>
          <MenuButton
            as={IconButton}
            icon={<FiMenu size={32} />}
            variant="ghost"
            color="white"
            mr={4}
            aria-label="Menu"
            _hover={{ bg: 'transparent' }}
            _active={{ bg: 'transparent' }}
          />
          <MenuList>
            <MenuItem onClick={() => handleMenuItemClick(routes.DEVICES.ROOT)}>Devices</MenuItem>
            <MenuItem onClick={() => handleMenuItemClick(routes.DEVICE_GROUPS.ROOT)}>Device groups</MenuItem>
            <Divider />
            <MenuItem onClick={() => handleMenuItemClick(routes.POIS.ROOT)}>POIs</MenuItem>
            <MenuItem onClick={() => handleMenuItemClick(routes.POI_GROUPS.ROOT)}>POI groups</MenuItem>
            <Divider />
            <MenuItem onClick={() => handleMenuItemClick(routes.BLE_DEVICES.NOTICES)}>BLE latest notices</MenuItem>
            <MenuItem onClick={() => handleMenuItemClick(routes.BLE_DEVICES.ROOT)}>BLE devices</MenuItem>
            <MenuItem onClick={() => handleMenuItemClick(routes.BLE_DEVICES_CONFIGURATIONS.ROOT)}>
              BLE devices configurations
            </MenuItem>
          </MenuList>
        </Menu>
        <AppLogo />
      </Flex>
      <Flex color="white">
        <Clock mr={4} />
        <Box position="relative">
          <Badge colorScheme="red" fontSize="0.5em" position="absolute" top={0} zIndex="docked">
            New
          </Badge>
          <IconButton
            aria-label="New notifications history"
            icon={<VscHistory size={32} />}
            variant="unstyled"
            display="inline-flex"
            onClick={onNewNotificationsOpen}
          />
        </Box>
        <Box position="relative">
          <Badge colorScheme="green" fontSize="0.5em" position="absolute" top={0} zIndex="docked">
            Test
          </Badge>
          <IconButton
            aria-label="New notifications history"
            icon={<VscHistory size={32} />}
            variant="unstyled"
            display="inline-flex"
            onClick={onTestNotificationsOpen}
          />
        </Box>
        <IconButton
          aria-label="Notifications history"
          icon={<VscHistory size={32} />}
          variant="unstyled"
          display="inline-flex"
          onClick={onNotificationsOpen}
        />
        {loggedUser?.is_admin && (
          <IconButton
            aria-label="Account settings"
            icon={<IoPersonOutline size={32} />}
            variant="unstyled"
            display="inline-flex"
            onClick={onSettingsOpen}
          />
        )}
        <IconButton
          aria-label="Logout"
          icon={<IoPowerOutline size={32} />}
          variant="unstyled"
          display="inline-flex"
          onClick={() => mutate({})}
        />
      </Flex>
      <NewNotificationsDrawer isOpen={isNewNotificationsOpen} onClose={onNewNotificationsClose} />
      <NotificationsList isOpen={isNotificationsOpen} onClose={onNotificationsClose} />
      <NotificationsTest isOpen={isTestNotificationsOpen} onClose={onTestNotificationsClose} />
      <AccountSettings isOpen={isSettingsOpen} onClose={onSettingsClose} />
    </HeaderTemplate>
  );
};
