/**
 * Creates a debounced function that delays invoking given function after the specific time in milliseconds have elapsed.
 * @param {function} callback
 * @param {number} wait
 * @returns {function}
 */
export const debounce = (callback: Function, wait: number) => {
  let timeout: NodeJS.Timeout;

  return (...args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => callback(...args), wait);
  };
};

/**
 * Gets the value at path of object.
 * Based on https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_get
 * @param {Object} object
 * @param {string} path
 * @param {*} [defaultValue]
 * @returns {*}
 */
export const get = (object: any, path: string, defaultValue?: any) => {
  const travel = (regexp: RegExp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), object);
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === object ? defaultValue : result;
};
