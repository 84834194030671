import { useEffect } from 'react';
import { Box, Button, Center } from '@chakra-ui/react';

import { BleDeviceListItem } from 'components/BleDeviceListItem';
import { Loader } from 'components/Loader';

import { useBLEDevicesGeofencePaginatedQuery } from 'api/bleDevicesGeofence';
import { useMainData } from 'pages/Main';
import { paginationPageSize } from 'consts/common';

export const BleDeviceList = () => {
  const { bleDevicesData, isBleDevicesDataLoading } = useMainData();

  const {
    data,
    clearData,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage = (bleDevicesData?.count || 1) > paginationPageSize,
  } = useBLEDevicesGeofencePaginatedQuery();

  useEffect(() => {
    // Clear paginated data when devices list is updated (display only the first page)
    if (data) {
      clearData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box position="relative" minHeight={16} overflow="auto">
      {bleDevicesData?.ble_devices?.map((bleDevice, itemIndex) => (
        <BleDeviceListItem key={itemIndex} bleDevice={bleDevice} />
      ))}
      {hasNextPage && (
        <Center py={4}>
          <Button
            onClick={() => fetchNextPage()}
            isLoading={isFetchingNextPage}
            colorScheme="primaryScheme"
            variant="outline"
            size="sm"
          >
            Load more
          </Button>
        </Center>
      )}
      <Loader isLoading={isBleDevicesDataLoading || isFetchingNextPage} top={4} />
    </Box>
  );
};
