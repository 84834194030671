import React from 'react';
import { Flex } from '@chakra-ui/react';
import { object, SchemaOf, string } from 'yup';

import { Form } from 'components/forms/Form';
import { Input } from 'components/forms/Input';
import { SubmitButton } from 'components/forms/SubmitButton';

import { useForm } from 'hooks/useForm';
import { ErrorSchema } from 'api/models/ErrorSchema';
import { DeviceGroupInputSchema } from 'api/models/DeviceGroupInputSchema';

type DeviceGroupFormProps = {
  onSubmit: (values: DeviceGroupInputSchema) => void;
  initialValues?: DeviceGroupInputSchema;
  isLoading?: boolean;
  fieldErrors?: ErrorSchema<DeviceGroupInputSchema>['fields'];
};

const validationSchema: SchemaOf<DeviceGroupInputSchema> = object({
  name: string().required(),
});

export const DeviceGroupForm = ({ onSubmit, initialValues, isLoading, fieldErrors }: DeviceGroupFormProps) => {
  const form = useForm<DeviceGroupInputSchema>({
    initialValues,
    validationSchema,
    fieldErrors,
  });

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Flex>
        <Input type="text" name="name" label="Name" isLabelOnTop={true} isRequired={true} />
        <SubmitButton mt={6} ml={2} isLoading={isLoading}>
          Save
        </SubmitButton>
      </Flex>
    </Form>
  );
};
