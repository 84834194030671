import React from 'react';
import { Text } from '@chakra-ui/react';

type HighlightedTextProps = {
  text: string;
  highlight: string;
};

export const HighlightedText = ({ text, highlight }: HighlightedTextProps) => {
  if (!text) {
    return <Text as="span" />;
  }

  const startIndex = text.indexOf(highlight);

  if (startIndex === -1) {
    return <Text as="span">{text}</Text>;
  }

  const endIndex = startIndex + highlight.length;
  const firstPart = text.slice(0, startIndex);
  const secondPart = text.slice(endIndex);

  return (
    <Text as="span">
      {firstPart}
      <Text as="mark">{highlight}</Text>
      {secondPart}
    </Text>
  );
};
