import React, { ForwardedRef, forwardRef } from 'react';
import { Box, Text } from '@chakra-ui/react';

import { formatDatetime } from 'utils/datetime';

import { BleDeviceNoticeSchema } from 'api/models/BleDeviceNoticeSchema';

type BLEHistoryPointPopupParams = {
  bleHistoryPoint?: Partial<BleDeviceNoticeSchema>;
};

export const BLEHistoryPointPopup = forwardRef(
  ({ bleHistoryPoint }: BLEHistoryPointPopupParams, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <Box fontSize="sm" ref={ref}>
        <Text fontWeight="semibold">{formatDatetime(bleHistoryPoint?.created_at)}</Text>
        {bleHistoryPoint?.location && (
          <>
            <Text mt={2} fontWeight="semibold">
              BLE Device Notice Details
            </Text>
            <Text>Device ID: {`${bleHistoryPoint.device_id}`}</Text>
            <Text>{`Alias: ${bleHistoryPoint.filter_alias ? ` | ${bleHistoryPoint.filter_alias}` : ''}`}</Text>
            <Text>Location: {`${bleHistoryPoint.location.lat}, ${bleHistoryPoint.location.lon}`}</Text>
            <Text>RSSI: {`${bleHistoryPoint.rssi?.toFixed(2)} s`}</Text>
          </>
        )}
      </Box>
    );
  }
);

export const MemoizedBLEHistoryPointPopup = React.memo(BLEHistoryPointPopup);
