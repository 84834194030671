import React, { useEffect, useState } from 'react';
import { Box, Button, ButtonProps, Text } from '@chakra-ui/react';
import { FaCheck } from 'react-icons/fa';

type SubmitButtonProps = ButtonProps & {
  isSuccess?: boolean;
};

export const SubmitButton = ({ isSuccess, children, ...rest }: SubmitButtonProps) => {
  const [showSuccessIcon, setShowSuccessIcon] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      setShowSuccessIcon(true);
      setTimeout(() => setShowSuccessIcon(false), 1500);
    }
  }, [isSuccess]);

  return (
    <Button type="submit" colorScheme="primaryScheme" {...rest}>
      {showSuccessIcon ? (
        <>
          <Box position="absolute">
            <FaCheck />
          </Box>
          <Text as="span" opacity={0}>
            {children}
          </Text>
        </>
      ) : (
        children
      )}
    </Button>
  );
};
