import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';

import { axios } from './axios';
import { urls } from './urls';
import { useMapParams } from 'hooks/useMapParams';
import { resolveUrl } from 'utils/resolveUrl';
import { paginationPageSize } from 'consts/common';
import { BLEDevicesGeofenceSchema } from './models/BLEDevicesGeofenceSchema';

const useBLEDevicesGeofenceQuery = () => {
  const { mapParams } = useMapParams();
  const params = {
    ...mapParams,
  };

  return useQuery<BLEDevicesGeofenceSchema>(
    [urls.BLE_DEVICES.GEOFENCE, params],
    () => axios.get(resolveUrl(urls.BLE_DEVICES.GEOFENCE, {}, params)),
    {
      enabled: !!mapParams,
      keepPreviousData: true,
    }
  );
};

const useBLEDevicesGeofencePaginatedQuery = () => {
  const queryClient = useQueryClient();
  const { mapParams } = useMapParams();

  const clearData = () => queryClient.resetQueries(urls.BLE_DEVICES.GEOFENCE, { exact: true });

  const infinityQuery = useInfiniteQuery<BLEDevicesGeofenceSchema>(
    urls.BLE_DEVICES.GEOFENCE,
    async ({ pageParam = 1 }) =>
      await axios.get(resolveUrl(urls.BLE_DEVICES.GEOFENCE, {}, { ...mapParams, page: pageParam })),
    {
      enabled: false,
      notifyOnChangeProps: ['data'],
      getNextPageParam: (lastPageData, allPagesData) => {
        const pagesNumber = Math.ceil(lastPageData.count / paginationPageSize);
        const nextPage = allPagesData.length + 1;
        return nextPage < pagesNumber ? nextPage : undefined;
      },
    }
  );

  return {
    ...infinityQuery,
    clearData,
  };
};

export { useBLEDevicesGeofenceQuery, useBLEDevicesGeofencePaginatedQuery };
