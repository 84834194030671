import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';

import { useAuth } from 'hooks/useAuth';
import { axios } from './axios';
import { urls } from './urls';
import { ChallengeName } from 'consts/auth';
import { routes } from 'consts/routes';
import { AuthChallengeInputSchema } from './models/AuthChallengeInputSchema';
import { AuthLoginInputSchema } from './models/AuthLoginInputSchema';
import { AuthLoginSchema } from './models/AuthLoginSchema';
import { AuthMfaSetupSchema } from './models/AuthMfaSetupSchema';
import { AuthMfaVerificationInputSchema } from './models/AuthMfaVerificationInputSchema';
import { AuthMfaVerificationSchema } from './models/AuthMfaVerificationSchema';
import { AuthPasswordResetInputSchema } from './models/AuthPasswordResetInputSchema';
import { AuthPasswordResetConfirmationInputSchema } from './models/AuthPasswordResetConfirmationInputSchema';
import { AuthPasswordSetupInputSchema } from './models/AuthPasswordSetupInputSchema';
import { AuthAPIKeyInputSchema } from './models/AuthAPIKeyInputSchema';
import { AuthAPIKeySchema } from './models/AuthAPIKeySchema';
import { EmptySchema } from './models/EmptySchema';
import { ErrorSchema } from './models/ErrorSchema';
import { UserSchema } from './models/UserSchema';

const useInitialQuery = () => {
  return useQuery<UserSchema, ErrorSchema<EmptySchema>>('initial', () => axios.get(urls.WHO_AM_I), {
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
  });
};

const useLogin = () => {
  const { login } = useAuth();

  return useMutation<AuthLoginSchema, ErrorSchema<AuthLoginInputSchema>, AuthLoginInputSchema>(
    (values) => axios.post(urls.AUTH.LOGIN, values),
    {
      onSuccess: (data) => {
        login(data.ChallengeName, data.ChallengeParameters);
      },
    }
  );
};

const useMfaSetup = (isFirstSetup: boolean = false) => {
  const queryClient = useQueryClient();

  const clearData = () => queryClient.resetQueries(urls.AUTH.MFA_SETUP, { exact: true });

  const mfaQuery = useQuery<AuthMfaSetupSchema, ErrorSchema>(
    urls.AUTH.MFA_SETUP,
    () => axios.get(urls.AUTH.MFA_SETUP),
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: isFirstSetup,
    }
  );

  return {
    ...mfaQuery,
    clearData,
  };
};

const useMfaVerification = (isFirstSetup?: boolean, onSuccess?: () => void) => {
  const { username } = useAuth();
  const { mutate } = useMfaChallenge(onSuccess);
  const toast = useToast();

  return useMutation<
    AuthMfaVerificationSchema,
    ErrorSchema<AuthMfaVerificationInputSchema>,
    AuthMfaVerificationInputSchema
  >((values) => axios.post(urls.AUTH.MFA_VERIFICATION, values), {
    onSuccess: (data, { user_code }) => {
      if (isFirstSetup) {
        mutate(
          {
            user_code,
            username,
            challenge_type: ChallengeName.MFA_SETUP,
          },
          {
            onError: (error) => {
              toast({
                description: error.error,
                status: 'error',
                isClosable: true,
              });
            },
          }
        );
      } else {
        onSuccess?.();
        toast({
          description: 'Your MFA configuration has been changed.',
          status: 'success',
          isClosable: true,
        });
      }
    },
  });
};

const usePasswordSetupChallenge = () => {
  const { login } = useAuth();

  return useMutation<AuthLoginSchema, ErrorSchema<AuthPasswordSetupInputSchema>, AuthChallengeInputSchema>(
    (values) => axios.post(urls.AUTH.CHALLENGE, values),
    {
      onSuccess: (data) => {
        login(data.ChallengeName);
      },
    }
  );
};

const useMfaChallenge = (onSuccess?: () => void) => {
  const { login } = useAuth();

  return useMutation<AuthLoginSchema, ErrorSchema<AuthChallengeInputSchema>, AuthChallengeInputSchema>(
    (values) => axios.post(urls.AUTH.CHALLENGE, values),
    {
      onSuccess: (data) => {
        login(data.ChallengeName);
        onSuccess?.();
      },
    }
  );
};

const usePasswordReset = () => {
  const navigate = useNavigate();

  return useMutation<EmptySchema, ErrorSchema<AuthPasswordResetInputSchema>, AuthPasswordResetInputSchema>(
    (values) => axios.post(urls.AUTH.PASSWORD_RESET, values),
    {
      onSuccess: () => {
        navigate(routes.AUTH.PASSWORD_RESET_CONFIRMATION);
      },
    }
  );
};

const usePasswordResetConfirmation = () => {
  const navigate = useNavigate();
  const toast = useToast();

  return useMutation<
    EmptySchema,
    ErrorSchema<AuthPasswordResetConfirmationInputSchema>,
    AuthPasswordResetConfirmationInputSchema
  >((values) => axios.post(urls.AUTH.PASSWORD_RESET_CONFIRMATION, values), {
    onSuccess: () => {
      navigate(routes.AUTH.LOGIN);
      toast({
        description: 'Your password has been changed.',
        status: 'success',
        isClosable: true,
      });
    },
  });
};

const useLogout = () => {
  const { logout } = useAuth();

  return useMutation<EmptySchema, ErrorSchema<EmptySchema>, EmptySchema>(() => axios.post(urls.AUTH.LOGOUT), {
    onSuccess: () => {
      logout();
    },
  });
};

const useGetAPIKey = () => {
  return useQuery<AuthAPIKeySchema, ErrorSchema>(urls.AUTH.API_KEY, () => axios.get(urls.AUTH.API_KEY), {
    keepPreviousData: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: true,
  });
};

const usePostAPIKey = () => {
  const client = useQueryClient();
  return useMutation<AuthAPIKeySchema, ErrorSchema<AuthAPIKeyInputSchema>, AuthAPIKeyInputSchema>(
    (values) => axios.post(urls.AUTH.API_KEY, values),
    {
      retry: false,
      onSuccess: () => {
        client.invalidateQueries(urls.AUTH.API_KEY);
      },
    }
  );
};

const useRemoveAPIKey = () => {
  const client = useQueryClient();
  return useMutation<undefined, ErrorSchema>(() => axios.delete(urls.AUTH.API_KEY), {
    onSuccess: (data) => {
      client.removeQueries(urls.AUTH.API_KEY);
    },
  });
};

export {
  useInitialQuery,
  useLogin,
  useMfaSetup,
  useMfaVerification,
  usePasswordSetupChallenge,
  useMfaChallenge,
  usePasswordReset,
  usePasswordResetConfirmation,
  useLogout,
  usePostAPIKey,
  useGetAPIKey,
  useRemoveAPIKey,
};
