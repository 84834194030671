import React from 'react';
import { Box, Heading } from '@chakra-ui/react';

import { PoisList } from 'components/PoisList';
import { PoisAutocomplete } from 'components/Autocomplete/PoisAutocomplete';
import { PoiFiltersForm } from 'components/forms/PoiFiltersForm';
import { AddPoiModal } from 'components/modals/AddPoiModal';

import { useMainData } from 'pages/Main';
import { usePoiFilters } from 'hooks/usePoiFilters';
import { useSelectedPoi } from 'hooks/useSelectedPoi';
import { PoiGeofenceSchema } from 'api/models/PoiGeofenceSchema';

export const Pois = () => {
  const { setSelectedPoi } = useSelectedPoi();
  const { poiFilters, setPoiFilters } = usePoiFilters();
  const { poisData, isPoisDataLoading } = useMainData();

  const handlePoiSuggestionClick = (poi: PoiGeofenceSchema) => {
    setSelectedPoi(poi);
  };

  return (
    <>
      <Box m={4}>
        <Heading as="h5" size="sm">
          POIs
        </Heading>
        <PoisAutocomplete
          onSuggestionClick={handlePoiSuggestionClick}
          placeholder="Search by name, city or street"
          mt={4}
        />
        <AddPoiModal my={4} />
        <PoiFiltersForm onSubmit={setPoiFilters} initialValues={poiFilters} />
      </Box>
      <PoisList pois={poisData?.pois} totalPois={poisData?.count ?? 0} isLoading={isPoisDataLoading ?? false} />
    </>
  );
};
