import { useEffect } from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';

import { Map } from 'components/Map';
import { MapControls } from 'components/Map/components/MapControls';

import { useMapHistory } from 'hooks/useMapHistory';
import { useMapParams } from 'hooks/useMapParams';
import { useSelectedDevice } from 'hooks/useSelectedDevice';
import { useSelectedPoi } from 'hooks/useSelectedPoi';
import { debounce } from 'utils/utils';
import { theme } from 'styles/theme';
import { useDevicesGeofenceQuery } from 'api/devicesGeofence';
import { usePoisGeofenceQuery } from 'api/poisGeofence';
import { DevicesGeofenceSchema } from 'api/models/DevicesGeofenceSchema';
import { GeofenceQuerySchema } from 'api/models/GeofenceQuerySchema';
import { PoisGeofenceSchema } from 'api/models/PoisGeofenceSchema';
import { useSelectedBleDevice } from 'hooks/useSelectedBleDeviceNotice';
import { useBLEDevicesGeofenceQuery } from 'api/bleDevicesGeofence';
import { BLEDevicesGeofenceSchema } from 'api/models/BLEDevicesGeofenceSchema';

type MainDataContextType = {
  bleDevicesData?: BLEDevicesGeofenceSchema;
  isBleDevicesDataLoading?: boolean;
  devicesData?: DevicesGeofenceSchema;
  isDevicesDataLoading?: boolean;
  poisData?: PoisGeofenceSchema;
  isPoisDataLoading?: boolean;
};

export const Main = () => {
  const {
    deviceId: deviceIdParam,
    poiId: poiIdParam,
    bleDeviceId: bleDeviceIdParam,
  } = useParams<{ deviceId: string; poiId: string; bleDeviceId: string }>();
  const { setMapParams } = useMapParams();
  const { setSelectedPoi } = useSelectedPoi();
  const { setSelectedDevice } = useSelectedDevice();
  const { setSelectedBleDevice } = useSelectedBleDevice();
  const {
    state: { isWideScreen },
  } = useMapHistory();
  const { data: devicesData, isFetching: isDevicesDataLoading } = useDevicesGeofenceQuery();
  const { data: poisData, isFetching: isPoisDataLoading } = usePoisGeofenceQuery();
  const { data: bleDevicesData, isFetching: isBLEDevicesDataLoading } = useBLEDevicesGeofenceQuery();

  // Show device details on the app start if it is selected
  useEffect(() => {
    !devicesData && deviceIdParam && setSelectedDevice(deviceIdParam);
  }, [deviceIdParam]); // eslint-disable-line react-hooks/exhaustive-deps

  // Show POI details on the app start if it is selected
  useEffect(() => {
    !poisData && poiIdParam && setSelectedPoi(poiIdParam);
  }, [poiIdParam]); // eslint-disable-line react-hooks/exhaustive-deps

  // Show POI details on the app start if it is selected
  useEffect(() => {
    bleDeviceIdParam && setSelectedBleDevice(bleDeviceIdParam);
  }, [bleDeviceIdParam]); // eslint-disable-line react-hooks/exhaustive-deps

  const debouncedSetMapParams = debounce(setMapParams, 300);

  const handleMapParamsChange = (params: GeofenceQuerySchema, options: { delay?: boolean }) => {
    options.delay ? debouncedSetMapParams(params) : setMapParams(params);
  };

  return (
    <Flex as="main" flex={1} minHeight={0}>
      <Flex
        flexDirection="column"
        position="relative"
        width={theme.sizes.sidebarWidth}
        bg="white"
        bgColor="white"
        transition="width 500ms"
        sx={
          isWideScreen
            ? { [`@media (min-width: ${theme.sizes.doubleSidebarWidth})`]: { width: theme.sizes.fullSidebarWidth } }
            : {}
        }
      >
        <Outlet
          context={{
            devicesData,
            isDevicesDataLoading,
            bleDevicesData,
            isBLEDevicesDataLoading,
            poisData,
            isPoisDataLoading,
          }}
        />
      </Flex>
      <Box flex={1}>
        <Map
          bleDevicesGeohashes={bleDevicesData?.buckets}
          deviceGeohashes={devicesData?.buckets}
          poiGeohashes={poisData?.buckets}
          onMapUpdate={handleMapParamsChange}
        />
        <MapControls />
      </Box>
    </Flex>
  );
};

// Custom hook for passing data to descendant routes https://reactrouter.com/docs/en/v6/api#useoutletcontext
export const useMainData = () => {
  return useOutletContext<MainDataContextType>();
};
