import React from 'react';
import { Center, Spinner, SpinnerProps } from '@chakra-ui/react';

type LoaderProps = {
  isLoading: boolean;
  size?: SpinnerProps['size'];
  top?: SpinnerProps['top'];
};

export const Loader = ({ isLoading, size = 'lg', top }: LoaderProps) => {
  if (!isLoading) {
    return null;
  }

  return (
    <Center position="absolute" top={0} right={0} bottom={0} left={0} zIndex="overlay">
      <Spinner
        position="absolute"
        top={top}
        thickness="3px"
        speed="0.65s"
        emptyColor="gray.200"
        color="primary"
        size={size}
      />
    </Center>
  );
};
