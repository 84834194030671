import React, { lazy, Suspense } from 'react';
import { Route, Routes as ReactRoutes } from 'react-router-dom';

import { Loader } from 'components/Loader';
import { AppLayout } from 'components/templates/AppLayout';
import { AuthLayout } from 'components/templates/AuthLayout';
import { DetailsContainer } from 'components/templates/DetailsContainer';
import { Login } from 'pages/Login';
import { Main } from 'pages/Main';
import { MfaSetup } from 'pages/MfaSetup';
import { MfaVerification } from 'pages/MfaVerification';
import { NotFound } from 'pages/NotFound';
import { PasswordReset } from 'pages/PasswordReset';
import { PasswordResetConfirmation } from 'pages/PasswordResetConfirmation';
import { PasswordSetup } from 'pages/PasswordSetup';
import { BleDevicesRoute } from 'pages/sidebar/BleDevicesRoute';
import { BleDevicesNoticesRoute } from 'pages/sidebar/BleDevicesNoticesRoute';
import { DeviceGroups } from 'pages/sidebar/DeviceGroups';
import { Devices } from 'pages/sidebar/Devices';
import { PoiGroups } from 'pages/sidebar/PoiGroups';
import { Pois } from 'pages/sidebar/Pois';

import { useAuth } from 'hooks/useAuth';
import { routes } from 'consts/routes';
import { BLEDetailsContainer } from 'components/templates/BLEDetailsContainer';

const BleDeviceNotices = lazy(() => import('pages/sidebar/BleDeviceNoticesRoute'));
const DeviceBleDeviceNotices = lazy(() => import('pages/sidebar/DeviceBleDeviceNoticesRoute'));
const BleDevicesConfigurations = lazy(() => import('pages/sidebar/BleDevicesConfigurationsRoute'));
const DeviceDetails = lazy(() => import('pages/sidebar/DeviceDetailsRoute'));
const DeviceFlights = lazy(() => import('pages/sidebar/DeviceFlightsRoute'));
const DeviceHistory = lazy(() => import('pages/sidebar/DeviceHistoryRoute'));
const DeviceSettings = lazy(() => import('pages/sidebar/DeviceSettingsRoute'));
const DeviceBleSettings = lazy(() => import('pages/sidebar/DeviceBleSettingsRoute'));
const PoiDetails = lazy(() => import('pages/sidebar/PoiDetailsRoute'));

export const Routes = () => {
  const { isInitial } = useAuth();

  if (isInitial) {
    return <Loader isLoading={isInitial} />;
  }

  return (
    <ReactRoutes>
      <Route element={<AppLayout />}>
        <Route path={routes.ROOT} element={<Main />}>
          <Route index element={<Devices />} />
          <Route path={routes.DEVICES.ROOT} element={<Devices />} />
          <Route path={routes.DEVICE_GROUPS.ROOT} element={<DeviceGroups />} />
          <Route path={routes.POIS.ROOT} element={<Pois />} />
          <Route path={routes.POI_GROUPS.ROOT} element={<PoiGroups />} />
          <Route path={routes.BLE_DEVICES.ROOT} element={<BleDevicesRoute />} />
          <Route
            path={routes.BLE_DEVICES_CONFIGURATIONS.ROOT}
            element={
              <Suspense fallback={<Loader isLoading={true} />}>
                <BleDevicesConfigurations />
              </Suspense>
            }
          />
          <Route element={<DetailsContainer />}>
            <Route
              path={routes.DEVICES.DETAILS}
              element={
                <Suspense fallback={<Loader isLoading={true} />}>
                  <DeviceDetails />
                </Suspense>
              }
            />
            <Route
              path={routes.DEVICES.SETTINGS}
              element={
                <Suspense fallback={<Loader isLoading={true} />}>
                  <DeviceSettings />
                </Suspense>
              }
            />
            <Route
              path={routes.DEVICES.FLIGHTS}
              element={
                <Suspense fallback={<Loader isLoading={true} />}>
                  <DeviceFlights />
                </Suspense>
              }
            />
            <Route
              path={routes.DEVICES.HISTORY}
              element={
                <Suspense fallback={<Loader isLoading={true} />}>
                  <DeviceHistory />
                </Suspense>
              }
            />
            <Route
              path={routes.DEVICES.BLE_DEVICE_NOTICES}
              element={
                <Suspense fallback={<Loader isLoading={true} />}>
                  <DeviceBleDeviceNotices />
                </Suspense>
              }
            />
            <Route
              path={routes.BLE_DEVICES.ROOT}
              element={
                <Suspense fallback={<Loader isLoading={true} />}>
                  <BleDevicesRoute />
                </Suspense>
              }
            />
            <Route
              path={routes.BLE_DEVICES.NOTICES}
              element={
                <Suspense fallback={<Loader isLoading={true} />}>
                  <BleDevicesNoticesRoute />
                </Suspense>
              }
            />
            <Route
              path={routes.POIS.DETAILS}
              element={
                <Suspense fallback={<Loader isLoading={true} />}>
                  <PoiDetails />
                </Suspense>
              }
            />
          </Route>
          <Route element={<BLEDetailsContainer />}>
            <Route
              path={routes.BLE_DEVICES.DETAILS}
              element={
                <Suspense fallback={<Loader isLoading={true} />}>
                  <BleDeviceNotices />
                </Suspense>
              }
            />
            <Route
              path={routes.BLE_DEVICES.SETTINGS}
              element={
                <Suspense fallback={<Loader isLoading={true} />}>
                  <DeviceBleSettings />
                </Suspense>
              }
            />
          </Route>
        </Route>
      </Route>
      <Route element={<AuthLayout />}>
        <Route path={routes.AUTH.LOGIN} element={<Login />} />
        <Route path={routes.AUTH.PASSWORD_RESET} element={<PasswordReset />} />
        <Route path={routes.AUTH.PASSWORD_RESET_CONFIRMATION} element={<PasswordResetConfirmation />} />
      </Route>
      <Route element={<AuthLayout isUserRequired={true} />}>
        <Route path={routes.AUTH.PASSWORD_SETUP} element={<PasswordSetup />} />
        <Route path={routes.AUTH.MFA_SETUP} element={<MfaSetup />} />
        <Route path={routes.AUTH.MFA_VERIFICATION} element={<MfaVerification />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </ReactRoutes>
  );
};
