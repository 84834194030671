import React from 'react';
import { useFormContext, useController } from 'react-hook-form';
import {
  useRadioGroup,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Radio,
  ThemingProps,
} from '@chakra-ui/react';

import { get } from 'utils/utils';

type RadioGroupProps = FormControlProps & {
  name: string;
  options: { label: string; value: string | number }[];
  label?: string;
  size?: ThemingProps['size'];
};

export const RadioGroup = ({ name, options, label, size, ...rest }: RadioGroupProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { field } = useController({ name, control });
  const { getRadioProps } = useRadioGroup({
    name,
    onChange: field.onChange,
    value: field.value,
  });
  const error = get(errors, name);

  return (
    <FormControl as="fieldset" isInvalid={!!error} _notFirst={{ mt: 2 }} {...rest}>
      {label && (
        <FormLabel as="legend" fontSize="sm" fontWeight="semibold" m={0}>
          {label}
        </FormLabel>
      )}
      {options.map(({ label, value }, index) => (
        <Radio
          key={index}
          isInvalid={!!error}
          colorScheme="primaryScheme"
          size={size}
          mr={4}
          {...getRadioProps({ value })}
        >
          {label}
        </Radio>
      ))}
      <FormErrorMessage mt={0} fontSize="xs">
        {error?.message}
      </FormErrorMessage>
    </FormControl>
  );
};
