// Minimal latitude
export const minLatitude = -85;

// Maximal latitude
export const maxLatitude = 85;

// Google map config
export const mapConfig = {
  zoom: 3,
  center: { lat: 25, lng: 0 },
  disableDefaultUI: true,
  mapTypeControl: true,
  mapTypeControlOptions: {
    position: google.maps.ControlPosition.TOP_RIGHT,
  },
  zoomControl: true,
  styles: [
    {
      featureType: 'poi',
      stylers: [{ visibility: 'off' }],
    },
  ],
};
