import { Divider, Text } from '@chakra-ui/react';

import { Loader } from 'components/Loader';
import { ErrorMessage } from 'components/ErrorMessage';

import { useRemoveAPIKey, useGetAPIKey, usePostAPIKey } from 'api/auth';

import { APIKeyForm } from './forms/APIKeyForm';
import { ApiKeyDetails } from './ApiKeyDetails';

export const APIKeys = () => {
  const { data: apiKey, error: errorGet, isFetching } = useGetAPIKey();
  const { mutate: remove, error: errorRemove, isLoading: isRemoveLoading } = useRemoveAPIKey();
  const { mutate: create, error: errorCreate, isLoading: isPatchLoading } = usePostAPIKey();

  const error = errorGet?.error === 'Not found.' ? errorCreate?.error || errorRemove?.error : errorGet?.error;
  const isLoading = isPatchLoading || isRemoveLoading || isFetching;

  return (
    <>
      <Text mb={4} fontWeight={600}>
        Here you can set global API Key
      </Text>
      <Divider borderColor="gray.400" my={4} />
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : apiKey ? (
        <ApiKeyDetails apiKey={apiKey} onRemove={() => remove()} />
      ) : (
        <APIKeyForm onSubmit={create} isLoading={isPatchLoading} />
      )}
      <ErrorMessage message={error} />
    </>
  );
};
