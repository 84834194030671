import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@chakra-ui/react';
import { object, string, SchemaOf } from 'yup';

import { Form } from 'components/forms/Form';
import { Input } from 'components/forms/Input';
import { SubmitButton } from 'components/forms/SubmitButton';

import { useForm } from 'hooks/useForm';
import { routes } from 'consts/routes';
import { ErrorSchema } from 'api/models/ErrorSchema';
import { AuthLoginInputSchema } from 'api/models/AuthLoginInputSchema';

type LoginFormProps = {
  onSubmit: (values: AuthLoginInputSchema) => void;
  isLoading?: boolean;
  fieldErrors?: ErrorSchema<AuthLoginInputSchema>['fields'];
};

const validationSchema: SchemaOf<AuthLoginInputSchema> = object({
  username: string().required(),
  password: string().required(),
});

export const LoginForm = ({ onSubmit, isLoading, fieldErrors }: LoginFormProps) => {
  const form = useForm<AuthLoginInputSchema>({ validationSchema, fieldErrors });

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Input type="text" name="username" label="Username" isLabelOnTop={true} />
      <Input type="password" name="password" label="Password" isLabelOnTop={true} />
      <Link as={RouterLink} to={routes.AUTH.PASSWORD_RESET} fontSize="sm">
        Forgot your password?
      </Link>
      <SubmitButton isLoading={isLoading} width="full" mt={2}>
        Log in
      </SubmitButton>
    </Form>
  );
};
