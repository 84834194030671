import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Box, IconButton, Tooltip, VStack } from '@chakra-ui/react';
import { RiArrowGoBackLine, RiInformationLine, RiSettings3Line } from 'react-icons/ri';

import { useSelectedBleDevice } from 'hooks/useSelectedBleDeviceNotice';
import { useSelectedDevice } from 'hooks/useSelectedDevice';
import { useSelectedPoi } from 'hooks/useSelectedPoi';
import { useMapHistory } from 'hooks/useMapHistory';
import { resolveUrl } from 'utils/resolveUrl';
import { routes } from 'consts/routes';
import { theme } from 'styles/theme';

export const BLEDetailsContainer = () => {
  const navigate = useNavigate();
  const { deviceId, poiId, bleDeviceId } = useParams<{ deviceId: string; poiId: string; bleDeviceId: string }>();
  const { selectedBleDevice, setSelectedBleDevice } = useSelectedBleDevice();
  const { selectedDevice, setSelectedDevice } = useSelectedDevice();
  const { selectedPoi, setSelectedPoi } = useSelectedPoi();

  const {
    state: { isWideScreen },
  } = useMapHistory();

  return (
    <>
      <Box position="absolute" top={0} right={0} bottom={0} left={0} bg="white" overflowY="auto">
        <Outlet />
      </Box>
      <Box
        position="absolute"
        left={theme.sizes.sidebarWidth}
        mx={2}
        zIndex="docked"
        transition="left 500ms"
        sx={
          isWideScreen
            ? { [`@media (min-width: ${theme.sizes.doubleSidebarWidth})`]: { left: theme.sizes.fullSidebarWidth } }
            : {}
        }
      >
        <VStack spacing={0} bgColor="white" borderRadius="base">
          <Tooltip label="Back to the list" placement="right" hasArrow>
            <IconButton
              aria-label="Back to the list"
              icon={<RiArrowGoBackLine size={30} />}
              variant="ghost"
              onClick={() => {
                deviceId && selectedDevice && setSelectedDevice(null);
                poiId && selectedPoi && setSelectedPoi(null);
                bleDeviceId && selectedBleDevice && setSelectedBleDevice(null);
                navigate(routes.BLE_DEVICES.ROOT);
              }}
            />
          </Tooltip>
          {bleDeviceId && (
            <>
              <Tooltip label="Device details" placement="right" hasArrow>
                <IconButton
                  aria-label="Device details"
                  icon={<RiInformationLine size={30} />}
                  variant="ghost"
                  onClick={() => navigate(resolveUrl(routes.BLE_DEVICES.DETAILS, { bleDeviceId }))}
                />
              </Tooltip>
              <Tooltip label="Device settings" placement="right" hasArrow>
                <IconButton
                  aria-label="Device settings"
                  icon={<RiSettings3Line size={30} />}
                  variant="ghost"
                  onClick={() => navigate(resolveUrl(routes.BLE_DEVICES.SETTINGS, { bleDeviceId }))}
                />
              </Tooltip>
            </>
          )}
        </VStack>
      </Box>
    </>
  );
};
