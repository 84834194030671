import { useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';

import { Loader } from 'components/Loader';
import { PoiGroupsListItem } from 'components/PoiGroupsList/PoiGroupsListItem';
import { AddPoiGroupModal } from 'components/modals/AddPoiGroupModal';
import { EditPoiGroupModal } from 'components/modals/EditPoiGroupModal';
import { DeletePoiGroupModal } from 'components/modals/DeletePoiGroupModal';

import { usePoiGroupsQuery } from 'api/poiGroups';
import { PoiGroupSchema } from 'api/models/PoiGroupSchema';

export const PoiGroupsList = () => {
  const [editedGroupId, setEditedGroupId] = useState<string | undefined>();
  const [deletedGroupId, setDeletedGroupId] = useState<string | undefined>();
  const { data: poiGroups, isLoading } = usePoiGroupsQuery();

  return (
    <Flex overflow="hidden" flexDirection="column">
      <AddPoiGroupModal m={4} />
      <Box position="relative" minHeight={16} overflowY="auto">
        {poiGroups?.groups?.map((group: PoiGroupSchema) => (
          <PoiGroupsListItem
            key={group.id}
            group={group}
            onEdit={() => setEditedGroupId(group.id)}
            onDelete={() => setDeletedGroupId(group.id)}
          />
        ))}
        <Loader isLoading={isLoading} top={4} />
      </Box>
      {editedGroupId && <EditPoiGroupModal groupId={editedGroupId} onClose={() => setEditedGroupId(undefined)} />}
      <DeletePoiGroupModal
        groups={poiGroups?.groups}
        onClose={() => setDeletedGroupId(undefined)}
        groupId={deletedGroupId}
      />
    </Flex>
  );
};
