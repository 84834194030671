import React, { Key, useEffect, useState } from 'react';
import { useCopyToClipboard } from 'react-use';
import { Box, BoxProps, Button, Text, Tooltip } from '@chakra-ui/react';
import { IoFolderOpenOutline } from 'react-icons/io5';

type CodeBlockProps = BoxProps & {
  code?: string;
  data?: Record<Key, any>;
  noData?: React.ReactNode | string;
};

export const CodeBlock = ({ code, data, noData, ...styleProps }: CodeBlockProps) => {
  const [text, isEmpty] = getCodeBlock(code, data);
  const [tooltipText, setTooltipText] = useState<string>('Copy to clipboard');
  const [copyState, copyToClipboard] = useCopyToClipboard();

  useEffect(() => {
    if (copyState.value) {
      setTooltipText('Copied!');
    } else if (copyState.error?.message) {
      setTooltipText(`Unable to copy: ${copyState.error.message}`);
    }
  }, [copyState]);

  if (isEmpty) {
    return (
      <>
        {noData ?? (
          <Box p="4" display="flex" alignItems="center">
            <IoFolderOpenOutline />
            <Text as="span" size="sm" pl="2">
              No data available
            </Text>
          </Box>
        )}
      </>
    );
  }

  return (
    <Box
      position="relative"
      maxHeight="200px"
      p={2}
      bg="gray.50"
      borderWidth="1px"
      borderRadius="base"
      overflow="auto"
      {...styleProps}
    >
      <Tooltip
        label={tooltipText}
        placement="left"
        hasArrow={true}
        closeOnClick={false}
        onClose={() => setTooltipText('Copy to clipboard')}
      >
        <Button
          position="absolute"
          top={2}
          right={2}
          size="xs"
          variant="outline"
          colorScheme="primaryScheme"
          onClick={() => copyToClipboard(text)}
        >
          Copy
        </Button>
      </Tooltip>
      {!isEmpty && (
        <Text as="pre" fontSize="xs">
          {text}
        </Text>
      )}
    </Box>
  );
};

const getCodeBlock = (code?: string, data?: Record<Key, any>): [string, boolean] => {
  if (code) {
    return [code, !Object.keys(JSON.parse(code)).length];
  }
  if (data) {
    return [JSON.stringify(data, undefined, 2), !Object.keys(data).length];
  }

  return ['', true];
};
