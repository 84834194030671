import { useEffect, useMemo } from 'react';
import { Button, Flex, ThemingProps } from '@chakra-ui/react';

import { Form } from 'components/forms/Form';
import { Input } from 'components/forms/Input';
import { Select } from 'components/forms/Select';
import { SubmitButton } from 'components/forms/SubmitButton';

import { useForm } from 'hooks/useForm';
import { actionTypes, severityTypes, statusTypes } from 'consts/notifications';
import { NewNotificationFiltersSchema } from 'api/models/NewNotificationFiltersSchema';

type NewNotificationFiltersFormProps = {
  onSubmit: (values: NewNotificationFiltersSchema) => void;
  formSize?: ThemingProps['size'];
};

const defaultValues: NewNotificationFiltersSchema = { object_id: '', object: '', action: '', severity: '', status: '' };
const objectOptions = Object.keys(actionTypes).map((object) => ({ value: object, label: object }));
const severityOptions = severityTypes.map((severity) => ({ value: severity, label: severity }));
const statusOptions = statusTypes.map((status) => ({ value: status, label: status }));

export const NewNotificationFiltersForm = ({ onSubmit, formSize }: NewNotificationFiltersFormProps) => {
  const form = useForm<NewNotificationFiltersSchema>({ defaultValues });
  const selectedObject = form.watch('object');

  useEffect(() => {
    const currentValues = form.getValues();
    selectedObject && form.reset({ ...currentValues, action: '' });
  }, [selectedObject]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleResetForm = () => {
    form.reset(defaultValues);
    onSubmit(defaultValues);
  };

  const actionOptions = useMemo(
    () => (selectedObject ? actionTypes[selectedObject].map((action) => ({ value: action, label: action })) : []),
    [selectedObject]
  );

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Flex>
        <Input type="text" name="object_id" label="Object (device) ID" isLabelOnTop={true} size={formSize} mr={2} />
        <Select name="status" label="Status" isLabelOnTop={true} items={statusOptions} size={formSize} />
      </Flex>
      <Flex>
        <Select name="object" label="Object type" isLabelOnTop={true} items={objectOptions} size={formSize} mr={2} />
        <Select
          name="action"
          label="Action type"
          isLabelOnTop={true}
          items={actionOptions}
          isDisabled={!selectedObject}
          size={formSize}
        />
      </Flex>
      <Flex alignItems="flex-end">
        <Select
          name="severity"
          label="Severity level"
          isLabelOnTop={true}
          items={severityOptions}
          size={formSize}
          flexBasis="50%"
          mr={2}
        />
        <Flex flexBasis="50%" justifyContent="flex-end" mb={2}>
          <Button mr={2} size={formSize} variant="outline" colorScheme="primaryScheme" onClick={handleResetForm}>
            Reset
          </Button>
          <SubmitButton size={formSize}>Filter</SubmitButton>
        </Flex>
      </Flex>
    </Form>
  );
};
