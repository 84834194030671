import React from 'react';
import { Box, Button, Collapse, Flex, Link, Tag, Text, Tooltip, useDisclosure } from '@chakra-ui/react';

import { CodeBlock } from 'components/CodeBlock';

import { useSelectedDevice } from 'hooks/useSelectedDevice';
import { formatDatetime, fromNow } from 'utils/datetime';
import { NotificationSchema } from 'api/models/NotificationSchema';

type NotificationsListItemProps = {
  notification: NotificationSchema;
};

export const NotificationsListItem = ({ notification }: NotificationsListItemProps) => {
  const { isOpen, onToggle } = useDisclosure();
  const { setSelectedDevice } = useSelectedDevice();
  const { type, subtype, device_id, created_at, body } = notification;

  return (
    <Box _last={{ borderBottomWidth: '1px' }}>
      <Box px={4} py={2} borderTopWidth="1px">
        <Flex alignItems="center" justifyContent="space-between">
          <Box>
            <Text>
              <Link onClick={() => setSelectedDevice(device_id)} variant="unstyled" fontWeight="semibold">
                {device_id}
              </Link>{' '}
              triggered the <Tag>{`${type}${subtype ? ` | ${subtype}` : ''}`}</Tag> event
            </Text>
            <Text fontSize="xs">
              <Tooltip label={formatDatetime(created_at)}>{`${fromNow(created_at)} ago`}</Tooltip>
            </Text>
          </Box>
          <Button size="xs" variant="outline" colorScheme="primaryScheme" onClick={onToggle}>
            Details
          </Button>
        </Flex>
      </Box>
      <Collapse in={isOpen} unmountOnExit={true}>
        <CodeBlock data={body} />
      </Collapse>
    </Box>
  );
};
