import React from 'react';
import { Center } from '@chakra-ui/react';
import { object, string, SchemaOf } from 'yup';

import { Form } from 'components/forms/Form';
import { PinInput } from 'components/forms/PinInput';
import { SubmitButton } from 'components/forms/SubmitButton';

import { useForm } from 'hooks/useForm';
import { AuthMfaVerificationInputSchema } from 'api/models/AuthMfaVerificationInputSchema';

type MfaVerificationFormProps = {
  onSubmit: (values: AuthMfaVerificationInputSchema) => void;
  isLoading?: boolean;
  isSubmitButtonFull?: boolean;
};

const validationSchema: SchemaOf<AuthMfaVerificationInputSchema> = object({
  user_code: string().required(),
});

export const MfaVerificationForm = ({ onSubmit, isLoading, isSubmitButtonFull }: MfaVerificationFormProps) => {
  const form = useForm<AuthMfaVerificationInputSchema>({ validationSchema });

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Center>
        <PinInput name="user_code" />
      </Center>
      <Center>
        <SubmitButton isLoading={isLoading} width="full" mt={2}>
          Submit
        </SubmitButton>
      </Center>
    </Form>
  );
};
