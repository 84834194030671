import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';

import { UsersItem } from './UsersItem';
import { ConfirmDeleteAlert } from 'components/ConfirmDeleteAlert';
import { ErrorMessage } from 'components/ErrorMessage';
import { Loader } from 'components/Loader';
import { UserForm } from 'components/forms/UserForm';

import { useAuth } from 'hooks/useAuth';
import { useUsersQuery, useUserCreate, useUserDelete } from 'api/users';
import { UserSchema } from 'api/models/UserSchema';

export const Users = () => {
  const [deletedUser, setDeletedUser] = useState<UserSchema | null>(null);
  const { loggedUser } = useAuth();
  const { data: users, isLoading: isUsersLoading } = useUsersQuery();
  const { error: addedUserError, mutate: addUser, isLoading: isUserAdding } = useUserCreate();
  const { mutate: deleteUser, isLoading: isUserDeleting } = useUserDelete();

  const handleUserDelete = () => {
    if (deletedUser) {
      deleteUser(
        { userId: deletedUser.id },
        {
          onSuccess: () => setDeletedUser(null),
        }
      );
    }
  };

  return (
    <>
      <UserForm onSubmit={addUser} isLoading={isUserAdding} fieldErrors={addedUserError?.fields} />
      <ErrorMessage message={addedUserError?.error} />
      <Box position="relative" mt={4}>
        {users?.users?.map((user) => (
          <UsersItem
            key={user.id}
            user={user}
            onDelete={loggedUser?.id === user.id ? undefined : () => setDeletedUser(user)}
          />
        ))}
        <Loader isLoading={isUsersLoading} top={4} />
      </Box>
      <ConfirmDeleteAlert
        isOpen={!!deletedUser}
        isLoading={isUserDeleting}
        onCancel={() => setDeletedUser(null)}
        onDelete={handleUserDelete}
        header={`Deleting user: ${deletedUser?.username}`}
      />
    </>
  );
};
