import { useEffect } from 'react';
import { usePrevious } from 'react-use';
import {
  DefaultValues,
  FieldValues,
  useForm as useReactHookForm,
  UseFormProps as ReactHookUseFormProps,
  UseFormReturn,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AnyObjectSchema } from 'yup';

import { ErrorSchema } from 'api/models/ErrorSchema';

type UseFormProps<TFormValues> = {
  defaultValues?: DefaultValues<TFormValues>;
  initialValues?: DefaultValues<TFormValues>; // For async default values
  validationSchema?: AnyObjectSchema; // TODO ObjectSchema<Shape<{} | undefined, TFormValues>, {}>;
  fieldErrors?: ErrorSchema<TFormValues>['fields'];
  options?: ReactHookUseFormProps<TFormValues>;
};

export const useForm = <TFormValues extends FieldValues = FieldValues>({
  defaultValues,
  initialValues,
  validationSchema,
  fieldErrors,
  options,
}: UseFormProps<TFormValues> = {}): UseFormReturn<TFormValues> => {
  const prevInitialValues = usePrevious(initialValues);
  const resolver = validationSchema ? yupResolver(validationSchema) : undefined;
  const form = useReactHookForm<TFormValues>({
    resolver,
    defaultValues,
    ...options,
  });

  // Can't use the useCustomCompareEffect hook because initialValues can be primitive value (undefined)
  useEffect(() => {
    if (initialValues && JSON.stringify(initialValues) !== JSON.stringify(prevInitialValues)) {
      form.reset(initialValues);
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (fieldErrors) {
      Object.keys(fieldErrors).forEach((key) => {
        // TODO Argument of type 'string' is not assignable to parameter of type 'Path'
        form.setError(key as any, { type: 'server', message: fieldErrors[key] });
      });
    }
  }, [fieldErrors]); // eslint-disable-line react-hooks/exhaustive-deps

  return form;
};
