import React from 'react';

import { ErrorMessage } from 'components/ErrorMessage';
import { PasswordChangeForm } from 'components/forms/PasswordChangeForm';

import { usePasswordChange } from 'api/account';
import { PasswordChangeInputSchema } from 'api/models/PasswordChangeInputSchema';

export const PasswordChange = () => {
  const { error, isLoading, mutate } = usePasswordChange();

  const handleSubmit = ({ matching_new_password, ...values }: PasswordChangeInputSchema, onSuccess?: () => void) => {
    mutate(values, {
      onSuccess: () => onSuccess?.(),
    });
  };

  return (
    <>
      <PasswordChangeForm onSubmit={handleSubmit} isLoading={isLoading} fieldErrors={error?.fields} />
      <ErrorMessage message={error?.error} />
    </>
  );
};
