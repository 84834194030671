import Axios from 'axios';

export const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

axios.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject({ ...error.response?.data, status: error.response?.status })
);
