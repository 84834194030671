import React from 'react';
import {
  Box,
  BoxProps,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

import { ErrorMessage } from 'components/ErrorMessage';
import { DeviceGroupForm } from 'components/forms/DeviceGroupForm';

import { useDeviceGroupCreate } from 'api/deviceGroups';

export const AddDeviceGroupModal = (props: BoxProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { error, mutate, isLoading } = useDeviceGroupCreate(onClose);

  return (
    <Box {...props}>
      <Button onClick={onOpen} size="sm" variant="outline" width="full" colorScheme="primaryScheme">
        Add new device group
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Adding device group</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={4}>
            <DeviceGroupForm onSubmit={mutate} isLoading={isLoading} fieldErrors={error?.fields} />
            <ErrorMessage message={error?.error} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
