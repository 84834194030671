import React from 'react';
import { UseMutateFunction } from 'react-query';
import { Flex } from '@chakra-ui/react';
import { object, SchemaOf, string } from 'yup';

import { Form } from 'components/forms/Form';
import { Input } from 'components/forms/Input';
import { SubmitButton } from 'components/forms/SubmitButton';

import { useForm } from 'hooks/useForm';
import { ErrorSchema } from 'api/models/ErrorSchema';
import { UserCognitoSchema } from 'api/models/UserCognitoSchema';
import { UserInputSchema } from 'api/models/UserInputSchema';

type DeviceGroupFormProps = {
  onSubmit: UseMutateFunction<UserCognitoSchema, ErrorSchema, UserInputSchema>;
  isLoading?: boolean;
  fieldErrors?: ErrorSchema<UserInputSchema>['fields'];
};

const validationSchema: SchemaOf<UserInputSchema> = object({
  email: string().email().required(),
});

export const UserForm = ({ onSubmit, isLoading, fieldErrors }: DeviceGroupFormProps) => {
  const form = useForm<UserInputSchema>({
    validationSchema,
    fieldErrors,
  });

  const submitForm = (values: UserInputSchema) => {
    onSubmit(values, {
      onSuccess: () => form.reset(),
    });
  };

  return (
    <Form form={form} onSubmit={submitForm}>
      <Flex>
        <Input type="text" name="email" placeholder="Email" />
        <SubmitButton ml={2} isLoading={isLoading}>
          Add
        </SubmitButton>
      </Flex>
    </Form>
  );
};
