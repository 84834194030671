import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';

import { axios } from './axios';
import { urls } from './urls';
import { useDeviceFilters } from 'hooks/useDeviceFilters';
import { useMapParams } from 'hooks/useMapParams';
import { resolveUrl } from 'utils/resolveUrl';
import { paginationPageSize } from 'consts/common';
import { DevicesGeofenceSchema } from './models/DevicesGeofenceSchema';

const useDevicesGeofenceQuery = () => {
  const { mapParams } = useMapParams();
  const { deviceFilters } = useDeviceFilters();
  const params = { ...mapParams, ...deviceFilters };

  return useQuery<DevicesGeofenceSchema>(
    [urls.DEVICES.GEOFENCE, params],
    () => axios.get(resolveUrl(urls.DEVICES.GEOFENCE, {}, params)),
    {
      enabled: !!mapParams,
      keepPreviousData: true,
    }
  );
};

const useDevicesGeofencePaginatedQuery = () => {
  const queryClient = useQueryClient();
  const { mapParams } = useMapParams();

  const clearData = () => queryClient.resetQueries(urls.DEVICES.GEOFENCE, { exact: true });

  const infinityQuery = useInfiniteQuery<DevicesGeofenceSchema>(
    urls.DEVICES.GEOFENCE,
    async ({ pageParam = 1 }) =>
      await axios.get(resolveUrl(urls.DEVICES.GEOFENCE, {}, { ...mapParams, page: pageParam })),
    {
      enabled: false,
      notifyOnChangeProps: ['data'],
      getNextPageParam: (lastPageData, allPagesData) => {
        const pagesNumber = Math.ceil(lastPageData.count / paginationPageSize);
        const nextPage = allPagesData.length + 1;
        return nextPage < pagesNumber ? nextPage : undefined;
      },
    }
  );

  return {
    ...infinityQuery,
    clearData,
  };
};

export { useDevicesGeofenceQuery, useDevicesGeofencePaginatedQuery };
