import React, { useState } from 'react';
import QRCode from 'react-qr-code';
import { Box, BoxProps, Button, Center, Code, Text } from '@chakra-ui/react';

import { useAuth } from 'hooks/useAuth';

type MfaConfigProps = BoxProps & {
  secretCode: string;
  isLoading?: boolean;
  isFirstSetup?: boolean;
};

export const MfaQrCode = ({ secretCode, isLoading = false, isFirstSetup = false, ...styleProps }: MfaConfigProps) => {
  const [isSecretShown, setIsSecretShown] = useState<boolean>(false);
  const { username } = useAuth();

  return (
    <Box {...styleProps}>
      <Text>Please scan the QR code or enter the secret key given below into your two-factor authentication app.</Text>
      <Center my={4}>
        <QRCode value={`otpauth://totp/${username}?secret=${secretCode}&issuer=TCC`} />
      </Center>
      <Text fontSize="xs" fontWeight="semibold">
        Secret code
      </Text>
      {!isSecretShown ? (
        <Button size="sm" variant="link" colorScheme="primarySchema" onClick={() => setIsSecretShown(true)}>
          Show
        </Button>
      ) : (
        <Code children={secretCode} wordBreak="break-all" />
      )}
    </Box>
  );
};
