import { useEffect, useState } from 'react';

import { paginationPageSize } from 'consts/common';

export const usePaginationCursor = <TData extends Record<string, any> = Record<string, any>>(
  data: TData[] = [],
  pageSize: number = paginationPageSize
) => {
  const [currentCursor, setCurrentCursor] = useState<number>(pageSize);
  const dataLength = data.length;

  useEffect(() => {
    setCurrentCursor(pageSize);
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    dataToDisplay: data.slice(0, currentCursor),
    isNextPage: currentCursor < dataLength,
    nextPage: () => setCurrentCursor((prevState) => prevState + pageSize),
  };
};
