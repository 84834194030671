import React, { useEffect, useState } from 'react';
import { Text, TextProps } from '@chakra-ui/react';
import { differenceInSeconds } from 'date-fns';

type EtdCounterProps = {
  etd: string;
};

export const EtdCounter = ({ etd, ...rest }: EtdCounterProps & TextProps) => {
  const [seconds, setSeconds] = useState(Math.floor(differenceInSeconds(new Date(etd), new Date())));

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds >= 60) {
        setSeconds((prevSeconds) => prevSeconds - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [etd]); // eslint-disable-line react-hooks/exhaustive-deps

  const minutes = seconds >= 60 ? Math.floor(seconds / 60) : 0;
  let color: string = 'yellow.400';
  if (minutes <= 15) color = 'red.400';
  if (minutes >= 60) color = 'green.400';

  return (
    <Text as="span" color={color} {...rest}>
      {minutes}
    </Text>
  );
};
