import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';

import { LocationSchema } from 'api/models/LocationSchema';
import { MapInstanceItem, MapVisibleItemsType } from 'components/Map/components/MapControls';

type MapInstanceContextType = {
  mapInstance: google.maps.Map | null;
  setMapInstance: Dispatch<SetStateAction<google.maps.Map | null>>;

  location: LocationSchema | null;
  setLocation: Dispatch<SetStateAction<LocationSchema | null>>;
  showOnTheMap: (location: LocationSchema) => void;

  visibleMapItems: MapVisibleItemsType;
  setVisibleMapItems: Dispatch<SetStateAction<MapVisibleItemsType>>;
  setVisibleMapItem: (mapItem: MapInstanceItem, value: boolean) => void;
  toggleMapItem: (mapItem: MapInstanceItem) => void;
};

type MapInstanceProviderProps = {
  children: React.ReactNode;
};

const MapInstanceContext = React.createContext<MapInstanceContextType | null>(null);

export const useMapInstance = () => {
  const context = useContext(MapInstanceContext);
  if (!context) {
    throw new Error('Missing MapInstanceProvider');
  }

  return context;
};

export const MapInstanceProvider = ({ children }: MapInstanceProviderProps) => {
  const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);

  // Map items toggles to specify if they are visible
  const [visibleMapItems, setVisibleMapItems] = useState<MapVisibleItemsType>({
    bleDevice: true,
    device: true,
    poi: true,
  });
  // Helper to toggle one item
  function toggleMapItem(mapItem: MapInstanceItem) {
    setVisibleMapItems({
      ...visibleMapItems,
      [mapItem]: !visibleMapItems[mapItem],
    });
  }
  // Helper to toggle one item
  function setVisibleMapItem(mapItem: MapInstanceItem, value: boolean) {
    setVisibleMapItems({
      ...visibleMapItems,
      [mapItem]: value,
    });
  }

  // Helper to zoom to specific location (lat,lon)
  function showOnTheMap(location: LocationSchema) {
    const { lat, lon: lng } = location;
    mapInstance?.panTo({ lat, lng });
    mapInstance?.setZoom(16);
  }

  // Helper to get location from map on click (lat,lon)
  const [location, setLocation] = useState<LocationSchema | null>(null);
  // Click event listener
  function onLocate(o: { latLng: { lat: () => number; lng: () => number } }) {
    setLocation({ lat: o.latLng.lat(), lon: o.latLng.lng() });
  }
  useEffect(() => {
    if (mapInstance) {
      mapInstance.addListener('click', onLocate);
    }
  }, [mapInstance]);

  return (
    <MapInstanceContext.Provider
      value={{
        mapInstance,
        setMapInstance,
        location,
        setLocation,
        showOnTheMap,
        visibleMapItems,
        setVisibleMapItem,
        setVisibleMapItems,
        toggleMapItem,
      }}
    >
      {children}
    </MapInstanceContext.Provider>
  );
};
