import { useState } from 'react';

import { debounce } from 'utils/utils';
import { PoiInputSchema } from 'api/models/PoiInputSchema';

const parsePlaceDetails = (place: google.maps.places.PlaceResult | null): Partial<PoiInputSchema> => {
  const address: PoiInputSchema['address'] = {};
  let postalCodePrefix = '';
  let postalCodeSuffix = '';
  let postalCode = '';

  place?.address_components?.forEach((component) => {
    const value = component.long_name || component.short_name;
    component.types.forEach((type) => {
      switch (type) {
        case 'street_number':
          address.street_number = value;
          break;
        case 'route':
          address.street = value;
          break;
        case 'locality':
          address.city = value;
          break;
        case 'postal_town':
          address.postal_town = value;
          break;
        case 'postal_code_prefix':
          postalCodePrefix = value;
          break;
        case 'postal_code_suffix':
          postalCodeSuffix = value;
          break;
        case 'postal_code':
          postalCode = value;
          break;
        case 'country':
          address.country = value;
          break;
        default:
          break;
      }
    });
  });

  address.postal_code = postalCodePrefix + postalCodeSuffix || postalCode;

  return {
    address,
    lat: place?.geometry?.location?.lat(),
    lon: place?.geometry?.location?.lng(),
  };
};

export const usePlaceSuggestions = (map: google.maps.Map | null) => {
  const [places, setPlaces] = useState<google.maps.places.AutocompletePrediction[]>([]);
  const [selectedPlace, setSelectedPlace] = useState<Partial<PoiInputSchema>>();
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const getPlaceSuggestions = debounce((value: string) => {
    setIsFetching(true);
    const autocompleteService = new google.maps.places.AutocompleteService();
    autocompleteService.getPlacePredictions({ input: value }, (predictions) => {
      setPlaces(predictions || []);
      setIsFetching(false);
    });
  }, 300);

  const getSelectedPlaceDetails = (suggestion: google.maps.places.AutocompletePrediction) => {
    if (map) {
      const placesService = new google.maps.places.PlacesService(map);
      const request = {
        placeId: suggestion.place_id,
        fields: ['address_components', 'geometry'],
      };
      placesService.getDetails(request, (place) => setSelectedPlace(parsePlaceDetails(place)));
    }
  };

  return {
    isFetching,
    places,
    selectedPlace,
    getPlaceSuggestions,
    getSelectedPlaceDetails,
  };
};
