import { maxLatitude, minLatitude } from 'consts/map';

/**
 * Limits a latitude if it is outside real bounds.
 * @param {number} latitude
 * @returns {number}
 */
export const limitLatitude = (latitude: number) => {
  let lat = latitude;

  if (lat < minLatitude) lat = minLatitude;
  else if (lat > maxLatitude) lat = maxLatitude;

  return lat;
};

/**
 * Get distance between two points on a sphere.
 *
 * @param lat1
 * @param lon1
 * @param lat2
 * @param lon2
 * @param radius (optional) default 6378.137km
 * @returns distance in metres or 0.0m
 */
export const getSphericalDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
  radius: number = 6378.137
): number => {
  const deltaLat = (lat2 * Math.PI) / 180.0 - (lat1 * Math.PI) / 180.0;
  const deltaLon = (lon2 * Math.PI) / 180.0 - (lon1 * Math.PI) / 180.0;

  const a =
    Math.sin(deltaLat / 2.0) * Math.sin(deltaLat / 2.0) +
    Math.cos((lat1 * Math.PI) / 18.0) *
      Math.cos((lat2 * Math.PI) / 180.0) *
      Math.sin(deltaLon / 2.0) *
      Math.sin(deltaLon / 2.0);
  const c = 2.0 * Math.atan2(Math.sqrt(a), Math.sqrt(1.0 - a));
  const d = radius * c;

  if (isNaN(d)) {
    return 0.0;
  }

  return d * 1000.0;
};

/**
 * Get cardinal direction indicators between two points.
 *
 * @param lat1
 * @param lon1
 * @param lat2
 * @param lon2
 * @returns [ 'S' | 'N', 'E' | 'W' ]
 */
export const getDirectionIndicator = (lat1: number, lon1: number, lat2: number, lon2: number): [string, string] => {
  const deltaLat = (lat2 * Math.PI) / 180.0 - (lat1 * Math.PI) / 180.0;
  const deltaLon = (lon2 * Math.PI) / 180.0 - (lon1 * Math.PI) / 180.0;

  return [deltaLon < 0.0 ? 'S' : 'N', deltaLat < 0.0 ? 'E' : 'W'];
};

/**
 * Format distance value to a string.
 *
 * @param value
 * @returns value in metres / km, with 2 digit precision
 */
export const getDistanceString = (value: number): string => {
  if (value > 1000) {
    return `${(value / 1000.0).toFixed(2)}km`;
  }

  return `${value.toFixed(2)}m`;
};
