import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import Chart from 'chart.js/auto';

import { Routes } from './Routes';

import { AuthProvider } from 'hooks/useAuth';
import { theme } from 'styles/theme';

// Import plugins and adapters for chart.js
import annotationPlugin from 'chartjs-plugin-annotation';
import 'chartjs-adapter-date-fns';

// Import styles for third-party libraries
import 'flatpickr/dist/flatpickr.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Import global styles and overrides
import 'styles/googleMap.scss';

// Set defaults for chart.js and register plugins
Chart.defaults.datasets.line.borderWidth = 1;
Chart.defaults.datasets.line.borderColor = theme.colors.primary;
Chart.defaults.datasets.line.pointBackgroundColor = theme.colors.white;
Chart.defaults.datasets.line.pointHoverBackgroundColor = theme.colors.primary;
Chart.defaults.plugins.legend.display = false;
Chart.register(annotationPlugin);

const queryClient = new QueryClient();

export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AuthProvider>
            <Routes />
          </AuthProvider>
        </BrowserRouter>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </ChakraProvider>
  );
};
