import React from 'react';
import { Text } from '@chakra-ui/react';

import { EmailChangeForm } from 'components/forms/EmailChangeForm';
import { EmailChangeVerificationForm } from 'components/forms/EmailChangeVerificationForm';
import { ErrorMessage } from 'components/ErrorMessage';

import { useEmailChange, useEmailChangeVerification } from 'api/account';
import { EmailChangeVerificationInputSchema } from 'api/models/EmailChangeVerificationInputSchema';

export const EmailChange = () => {
  const {
    error: emailError,
    isLoading: isEmailLoading,
    mutate: changeEmail,
    isSuccess: isEmailSuccess,
    reset: resetEmail,
  } = useEmailChange();
  const {
    error: verificationError,
    isLoading: isVerificationLoading,
    mutate: verifyEmailChange,
    reset: resetVerification,
  } = useEmailChangeVerification();

  const handleVerification = (values: EmailChangeVerificationInputSchema) => {
    verifyEmailChange(values, {
      onSuccess: () => {
        resetEmail();
        resetVerification();
      },
    });
  };

  return (
    <>
      <EmailChangeForm onSubmit={changeEmail} isLoading={isEmailLoading} fieldErrors={emailError?.fields} />
      <ErrorMessage message={emailError?.error} />
      {isEmailSuccess && (
        <>
          <Text mt={4} mb={2}>
            Enter a verification code sent to your email.
          </Text>
          <EmailChangeVerificationForm
            onSubmit={handleVerification}
            isLoading={isVerificationLoading}
            fieldErrors={verificationError?.fields}
          />
          <ErrorMessage message={verificationError?.error} />
        </>
      )}
    </>
  );
};
