import {
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { resolveUrl } from 'utils/resolveUrl';

import { routes } from 'consts/routes';
import { formatDatetime } from 'utils/datetime';

import { NotificationsTestHistorySchema } from './NotificationsTest';

type NotificationsTestHistoryProps = { history: NotificationsTestHistorySchema[] };

export const NotificationsTestHistory = ({ history }: NotificationsTestHistoryProps) => {
  if (!history.length) {
    return <Text>Please, send first request.</Text>;
  }
  return (
    <Flex flexDirection="column">
      <Text fontWeight={600} my={2}>
        Last requests
      </Text>
      <Flex>
        <Flex flex="1" justifyContent="center">
          Sent at
        </Flex>
        <Flex flex="1" justifyContent="center">
          Object ID
        </Flex>
        <Flex flex="1" justifyContent="center">
          Status
        </Flex>
        <Flex flex="1" justifyContent="center">
          Actions
        </Flex>
      </Flex>
      <Flex flexDirection="column" maxHeight={180} flex={1} overflow="hidden" overflowY="auto">
        {history.map((entry, idx) => (
          <Flex key={idx} flexDirection="column" my={2} height={80} flex={0}>
            <Flex flex="1">
              <Flex flex="1" justifyContent="center">
                <Text>{formatDatetime(entry.sent_at.toISOString())}</Text>
              </Flex>
              <Flex flex="1" justifyContent="center">
                <Link to={resolveUrl(routes.DEVICES.DETAILS, { deviceId: entry.object_id }) ?? '#'}>
                  <Text noOfLines={1}>{entry.object_id}</Text>
                </Link>
              </Flex>
              <Flex flex="1" justifyContent="center">
                <Text as="span" title={entry.error} noOfLines={1}>
                  {entry.error ? 'Failed' : 'Ok'}
                </Text>
              </Flex>
              <Flex flex="1" justifyContent="center">
                <Popover placement="left-start">
                  <PopoverTrigger>
                    <Button size="sm">Show Details</Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader>
                      <Flex flex="1">
                        <Text>Request info</Text>
                      </Flex>
                    </PopoverHeader>
                    <PopoverBody>
                      <Flex flexDirection="column" maxHeight={200} overflow="auto">
                        <Flex flex="1" justifyContent="space-between">
                          <Text fontWeight={600}>Severity:</Text>
                          <Text>{entry.severity}</Text>
                        </Flex>
                        <Flex flex="1" justifyContent="space-between">
                          <Text fontWeight={600}>Object:</Text>
                          <Text>{entry.object}</Text>
                        </Flex>
                        <Flex flex="1" justifyContent="space-between">
                          <Text fontWeight={600}>Action:</Text>
                          <Text>{entry.action}</Text>
                        </Flex>
                        <Flex flex="1" justifyContent="space-between">
                          <Text fontWeight={600}>Occured at:</Text>
                          <Text>{formatDatetime(entry.occurred_at)}</Text>
                        </Flex>
                      </Flex>
                      {entry.data && (
                        <>
                          <Flex fontWeight={600} flex="1">
                            Data:
                          </Flex>
                          <Flex overflowY="auto">
                            <pre>{JSON.stringify(entry.data, null, 2)}</pre>
                          </Flex>
                        </>
                      )}
                      {entry.error && (
                        <>
                          <Flex fontWeight={600} flex="1">
                            Error:
                          </Flex>
                          <Flex overflowY="auto">
                            <pre>{JSON.stringify(entry.error, null, 2)}</pre>
                          </Flex>
                        </>
                      )}
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Flex>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
