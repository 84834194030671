import { useEffect, useMemo, useState } from 'react';
import { mixed, object, SchemaOf, string } from 'yup';
import { useDebounce } from 'react-use';
import { DatePicker } from 'components/DatePicker';
import { startOfToday } from 'date-fns';
import { Button, Flex, Progress, Text, ThemingProps } from '@chakra-ui/react';

import { useForm } from 'hooks/useForm';
import { toUtc } from 'utils/datetime';
import { actionTypes, ObjectType, SeverityType, severityTypes } from 'consts/notifications';

import { Form } from 'components/forms/Form';
import { Input } from 'components/forms/Input';
import { Select } from 'components/forms/Select';
import { SubmitButton } from 'components/forms/SubmitButton';
import { ErrorMessage } from 'components/ErrorMessage';
import { ActionType } from 'consts/notifications';

import { NotificationsTestSchema } from 'api/models/NotificationsTestSchema';

type NotificationsTestFormProps = {
  onSubmit: (values: NotificationsTestSchema) => void;
  formSize?: ThemingProps['size'];
  isLoading: boolean;
};

const startDate = startOfToday();
const defaultValues: NotificationsTestFormSchema = {
  object_id: '700000000000001',
  object: ObjectType['device.data'],
  action: undefined,
  severity: SeverityType.info,
  data_raw: '{\n    "field": "value"\n}',
  occurred_at: toUtc(startDate).toISOString(),
};
const objectOptions = Object.keys(actionTypes).map((object) => ({ value: object, label: object }));
const severityOptions = severityTypes.map((severity) => ({ value: severity, label: severity }));

const validationSchema: SchemaOf<NotificationsTestFormSchema> = object({
  object_id: string().typeError('Please, put object (device) id').required(),
  object: mixed<ObjectType>().required(),
  action: mixed<ActionType>().required(),
  severity: mixed<SeverityType>().required(),
  data: object(),
  data_raw: string(),
  occurred_at: string().required(),
});

export interface NotificationsTestFormSchema extends NotificationsTestSchema {
  data_raw?: string;
}

export const NotificationsTestForm = ({ onSubmit, formSize, isLoading }: NotificationsTestFormProps) => {
  const form = useForm<NotificationsTestFormSchema>({ defaultValues, validationSchema });
  const selectedObject = form.watch('object');
  const data = form.watch('data_raw');

  // Track validation of provided JSON data, validating for showing progress bar
  const [isDataValid, setIsDataValid] = useState<undefined | string | 'validating'>();
  const [occuredAt, setOccuredAt] = useState<string>(toUtc(startDate).toISOString());

  useEffect(() => {
    form.setValue('action', undefined);
  }, [selectedObject]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleResetForm = () => {
    form.reset(defaultValues);
  };

  const actionOptions = useMemo(
    () => (selectedObject ? actionTypes[selectedObject].map((action) => ({ value: action, label: action })) : []),
    [selectedObject]
  );

  const handleSubmit = (values: NotificationsTestFormSchema) => {
    delete values.data_raw;
    values.occurred_at = occuredAt.toString();
    onSubmit(values);
  };

  //Beautify and validate provided JSON string
  const handleFormatData = () => {
    if (!data) {
      form.setValue('data', undefined);
      setIsDataValid(undefined);
      return;
    }
    try {
      const parsed = JSON.parse(data.toString());
      const formated = JSON.stringify(parsed, null, 4);
      JSON.parse(formated);
      form.setValue('data_raw', formated);
      form.setValue('data', parsed);
      setIsDataValid(undefined);
    } catch (err: any) {
      setIsDataValid(err.message);
    }
  };
  useDebounce(handleFormatData, 600, [data]);
  useDebounce(() => data?.length && setIsDataValid('validating'), 200, [data]);

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Flex alignItems="flex-end">
        <Flex flexBasis="100%" justifyContent="flex-end" mb={2}>
          <Button mr={2} size={formSize} variant="outline" colorScheme="primaryScheme" onClick={handleResetForm}>
            Reset
          </Button>
          <SubmitButton
            title="JSON data is invalid. Please fix it."
            isDisabled={!!isDataValid}
            isLoading={isLoading}
            size={formSize}
          >
            Send
          </SubmitButton>
        </Flex>
      </Flex>
      <Flex>
        <Flex flex="1" mr={2}>
          <Input
            isRequired
            type="text"
            name="object_id"
            label="Object (device) ID"
            isLabelOnTop={true}
            size={formSize}
          />
        </Flex>
        <Flex flex="1" flexDirection="column">
          <Flex>
            <Text as="span" mb={1} fontSize="sm">
              Occured at
            </Text>
          </Flex>
          <Flex>
            <DatePicker
              onChange={setOccuredAt}
              inputProps={{ size: 'sm', focusBorderColor: 'primary' }}
              options={{
                defaultDate: occuredAt,
                mode: 'single',
                enableTime: true,
                allowInput: true,
              }}
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex>
        <Flex flex="1" mr={2}>
          <Select
            isRequired
            name="object"
            label="Object type"
            isLabelOnTop={true}
            items={objectOptions}
            size={formSize}
          />
        </Flex>
        <Flex flex="1">
          <Select
            isRequired
            name="action"
            label="Action type"
            isLabelOnTop={true}
            items={actionOptions}
            isDisabled={!selectedObject}
            size={formSize}
          />
        </Flex>
      </Flex>
      <Flex>
        <Flex flex="1">
          <Select
            isRequired
            name="severity"
            label="Severity level"
            isLabelOnTop={true}
            items={severityOptions}
            size={formSize}
            flexBasis="100%"
            mr={2}
          />
        </Flex>
      </Flex>
      <Flex>
        <Input
          placeholder={`{\n  "example": true\n}`}
          type="textarea"
          name="data_raw"
          label="Data"
          isLabelOnTop={true}
          size={formSize}
          textareaConfig={{ noOfLines: 16, minHeight: 120, resize: 'block' }}
        />
      </Flex>
      <Flex mb={4}>
        {isDataValid === 'validating' ? (
          <Progress width="full" colorScheme="primaryScheme" size={formSize} isIndeterminate />
        ) : (
          <ErrorMessage message={isDataValid} />
        )}
      </Flex>
    </Form>
  );
};
