import React from 'react';
import { Box, Button, Text } from '@chakra-ui/react';
import { AuthAPIKeySchema } from 'api/models/AuthAPIKeySchema';
import { IoRemoveCircleOutline } from 'react-icons/io5';
import { formatDatetime } from '../utils/datetime';

export const ApiKeyDetails = ({ apiKey, onRemove }: ApiKeyDetailsProps) => {
  return (
    <Box pt={4}>
      <Text as="span" mr={2}>
        Updated on:
      </Text>
      <Text as="span" fontWeight={600}>
        {formatDatetime(apiKey.created_at)}
      </Text>
      <Button colorScheme="red" onClick={onRemove} width="full" mt={4}>
        <Text mr={2}>
          <IoRemoveCircleOutline />
        </Text>
        Remove
      </Button>
    </Box>
  );
};
type ApiKeyDetailsProps = { apiKey: AuthAPIKeySchema; onRemove: () => void };
