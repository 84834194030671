import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useToast } from '@chakra-ui/react';

import { axios } from './axios';
import { urls } from './urls';
import { useMapParams } from 'hooks/useMapParams';
import { usePoiFilters } from 'hooks/usePoiFilters';
import { useSelectedPoi } from 'hooks/useSelectedPoi';
import { resolveUrl } from 'utils/resolveUrl';
import { PoiType } from 'consts/pois';
import { EmptySchema } from './models/EmptySchema';
import { ErrorSchema } from './models/ErrorSchema';
import { PoiSchema } from './models/PoiSchema';
import { PoiInputSchema } from './models/PoiInputSchema';
import { PoiSuggestionsSchema } from './models/PoiSuggestionsSchema';
import { PoiGeofenceSchema } from 'api/models/PoiGeofenceSchema';

const usePoiQuery = (poiId?: string) => {
  return useQuery<PoiSchema>(
    [urls.POIS.DETAILS, poiId],
    () => axios.get(resolveUrl(urls.POIS.DETAILS, { poiId: poiId })),
    {
      enabled: !!poiId,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

const usePoiCreate = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const { mapParams } = useMapParams();
  const { poiFilters } = usePoiFilters();
  const toast = useToast();

  return useMutation<PoiSchema, ErrorSchema<PoiInputSchema>, PoiInputSchema>(
    (values) => axios.post(urls.POIS.BASE, values),
    {
      onSuccess: () => {
        onSuccess?.();
        // Delay the request due to waiting for Elastic reindexing on the backend side
        // TODO Display loader (on the map and the list) while fetching POIs
        setTimeout(() => {
          queryClient.invalidateQueries([urls.POIS.GEOFENCE, { ...mapParams, ...poiFilters }]);
        }, 2000);
        toast({
          description: 'The POI has been created.',
          status: 'success',
          isClosable: true,
        });
      },
    }
  );
};

const usePoiUpdate = (poiId?: string, onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const { mapParams } = useMapParams();
  const { poiFilters } = usePoiFilters();
  const { setSelectedPoi } = useSelectedPoi();

  return useMutation<PoiSchema, ErrorSchema<PoiInputSchema>, PoiInputSchema>(
    (values) => axios.patch(resolveUrl(urls.POIS.DETAILS, { poiId: poiId }), values),
    {
      onSuccess: (data) => {
        const { lat, lon, ...poi } = data;
        onSuccess?.();
        setSelectedPoi({ ...poi, location: { lat, lon } });
        // Delay the request due to waiting for Elastic reindexing on the backend side
        // TODO Display loader (on the map and the list) while fetching POIs
        setTimeout(() => {
          queryClient.invalidateQueries([urls.POIS.GEOFENCE, { ...mapParams, ...poiFilters }]);
        }, 2000);
      },
    }
  );
};

const usePoiDelete = (poiId?: string, onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const { mapParams } = useMapParams();
  const { poiFilters } = usePoiFilters();
  const { setSelectedPoi } = useSelectedPoi();
  const toast = useToast();

  return useMutation<EmptySchema, ErrorSchema<EmptySchema>, EmptySchema>(
    () => axios.delete(resolveUrl(urls.POIS.DETAILS, { poiId: poiId })),
    {
      onSuccess: () => {
        onSuccess?.();
        setSelectedPoi(null);
        // Delay the request due to waiting for Elastic reindexing on the backend side
        // TODO Display loader (on the map and the list) while fetching POIs
        setTimeout(() => {
          queryClient.invalidateQueries([urls.POIS.GEOFENCE, { ...mapParams, ...poiFilters }]);
        }, 2000);
        toast({
          description: 'The POI has been deleted.',
          status: 'success',
          isClosable: true,
        });
      },
      onError: (error) => {
        toast({
          description: error.error,
          status: 'error',
          isClosable: true,
        });
      },
    }
  );
};

const usePoiSuggestionsQuery = (query?: string, types?: PoiType[]) => {
  const queryParams = {
    query,
    types,
  };

  return useQuery<PoiGeofenceSchema[]>(
    [urls.POIS.SUGGESTIONS, queryParams],
    async () => {
      const data: PoiSuggestionsSchema = await axios.get(resolveUrl(urls.POIS.SUGGESTIONS, {}, queryParams));
      let results: PoiGeofenceSchema[] = [];
      data.suggestions.forEach((suggestion) => results.push(suggestion.poi));
      return results;
    },
    {
      enabled: !!query,
      refetchOnWindowFocus: false,
    }
  );
};

export { usePoiQuery, usePoiCreate, usePoiUpdate, usePoiDelete, usePoiSuggestionsQuery };
