import React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';

type ConfirmDeleteAlertProps = {
  isOpen: boolean;
  isLoading: boolean;
  onCancel: () => void;
  onDelete: (...args: any[]) => void;
  children?: React.ReactNode;
  header?: React.ReactNode;
};

export const ConfirmDeleteAlert = ({
  isOpen,
  isLoading,
  onCancel,
  onDelete,
  children,
  header,
}: ConfirmDeleteAlertProps) => {
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onCancel}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {header}
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>{children || 'Are you sure you want to delete this item?'}</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onCancel}>
              Cancel
            </Button>
            <Button onClick={onDelete} isLoading={isLoading} colorScheme="red" ml={3}>
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
