import React, { Dispatch, SetStateAction, useContext, useState } from 'react';

import { GeofenceQuerySchema } from 'api/models/GeofenceQuerySchema';

type MapParamsContextType = {
  mapParams: GeofenceQuerySchema | undefined;
  setMapParams: Dispatch<SetStateAction<GeofenceQuerySchema | undefined>>;
};

type MapParamsProviderProps = {
  children: React.ReactNode;
};

const MapParamsContext = React.createContext<MapParamsContextType | undefined>(undefined);

export const useMapParams = () => {
  const context = useContext(MapParamsContext);
  if (!context) {
    throw new Error('Missing MapParamsProvider');
  }

  return context;
};

export const MapParamsProvider = ({ children }: MapParamsProviderProps) => {
  const [mapParams, setMapParams] = useState<GeofenceQuerySchema | undefined>();

  return <MapParamsContext.Provider value={{ mapParams, setMapParams }}>{children}</MapParamsContext.Provider>;
};
