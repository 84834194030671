import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { useToast } from '@chakra-ui/react';

import { axios } from './axios';
import { urls } from './urls';
import { resolveUrl } from 'utils/resolveUrl';
import { EmptySchema } from './models/EmptySchema';
import { ErrorSchema } from './models/ErrorSchema';
import { DeviceGroupSchema } from './models/DeviceGroupSchema';
import { DeviceGroupsSchema } from './models/DeviceGroupsSchema';
import { DeviceGroupInputSchema } from './models/DeviceGroupInputSchema';
import { GroupDevicesInputSchema } from './models/GroupDevicesInputSchema';
import { GroupDevicesListSchema } from './models/GroupDevicesListSchema';

const useDeviceGroupsQuery = () => {
  return useInfiniteQuery<DeviceGroupsSchema>(
    urls.DEVICE_GROUPS.BASE,
    async ({ pageParam = 0 }) => await axios.get(resolveUrl(urls.DEVICE_GROUPS.BASE, {}, { page: pageParam })),
    {
      getNextPageParam: (lastPageData) => {
        const pagesNumber = Math.ceil(lastPageData.total / lastPageData.limit);
        const nextPage = lastPageData.page + 1;
        return nextPage < pagesNumber ? nextPage : undefined;
      },
    }
  );
};
const useGroupDevicesQuery = (groupId: string) => {
  return useQuery<GroupDevicesListSchema>(
    [urls.DEVICE_GROUPS.DEVICES, groupId],
    async ({ pageParam = 0 }) =>
      await axios.get(resolveUrl(urls.DEVICE_GROUPS.DEVICES, { groupId }, { page: pageParam }))
  );
};
const useDeviceGroupQuery = (groupId: string) => {
  return useQuery<DeviceGroupSchema>(
    [urls.DEVICE_GROUPS.DETAILS, groupId],
    () => axios.get(resolveUrl(urls.DEVICE_GROUPS.DETAILS, { groupId })),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

const useDeviceGroupCreate = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation<DeviceGroupSchema, ErrorSchema<DeviceGroupInputSchema>, DeviceGroupInputSchema>(
    (values) => axios.post(urls.DEVICE_GROUPS.BASE, values),
    {
      onSuccess: () => {
        onSuccess?.();
        queryClient.invalidateQueries(urls.DEVICE_GROUPS.BASE);
        toast({
          description: 'The device group has been created.',
          status: 'success',
          isClosable: true,
        });
      },
    }
  );
};

const useDeviceGroupUpdate = (groupId?: string, onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation<DeviceGroupSchema, ErrorSchema<DeviceGroupInputSchema>, DeviceGroupInputSchema>(
    (values) => axios.patch(resolveUrl(urls.DEVICE_GROUPS.DETAILS, { groupId }), values),
    {
      onSuccess: () => {
        onSuccess?.();
        queryClient.invalidateQueries(urls.DEVICE_GROUPS.BASE);
      },
    }
  );
};

const useDeviceGroupDelete = (groupId?: string, onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation<EmptySchema, ErrorSchema<EmptySchema>, EmptySchema>(
    () => axios.delete(resolveUrl(urls.DEVICE_GROUPS.DETAILS, { groupId })),
    {
      onSuccess: () => {
        onSuccess?.();
        queryClient.invalidateQueries(urls.DEVICE_GROUPS.BASE);
        toast({
          description: 'The device group has been deleted.',
          status: 'success',
          isClosable: true,
        });
      },
      onError: (error) => {
        toast({
          description: error.error,
          status: 'error',
          isClosable: true,
        });
      },
    }
  );
};

const useDeviceAdd = (groupId?: string, onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation<DeviceGroupSchema, ErrorSchema<GroupDevicesInputSchema>, GroupDevicesInputSchema>(
    (values) => axios.post(resolveUrl(urls.DEVICE_GROUPS.DEVICES, { groupId }), values),
    {
      onSuccess: () => {
        onSuccess?.();
        queryClient.invalidateQueries([urls.DEVICE_GROUPS.DEVICES, groupId]);
      },
    }
  );
};

const useDeviceDelete = (groupId?: string, deviceId?: number, onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation<EmptySchema, ErrorSchema<EmptySchema>, EmptySchema>(
    () => axios.delete(resolveUrl(urls.DEVICE_GROUPS.DEVICE, { groupId, deviceId })),
    {
      onSuccess: () => {
        onSuccess?.();
        queryClient.invalidateQueries([urls.DEVICE_GROUPS.DEVICES, groupId]);
      },
    }
  );
};

export {
  useDeviceGroupsQuery,
  useDeviceGroupCreate,
  useDeviceGroupDelete,
  useDeviceGroupUpdate,
  useDeviceGroupQuery,
  useGroupDevicesQuery,
  useDeviceAdd,
  useDeviceDelete,
};
