import React, { useState } from 'react';
import { BoxProps, Flex, Text } from '@chakra-ui/react';

import { Autocomplete } from '.';
import { HighlightedText } from 'components/HighlightedText';

import { useSelectedDevice } from 'hooks/useSelectedDevice';
import { debounce } from 'utils/utils';
import { useDeviceSuggestionsQuery } from 'api/devices';
import { DeviceSchema } from 'api/models/DeviceSchema';

export const DevicesAutocomplete = (styleProps: BoxProps) => {
  const [query, setQuery] = useState<string>('');
  const { setSelectedDevice } = useSelectedDevice();
  const { data, isLoading } = useDeviceSuggestionsQuery(query);

  const debouncedSetQuery = debounce(setQuery, 300);

  return (
    <Autocomplete
      items={data}
      placeholder="Search by ID or name"
      renderSuggestion={DeviceSuggestion}
      onInputChange={debouncedSetQuery}
      onSuggestionClick={setSelectedDevice}
      isLoading={isLoading}
      {...styleProps}
    />
  );
};

export const DeviceSuggestion = (device: DeviceSchema, query: string) => {
  const { id, name, updated_at } = device;

  return (
    <>
      <Text fontWeight="semibold">
        <HighlightedText text={name || 'unknown'} highlight={query} />
      </Text>
      <Flex alignItems="center" justifyContent="space-between">
        <Text>
          <HighlightedText text={id.toString()} highlight={query} />
        </Text>
        {!updated_at && (
          <Text fontSize="xs" color="red.500">
            No connection
          </Text>
        )}
      </Flex>
    </>
  );
};
