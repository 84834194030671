import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useToast } from '@chakra-ui/react';

import { SelectItemType } from 'components/forms/Select';

import { axios } from './axios';
import { urls } from './urls';
import { resolveUrl } from 'utils/resolveUrl';
import { EmptySchema } from './models/EmptySchema';
import { ErrorSchema } from './models/ErrorSchema';
import { PoiGroupSchema } from './models/PoiGroupSchema';
import { PoiGroupDeleteSchema } from './models/PoiGroupDeleteSchema';
import { PoiGroupInputSchema } from './models/PoiGroupInputSchema';
import { PoiGroupsSchema } from './models/PoiGroupsSchema';

const usePoiGroupOptionsQuery = () => {
  return useQuery<SelectItemType[]>(
    [urls.POI_GROUPS.BASE, 'options'],
    async () => {
      const data: PoiGroupsSchema = await axios.get(urls.POI_GROUPS.BASE);
      return data.groups?.map((group) => ({ label: group.name, value: group.id }));
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

const usePoiGroupsQuery = () => {
  return useQuery<PoiGroupsSchema>(urls.POI_GROUPS.BASE, () => axios.get(urls.POI_GROUPS.BASE), {
    keepPreviousData: true,
  });
};

const useDefaultPoiGroupsQuery = () => {
  return useQuery<PoiGroupSchema>(urls.POI_GROUPS.DEFAULT, () => axios.get(urls.POI_GROUPS.DEFAULT), {
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

const usePoiGroupQuery = (groupId?: string) => {
  return useQuery<PoiGroupSchema>(
    [urls.POI_GROUPS.DETAILS, groupId],
    async () => {
      // Transform null values to undefined due to the form validation issues
      const { heartbeat, radius, ...rest }: PoiGroupSchema = await axios.get(
        resolveUrl(urls.POI_GROUPS.DETAILS, { groupId })
      );
      return {
        heartbeat: heartbeat ?? undefined,
        radius: radius ?? undefined,
        ...rest,
      };
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

const usePoiGroupCreate = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation<PoiGroupSchema, ErrorSchema<PoiGroupInputSchema>, PoiGroupInputSchema>(
    (values) => axios.post(urls.POI_GROUPS.BASE, values),
    {
      onSuccess: () => {
        onSuccess?.();
        queryClient.invalidateQueries([urls.POI_GROUPS.BASE]);
        toast({
          description: 'The POI group has been created.',
          status: 'success',
          isClosable: true,
        });
      },
    }
  );
};

const usePoiGroupUpdate = (groupId?: string, onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation<PoiGroupSchema, ErrorSchema<PoiGroupInputSchema>, PoiGroupInputSchema>(
    (values) => axios.patch(resolveUrl(urls.POI_GROUPS.DETAILS, { groupId }), values),
    {
      onSuccess: () => {
        onSuccess?.();
        queryClient.invalidateQueries([urls.POI_GROUPS.BASE]);
      },
    }
  );
};

const usePoiGroupDelete = (groupId?: string, onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation<EmptySchema, ErrorSchema<PoiGroupDeleteSchema>, PoiGroupDeleteSchema>(
    (payload) => axios.delete(resolveUrl(urls.POI_GROUPS.DETAILS, { groupId }, { ...payload })),
    {
      onSuccess: () => {
        onSuccess?.();
        queryClient.invalidateQueries([urls.POI_GROUPS.BASE]);
        toast({
          description: 'The POI group has been deleted.',
          status: 'success',
          isClosable: true,
        });
      },
      onError: (error) => {
        toast({
          description: error.error,
          status: 'error',
          isClosable: true,
        });
      },
    }
  );
};

export {
  usePoiGroupOptionsQuery,
  usePoiGroupsQuery,
  usePoiGroupQuery,
  useDefaultPoiGroupsQuery,
  usePoiGroupCreate,
  usePoiGroupUpdate,
  usePoiGroupDelete,
};
