import React, { useEffect } from 'react';
import { Flex } from '@chakra-ui/react';
import { object, SchemaOf, string, number } from 'yup';

import { Form } from 'components/forms/Form';
import { Input } from 'components/forms/Input';
import { SubmitButton } from 'components/forms/SubmitButton';

import { useForm } from 'hooks/useForm';
import { ErrorSchema } from 'api/models/ErrorSchema';
import { PoiGroupInputSchema } from 'api/models/PoiGroupInputSchema';
import { PoiGroupSchema } from 'api/models/PoiGroupSchema';

type PoiGroupFormProps = {
  onSubmit: (values: PoiGroupInputSchema) => void;
  defaultValues?: PoiGroupSchema;
  initialValues?: PoiGroupInputSchema;
  isLoading?: boolean;
  fieldErrors?: ErrorSchema<PoiGroupInputSchema>['fields'];
};

const validationSchema: SchemaOf<PoiGroupInputSchema> = object({
  name: string().required(),
  // Transform empty string because of https://github.com/react-hook-form/react-hook-form/issues/908
  heartbeat: number()
    .integer()
    .transform((cv, ov) => (ov === '' ? undefined : cv)),
  // Transform empty string because of https://github.com/react-hook-form/react-hook-form/issues/908
  radius: number()
    .integer()
    .transform((cv, ov) => (ov === '' ? undefined : cv)),
});

export const PoiGroupForm = ({ onSubmit, defaultValues, initialValues, isLoading, fieldErrors }: PoiGroupFormProps) => {
  const form = useForm<PoiGroupInputSchema>({
    initialValues,
    validationSchema,
    fieldErrors,
  });
  const heartbeatValue = form.watch('heartbeat')?.toString();
  const radiusValue = form.watch('radius')?.toString();

  useEffect(() => {
    if (initialValues) {
      const isHeartbeatDirty = form.formState.dirtyFields['heartbeat'];
      if (isHeartbeatDirty && initialValues.heartbeat && heartbeatValue === '') {
        form.setError('heartbeat', {
          type: 'manual',
          message: `To reset this field enter ${defaultValues?.heartbeat}.`,
        });
      }
    }
  }, [heartbeatValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (initialValues) {
      const isRadiusDirty = form.formState.dirtyFields['radius'];
      if (isRadiusDirty && initialValues.radius && radiusValue === '') {
        form.setError('radius', { type: 'manual', message: `To reset this field enter ${defaultValues?.radius}.` });
      }
    }
  }, [radiusValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Input type="text" name="name" label="Group name" isRequired={true} />
      <Input type="number" name="heartbeat" label="Heartbeat" placeholder={defaultValues?.heartbeat?.toString()} />
      <Input type="number" name="radius" label="Radius" placeholder={defaultValues?.radius?.toString()} />
      <Flex justifyContent="flex-end" mt={4}>
        <SubmitButton isLoading={isLoading}>Save</SubmitButton>
      </Flex>
    </Form>
  );
};
