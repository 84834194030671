import React, { useState } from 'react';
import { BoxProps, Text } from '@chakra-ui/react';

import { Autocomplete } from './';

import { debounce } from 'utils/utils';
import { PoiType } from 'consts/pois';
import { usePoiSuggestionsQuery } from 'api/pois';
import { PoiGeofenceSchema } from 'api/models/PoiGeofenceSchema';

type PoisAutocompleteProps = BoxProps & {
  onSuggestionClick: (selectedPoi: PoiGeofenceSchema) => void;
  placeholder?: string;
  poiTypes?: PoiType[];
};

export const PoisAutocomplete = ({ onSuggestionClick, placeholder, poiTypes, ...props }: PoisAutocompleteProps) => {
  const [query, setQuery] = useState<string>('');
  const { data, isLoading } = usePoiSuggestionsQuery(query, poiTypes);

  const debouncedSetQuery = debounce(setQuery, 300);

  return (
    <Autocomplete
      items={data}
      placeholder={placeholder}
      renderSuggestion={PoiSuggestion}
      onInputChange={debouncedSetQuery}
      onSuggestionClick={onSuggestionClick}
      isLoading={isLoading}
      {...props}
    />
  );
};

export const PoiSuggestion = (poi: PoiGeofenceSchema) => {
  return (
    <>
      <Text>{poi.name}</Text>
      <Text fontSize="xs">{poi.type}</Text>
    </>
  );
};
