import React, { useEffect, useState } from 'react';
import { Box, BoxProps, Text } from '@chakra-ui/react';
import { format, minutesToHours } from 'date-fns';

const timeOffset = minutesToHours(-new Date().getTimezoneOffset());
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const Clock = (styleProps: BoxProps) => {
  const [date, setDate] = useState<Date>(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(new Date());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box {...styleProps} title={timezone}>
      <Text fontWeight="semibold" align="center">
        {format(date, 'H:mm')}
      </Text>
      <Text fontSize="xs" align="center">{`UTC${timeOffset < 0 ? '' : '+'}${timeOffset}`}</Text>
    </Box>
  );
};
