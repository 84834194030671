import { ReactNode } from 'react';
import { Box, FormControl, Tooltip, VStack } from '@chakra-ui/react';

import { Form } from 'components/forms/Form';
import { Input } from 'components/forms/Input';
import { useForm } from 'hooks/useForm';
import { useMapInstance } from 'hooks/useMapInstance';

import bleIcon from 'assets/icons/circle-ble.svg';
import deviceIcon from 'assets/icons/circle-primary.svg';
import poiIcon from 'assets/icons/poi-marker.svg';

export enum MapInstanceItem {
  bleDevice = 'bleDevice',
  device = 'device',
  poi = 'poi',
}

export interface MapVisibleItemsType extends Record<MapInstanceItem, boolean> {}

export const MapControls = () => {
  const { visibleMapItems, setVisibleMapItems } = useMapInstance();
  const form = useForm<MapVisibleItemsType>({ initialValues: visibleMapItems });

  return (
    <Box position="absolute" right="2" top="46">
      <VStack spacing={0} bgColor="white" borderRadius="base">
        <Form form={form} onSubmit={setVisibleMapItems} submitOnChange>
          <MapControlsItem
            label="BLE devices"
            visible={visibleMapItems.bleDevice}
            icon={<img src={bleIcon} alt="Toggle BLE devices" height={17} width={17} />}
            item={MapInstanceItem.bleDevice}
          />

          <MapControlsItem
            label="devices"
            visible={visibleMapItems.device}
            icon={<img src={deviceIcon} alt="Toggle devices" height={17} width={17} />}
            item={MapInstanceItem.device}
          />

          <MapControlsItem
            label="POis"
            visible={visibleMapItems.poi}
            icon={<img src={poiIcon} alt="Toggle POIs" height={17} width={17} />}
            item={MapInstanceItem.poi}
          />
        </Form>
      </VStack>
    </Box>
  );
};
type MapControlsItemProps = {
  item: MapInstanceItem;
  label: string;
  onToggle?: () => void;
  visible: boolean;
  icon: ReactNode;
};
export const MapControlsItem = ({ onToggle, visible, item, label, icon }: MapControlsItemProps) => {
  return (
    <FormControl display="flex">
      <Tooltip
        label={visible ? 'Hide ' + label : 'Show ' + label}
        title={visible ? 'Hide ' + label : 'Show ' + label}
        placement="right"
        hasArrow
      >
        <Box display="flex" p="2" alignItems="center" aria-label={visible ? 'Hide ' + label : 'Show ' + label}>
          <Box width={6} justifyContent="center">
            {icon}
          </Box>
          <Input pl="2" type="switch" name={item} mb="0" />
        </Box>
      </Tooltip>
    </FormControl>
  );
};
