import React, { ForwardedRef, forwardRef } from 'react';
import { Text, List, ListItem } from '@chakra-ui/react';

import { useSelectedPoi } from 'hooks/useSelectedPoi';

type MultiplePoisPopupProps = {
  poiIds: string[];
};

export const MultiplePoisPopup = forwardRef(({ poiIds }: MultiplePoisPopupProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { setSelectedPoi } = useSelectedPoi();

  return (
    <div ref={ref}>
      <Text fontWeight="semibold" pb={2}>
        POIs list
      </Text>
      <List maxH="320px" overflowY="auto">
        {poiIds.map((poiId) => (
          <ListItem
            key={poiId}
            onClick={() => setSelectedPoi(poiId)}
            p={1}
            cursor="pointer"
            _odd={{ bg: 'gray.100' }}
            _hover={{ bg: 'aquaLight' }}
          >
            {poiId}
          </ListItem>
        ))}
      </List>
    </div>
  );
});

export const MemoizedMultiplePoisPopup = React.memo(MultiplePoisPopup);
