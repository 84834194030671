import React, { ForwardedRef, forwardRef } from 'react';
import { Box, Text, Tooltip } from '@chakra-ui/react';

import { formatDatetime } from 'utils/datetime';
import { DeviceHistoryItemSchema } from 'api/models/DeviceHistorySchema';

type HistoryPointPopupParams = {
  historyPoint?: Partial<DeviceHistoryItemSchema>;
};

export const HistoryPointPopup = forwardRef(
  ({ historyPoint }: HistoryPointPopupParams, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <Box fontSize="sm" ref={ref}>
        <Text fontWeight="semibold">{formatDatetime(historyPoint?.updated_at)}</Text>
        {historyPoint?.sensors?.map((sensor) => (
          <Tooltip label={sensor.description} placement="left" hasArrow>
            <Text>{`${sensor.type}: ${sensor.value?.toFixed(1)} ${sensor.unit}`}</Text>
          </Tooltip>
        ))}
        {historyPoint?.nearest_poi && (
          <>
            <Text mt={2} fontWeight="semibold">
              The nearest POI details
            </Text>
            <Text>Name: {`${historyPoint.nearest_poi.poi.name}`}</Text>
            <Text>
              Type:{' '}
              {`${historyPoint.nearest_poi.poi.type}${
                historyPoint.nearest_poi.poi.subtype ? ` | ${historyPoint.nearest_poi.poi.subtype}` : ''
              }`}
            </Text>
            <Text>
              Location: {`${historyPoint.nearest_poi.poi.location.lat}, ${historyPoint.nearest_poi.poi.location.lon}`}
            </Text>
            <Text>Distance: {`${historyPoint.nearest_poi.distance?.toFixed(2)} km`}</Text>
          </>
        )}
      </Box>
    );
  }
);

export const MemoizedHistoryPointPopup = React.memo(HistoryPointPopup);
