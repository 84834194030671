import { Flex, IconButton, Text } from '@chakra-ui/react';
import { BsTrash } from 'react-icons/bs';

import { UserSchema } from 'api/models/UserSchema';

type UsersItemProps = {
  user: UserSchema;
  onDelete?: (user: UserSchema) => void;
};

export const UsersItem = ({ user, onDelete }: UsersItemProps) => {
  return (
    <Flex
      px={4}
      py={2}
      justifyContent="space-between"
      alignItems="center"
      _odd={{ bg: 'gray.100' }}
      _hover={{ bg: 'aquaLight' }}
    >
      <Text noOfLines={1}>{user.username}</Text>
      {onDelete && (
        <IconButton
          aria-label="Delete user"
          icon={<BsTrash size={16} />}
          size="xs"
          variant="unstyled"
          display="inline-flex"
          onClick={() => onDelete(user)}
        />
      )}
    </Flex>
  );
};
