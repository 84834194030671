import React from 'react';
import { Flex } from '@chakra-ui/react';
import { object, string, SchemaOf } from 'yup';

import { Form } from 'components/forms/Form';
import { Input } from 'components/forms/Input';
import { SubmitButton } from 'components/forms/SubmitButton';

import { useForm } from 'hooks/useForm';
import { EmailChangeInputSchema } from 'api/models/EmailChangeInputSchema';
import { ErrorSchema } from 'api/models/ErrorSchema';

type EmailChangeFormProps = {
  onSubmit: (values: EmailChangeInputSchema) => void;
  isLoading?: boolean;
  fieldErrors?: ErrorSchema<EmailChangeInputSchema>['fields'];
};

const validationSchema: SchemaOf<EmailChangeInputSchema> = object({
  email: string().email().required(),
});

export const EmailChangeForm = ({ onSubmit, isLoading, fieldErrors }: EmailChangeFormProps) => {
  const form = useForm<EmailChangeInputSchema>({ validationSchema, fieldErrors });

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Flex>
        <Input type="text" name="email" placeholder="New email" />
        <SubmitButton ml={2} isLoading={isLoading}>
          Send
        </SubmitButton>
      </Flex>
    </Form>
  );
};
