import React from 'react';
import { Badge, Box, Button, Collapse, Flex, Link, Stack, Tag, Text, Tooltip, useDisclosure } from '@chakra-ui/react';

import { NewNotificationWebhookList } from './NewNotificationWebhookList';
import { CodeBlock } from 'components/CodeBlock';

import { useSelectedDevice } from 'hooks/useSelectedDevice';
import { formatDatetime, fromNow } from 'utils/datetime';
import { getSeverityColor } from 'utils/notifications';
import { NewNotificationSchema } from 'api/models/NewNotificationSchema';

type NewNotificationsListItemProps = {
  notification: NewNotificationSchema;
};

export const NewNotificationsListItem = ({ notification }: NewNotificationsListItemProps) => {
  const { isOpen, onToggle } = useDisclosure();
  const { setSelectedDevice } = useSelectedDevice();
  const { object_id, object, action, severity, webhooks, occurred_at, data } = notification;

  return (
    <Box _last={{ borderBottomWidth: '1px' }}>
      <Box px={4} py={2} borderTopWidth="1px">
        <Flex alignItems="center" justifyContent="space-between">
          <Box>
            <Text pb={1}>
              <Link onClick={() => setSelectedDevice(object_id)} variant="unstyled" fontWeight="semibold">
                {object_id}
              </Link>{' '}
              triggered the <Tag>{`${object} | ${action}`}</Tag> event
            </Text>
            <Stack direction="row">
              <Tooltip label="Severity">
                <Badge colorScheme={getSeverityColor(severity)} variant="outline">
                  {severity}
                </Badge>
              </Tooltip>
              <Text fontSize="xs">
                <Tooltip label={`Occurred at ${formatDatetime(occurred_at)}`}>{`${fromNow(occurred_at)} ago`}</Tooltip>
              </Text>
            </Stack>
          </Box>
          <Button size="xs" variant="outline" colorScheme="primaryScheme" onClick={onToggle}>
            Details
          </Button>
        </Flex>
      </Box>
      <Collapse in={isOpen} unmountOnExit={true}>
        <CodeBlock data={data} mb={4} ml={4} />
        {webhooks?.length > 0 && (
          <>
            <Text mt={2} ml={4} fontWeight="semibold">
              Webhooks
            </Text>
            <NewNotificationWebhookList webhooks={webhooks} />
          </>
        )}
      </Collapse>
    </Box>
  );
};
