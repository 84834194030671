import { useEffect, useRef, useState } from 'react';
import { theme } from 'styles/theme';

import { Popup, PopupType } from '../components/Popup';

import historyPointIcon from 'assets/icons/circle-aqua.svg';

type MapDrawHistoryData<T extends Record<string, any>> = {
  location: T['location'] & { lat: number; lon: number };
};

export const useMapHistory = <T extends MapDrawHistoryData<T>>(
  map: React.MutableRefObject<google.maps.Map | undefined>,
  updateMap: (delay?: boolean) => void,
  isPathVisible: boolean,
  deviceHistory: T[] | null,
  onEmpty?: () => void
) => {
  const points = useRef<google.maps.Marker[]>([]);
  const path = useRef<google.maps.Polyline | null>(null);
  const pointPopup = useRef<PopupType>();
  const pointPopupRef = useRef<HTMLDivElement | null>(null);
  const [point, setPoint] = useState<T>();

  /**
   * Removes history points and path from the map.
   */
  const removePath = () => {
    for (let i = 0; i < points.current.length; i++) {
      points.current[i].setMap(null);
    }
    points.current = [];
    path.current?.setMap(null);
    path.current = null;
  };

  /**
   * Draws path of device historical locations on the map.
   */
  useEffect(() => {
    if (!pointPopup.current) {
      pointPopup.current = new Popup(pointPopupRef.current as HTMLDivElement);
    }
  }, []);

  /**
   * Draws path of device historical locations on the map.
   */
  useEffect(() => {
    if (isPathVisible) {
      removePath();

      if (deviceHistory && deviceHistory.length > 0) {
        const locations: { lat: number; lng: number }[] = [];
        const bounds = new google.maps.LatLngBounds();

        // Draw location points
        deviceHistory.forEach((item) => {
          const {
            location: { lat, lon: lng },
          } = item;
          const coordinates = { lat, lng };
          const marker = new google.maps.Marker({
            icon: {
              url: historyPointIcon,
              scaledSize: new google.maps.Size(12, 12),
              anchor: new google.maps.Point(6, 6),
            },
            position: coordinates,
            map: map.current,
          });

          marker.addListener('click', () => {
            const markerPosition = marker.getPosition() as google.maps.LatLng;
            setPoint(item);
            pointPopup.current?.setMap(map.current || null);
            pointPopup.current?.setPosition(markerPosition);
          });

          points.current.push(marker);
          locations.push(coordinates);
          bounds.extend(coordinates);
        });

        // Draw the path
        path.current = new google.maps.Polyline({
          path: locations,
          geodesic: true,
          strokeColor: theme.colors.aqua,
          strokeOpacity: 1.0,
          strokeWeight: 2,
          icons: [
            {
              icon: {
                path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
              },
              repeat: '10%',
            },
          ],
          map: map.current,
        });

        // Zoom to the path
        map.current?.fitBounds(bounds);
      } else {
        onEmpty?.();
      }
    } else {
      // Get devices and POIs when there is no device history (switch from history view to standard view of the map)
      // Check if the map is available to avoid updating devices and POIs on the app start
      map.current?.getProjection() && updateMap();
      removePath();
    }
  }, [isPathVisible, deviceHistory]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    points,
    path,
    pointPopup,
    point,
    pointPopupRef,
  };
};
export default useMapHistory;
