import { add, format, intervalToDuration } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

/**
 * Convert given date to UTC.
 * @param {Date} date
 * returns {Date}
 */
export const toUtc = (date: Date) => {
  const utcDate = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds()
  );
  return new Date(utcDate);
};

/**
 * Formats given datetime.
 * @param {string} [datetime]
 * @param {number} [timeOffset]
 * @param {string} [pattern]
 * returns {string | undefined}
 */
export const formatDatetime = (
  datetime?: string,
  timeOffset?: number,
  pattern: string = 'yyyy-MM-dd H:mm'
): string | undefined => {
  if (!datetime) return undefined;

  if (timeOffset !== undefined) {
    // Format to the datetime according to the given time shift
    const datetimeString = format(add(utcToZonedTime(datetime, 'UTC'), { hours: timeOffset }), pattern);
    return `${datetimeString} (UTC${timeOffset < 0 ? '' : '+'}${timeOffset})`;
  } else {
    // Format to the local datetime
    return format(new Date(datetime), pattern);
  }
};

/**
 * Computes a time duration from the given date to now.
 * @param {string} datetime
 * returns {string}
 */
export const fromNow = (datetime: string): string => {
  const duration = intervalToDuration({
    start: new Date(datetime),
    end: new Date(),
  });
  const years = duration.years;
  const months = duration.months;

  if (years) return `over ${years} year${years > 1 ? 's' : ''}`;
  else if (months) return `over ${months} month${months > 1 ? 's' : ''}`;

  const days = duration.days;
  const hours = duration.hours;
  const minutes = duration.minutes;
  const seconds = duration.seconds;
  const formattedDays = days ? `${days} day${days > 1 ? 's' : ''} ` : '';
  const formattedHours = hours ? `${hours} hr${hours > 1 ? 's' : ''} ` : '';
  const formattedMinutes = minutes ? `${minutes} min${minutes > 1 ? 's' : ''} ` : '';
  const formattedSeconds = !days && !hours && !minutes && seconds ? `${seconds} sec${seconds > 1 ? 's' : ''} ` : '';

  return `${formattedDays}${formattedHours}${formattedMinutes}${formattedSeconds}`;
};
