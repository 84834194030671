import { BadgeProps } from '@chakra-ui/react';

import { NewNotificationSchema } from 'api/models/NewNotificationSchema';
import { NotificationWebhookSchema } from 'api/models/NotificationWebhookSchema';

export const getStatusColor = (status: NotificationWebhookSchema['status']): BadgeProps['colorScheme'] => {
  switch (status) {
    case 'sent':
      return 'green';
    case 'failed':
      return 'red';
    case 'pending':
    case 'retry':
    default:
      return 'gray';
  }
};

export const getSeverityColor = (severity: NewNotificationSchema['severity']): BadgeProps['colorScheme'] => {
  switch (severity) {
    case 'info':
      return 'blue';
    case 'warning':
      return 'yellow';
    case 'failure':
    case 'fatal':
      return 'red';
    default:
      return 'gray';
  }
};
