import React from 'react';
import { Divider, Flex, Text, ThemingProps } from '@chakra-ui/react';
import { number, object, string, SchemaOf } from 'yup';

import { Form } from 'components/forms/Form';
import { Input } from 'components/forms/Input';
import { PoisAutocomplete } from 'components/Autocomplete/PoisAutocomplete';
import { PlacesAutocomplete } from 'components/Autocomplete/PlacesAutocomplete';
import { Select } from 'components/forms/Select';
import { SubmitButton } from 'components/forms/SubmitButton';

import { useForm } from 'hooks/useForm';
import { PoiType } from 'consts/pois';
import { usePoiGroupOptionsQuery } from 'api/poiGroups';
import { AddressInputSchema } from 'api/models/AddressInputSchema';
import { ErrorSchema } from 'api/models/ErrorSchema';
import { PoiInputSchema } from 'api/models/PoiInputSchema';
import { PoiGeofenceSchema } from 'api/models/PoiGeofenceSchema';

type PoiFormProps = {
  onSubmit: (values: PoiInputSchema) => void;
  initialValues?: PoiInputSchema;
  isLoading?: boolean;
  fieldErrors?: ErrorSchema<PoiInputSchema>['fields'];
  isSystemPoi?: boolean;
  formSize?: ThemingProps['size'];
};

const validationSchema: SchemaOf<PoiInputSchema> = object({
  name: string().required(),
  address: object({
    street_number: string().nullable(),
    street: string().nullable(),
    city: string().nullable(),
    postal_town: string().nullable(),
    postal_code: string().nullable(),
    country: string().nullable(),
  }).nullable(),
  lat: number().typeError('you must specify a number').min(-90).max(90).required(),
  lon: number().typeError('you must specify a number').min(-180).max(180).required(),
  // Transform empty string because of https://github.com/react-hook-form/react-hook-form/issues/908
  radius: number()
    .integer()
    .min(1)
    .transform((cv, ov) => (ov === '' ? undefined : cv)),
  group_id: string().nullable(),
});

export const PoiForm = ({ onSubmit, initialValues, isLoading, fieldErrors, isSystemPoi, formSize }: PoiFormProps) => {
  const form = useForm<PoiInputSchema>({ initialValues, validationSchema, fieldErrors });
  // TODO Don't fetch group options on every mount (to consider https://github.com/tannerlinsley/react-query/discussions/616)
  const { data: groupOptions } = usePoiGroupOptionsQuery();

  const handlePoiSuggestionClick = (selectedPoi: PoiGeofenceSchema) => {
    const formValues: PoiInputSchema = {
      name: selectedPoi.name,
      radius: selectedPoi.radius,
      lat: selectedPoi.location?.lat,
      lon: selectedPoi.location?.lon,
      address: selectedPoi.address ?? initialValues?.address ?? {},
    };

    form.reset(formValues);
  };

  const handlePlaceSuggestionClick = (selectedPlace?: Partial<AddressInputSchema>) => {
    selectedPlace && form.reset(selectedPlace);
  };

  return (
    <>
      {!isSystemPoi && (
        <>
          <PoisAutocomplete
            onSuggestionClick={handlePoiSuggestionClick}
            placeholder="Search for system POI"
            poiTypes={[PoiType.system]}
            mb={2}
          />
          <PlacesAutocomplete onSuggestionClick={handlePlaceSuggestionClick} />
          <Divider my={4} borderColor="gray.400" />
        </>
      )}
      <Form form={form} onSubmit={onSubmit}>
        {!isSystemPoi && (
          <>
            <Input type="text" name="name" label="Name" isLabelOnTop={true} size={formSize} isRequired={true} />
            <Text mb={1} fontSize="sm">
              Address
            </Text>
            <Flex>
              <Input type="text" name="address.street_number" placeholder="No." size={formSize} flex={1} mr={2} />
              <Input type="text" name="address.street" placeholder="Street" size={formSize} flex={3} />
            </Flex>
            <Input type="text" name="address.city" placeholder="City" size={formSize} />
            <Flex>
              <Input type="text" name="address.postal_town" placeholder="Postal town" size={formSize} mr={2} />
              <Input type="text" name="address.postal_code" placeholder="Postal code" size={formSize} />
            </Flex>
            <Input type="text" name="address.country" placeholder="Country" size={formSize} />
            <Input type="number" name="lat" label="Latitude" isLabelOnTop={true} size={formSize} isRequired={true} />
            <Input type="number" name="lon" label="Longitude" isLabelOnTop={true} size={formSize} isRequired={true} />
            <Input type="number" name="radius" label="Radius [m]" isLabelOnTop={true} size={formSize} />
          </>
        )}
        <Select name="group_id" label="Group" isLabelOnTop={true} items={groupOptions} isClearable />
        <Flex justifyContent="flex-end" mt={4}>
          <SubmitButton size={formSize} isLoading={isLoading}>
            Save
          </SubmitButton>
        </Flex>
      </Form>
    </>
  );
};
