import { compile } from 'path-to-regexp';
import qs from 'qs';

type Primitive = string | boolean | number;
type QueryToParse = {
  [key: string]: Primitive | Primitive[] | QueryToParse | QueryToParse[] | null | undefined;
};
type Options = {
  noEncode?: boolean;
};

/**
 * Returns a path with the given parameters.
 * @param {string} url
 * @param {Object} [params]
 * @param {Object} [query]
 * @param {Object} [options]
 * @returns {string}
 */
export const resolveUrl = <P extends object = object>(
  url: string,
  params?: P,
  query?: QueryToParse,
  options?: Options
) => {
  const toPath = compile<P>(url, { encode: options?.noEncode ? undefined : encodeURIComponent });
  const parsedUrl = toPath(params);
  const parsedQuery = qs.stringify(query, {
    addQueryPrefix: true,
    arrayFormat: 'comma',
    skipNulls: true,
  });

  return `${parsedUrl}${parsedQuery}`;
};
