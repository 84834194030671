import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import { useSelectedPoi } from 'hooks/useSelectedPoi';
import { PoiType } from 'consts/pois';
import { PoiGeofenceSchema } from 'api/models/PoiGeofenceSchema';

type PoisListItemProps = {
  poi: PoiGeofenceSchema;
};

export const PoisListItem = ({ poi }: PoisListItemProps) => {
  const { setSelectedPoi } = useSelectedPoi();

  return (
    <Box
      px={4}
      py={2}
      cursor="pointer"
      _odd={{ bg: 'gray.100' }}
      _hover={{ bg: 'aquaLight' }}
      onClick={() => setSelectedPoi(poi)}
    >
      <Text wordBreak="break-all">{poi.name}</Text>
      <Text fontSize="xs">{`${poi.type}${poi.type === PoiType.system ? ` | ${poi.subtype}` : ''}`}</Text>
    </Box>
  );
};
