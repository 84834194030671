import { Flex, IconButton, Text } from '@chakra-ui/react';
import { BsPencil, BsTrash } from 'react-icons/bs';

import { DeviceGroupSchema } from 'api/models/DeviceGroupSchema';

type DeviceGroupsListItemProps = {
  group: DeviceGroupSchema;
  onEdit: (group: DeviceGroupSchema) => void;
  onDelete: (group: DeviceGroupSchema) => void;
};

export const DeviceGroupsListItem = ({ group, onEdit, onDelete }: DeviceGroupsListItemProps) => {
  return (
    <Flex
      px={4}
      py={2}
      width="full"
      alignItems="center"
      justifyContent="space-between"
      _odd={{ bg: 'gray.100' }}
      _hover={{ bg: 'aquaLight' }}
    >
      <Flex flex="1 1 auto" maxWidth="270px">
        <Text noOfLines={1}>{group.name}</Text>
      </Flex>
      <Flex justifyContent="space-between">
        <IconButton
          aria-label="Edit group"
          icon={<BsPencil size={16} />}
          size="xs"
          variant="unstyled"
          display="inline-flex"
          onClick={() => onEdit(group)}
        />
        <IconButton
          aria-label="Delete group"
          icon={<BsTrash size={16} />}
          size="xs"
          variant="unstyled"
          display="inline-flex"
          onClick={() => onDelete(group)}
        />
      </Flex>
    </Flex>
  );
};
