import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { AppHeader } from 'components/templates/Header';

import { useAuth } from 'hooks/useAuth';
import { DeviceFiltersProvider } from 'hooks/useDeviceFilters';
import { DeviceHistoryProvider } from 'hooks/useMapHistory';
import { MapInstanceProvider } from 'hooks/useMapInstance';
import { MapParamsProvider } from 'hooks/useMapParams';
import { PoiFiltersProvider } from 'hooks/usePoiFilters';
import { SelectedBleDeviceProvider } from 'hooks/useSelectedBleDeviceNotice';
import { SelectedDeviceProvider } from 'hooks/useSelectedDevice';
import { SelectedPoiProvider } from 'hooks/useSelectedPoi';
import { routes } from 'consts/routes';

export const AppLayout = () => {
  const location = useLocation();
  const { isLoggedIn } = useAuth();

  if (!isLoggedIn) {
    return <Navigate to={routes.AUTH.LOGIN} state={{ from: location.pathname }} replace />;
  }

  return (
    <MapInstanceProvider>
      <MapParamsProvider>
        <DeviceFiltersProvider>
          <PoiFiltersProvider>
            <SelectedDeviceProvider>
              <SelectedPoiProvider>
                <SelectedBleDeviceProvider>
                  <DeviceHistoryProvider>
                    <AppHeader />
                    <Outlet />
                  </DeviceHistoryProvider>
                </SelectedBleDeviceProvider>
              </SelectedPoiProvider>
            </SelectedDeviceProvider>
          </PoiFiltersProvider>
        </DeviceFiltersProvider>
      </MapParamsProvider>
    </MapInstanceProvider>
  );
};
