import React, { ForwardedRef, forwardRef } from 'react';
import { Text, List, ListItem } from '@chakra-ui/react';

import { useSelectedBleDevice } from 'hooks/useSelectedBleDeviceNotice';

type MultipleBleDevicesPopupProps = {
  bleDeviceIds: string[];
};

export const MultipleBleDevicesPopup = forwardRef(
  ({ bleDeviceIds }: MultipleBleDevicesPopupProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { setSelectedBleDevice } = useSelectedBleDevice();

    return (
      <div ref={ref}>
        <Text fontWeight="semibold" pb={2}>
          Devices list ble
        </Text>
        <List maxH="320px" overflowY="auto">
          {bleDeviceIds.map((deviceId) => (
            <ListItem
              key={deviceId}
              onClick={() => setSelectedBleDevice(deviceId)}
              p={1}
              cursor="pointer"
              _odd={{ bg: 'gray.100' }}
              _hover={{ bg: 'aquaLight' }}
            >
              {deviceId}
            </ListItem>
          ))}
        </List>
      </div>
    );
  }
);

export const MemoizedMultipleBleDevicesPopup = React.memo(MultipleBleDevicesPopup);
