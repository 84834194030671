import React from 'react';
import { Badge, Box, Flex, Text, Tooltip } from '@chakra-ui/react';

import { CodeBlock } from 'components/CodeBlock';

import { formatDatetime, fromNow } from 'utils/datetime';
import { getStatusColor } from 'utils/notifications';
import { NotificationWebhookSchema } from 'api/models/NotificationWebhookSchema';

type NewNotificationWebhookListItemProps = {
  webhook: NotificationWebhookSchema;
};

export const NewNotificationWebhookListItem = ({ webhook }: NewNotificationWebhookListItemProps) => {
  const { sent_at, updated_at, status, ...rest } = webhook;

  return (
    <>
      <CodeBlock data={rest} ml={4} />
      <Flex ml={4} mb={4} _last={{ marginBottom: 0 }}>
        <Tooltip label="Status">
          <Box>
            <Badge colorScheme={getStatusColor(status)} variant="solid">
              {status}
            </Badge>
          </Box>
        </Tooltip>
        {sent_at && (
          <Tooltip label={`Sent at ${formatDatetime(sent_at)}`}>
            <Text>
              <Text as="span" ml={2} fontSize="xs" fontWeight="semibold">
                Sent
              </Text>
              <Text as="span" ml={1} fontSize="xs">
                {fromNow(sent_at)} ago
              </Text>
            </Text>
          </Tooltip>
        )}
        {!sent_at && updated_at && (
          <Tooltip label={`Updated at ${formatDatetime(sent_at)}`}>
            <Text>
              <Text as="span" ml={2} fontSize="xs" fontWeight="semibold">
                Updated
              </Text>
              <Text as="span" ml={1} fontSize="xs">
                {fromNow(updated_at)} ago
              </Text>
            </Text>
          </Tooltip>
        )}
      </Flex>
    </>
  );
};
