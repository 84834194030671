import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Text } from '@chakra-ui/react';

import { useSelectedDevice } from 'hooks/useSelectedDevice';
import { useSelectedPoi } from 'hooks/useSelectedPoi';
import { routes } from 'consts/routes';

type LogoProps = {
  onClick?: () => void;
};

export const AuthLogo = ({ onClick }: LogoProps) => {
  return (
    <Box pl="2" borderLeft="1px" borderColor="white" color="white">
      <Text fontSize="sm" fontWeight="semibold" letterSpacing="widest" cursor={onClick && 'pointer'} onClick={onClick}>
        Tracking Control Center
      </Text>
    </Box>
  );
};

export const AppLogo = () => {
  const navigate = useNavigate();
  const { deviceId, poiId, bleDeviceId } = useParams<{ deviceId: string; poiId: string; bleDeviceId: string }>();
  const { selectedDevice, setSelectedDevice } = useSelectedDevice();
  const { selectedPoi, setSelectedPoi } = useSelectedPoi();

  const handleLogoClick = () => {
    if (deviceId) {
      selectedDevice && setSelectedDevice(null);
    } else if (poiId) {
      selectedPoi && setSelectedPoi(null);
    } else if (bleDeviceId) {
      bleDeviceId && navigate(routes.BLE_DEVICES.ROOT);
    }
  };

  return <AuthLogo onClick={handleLogoClick} />;
};
