import React from 'react';
import { Flex } from '@chakra-ui/react';
import { object, string, SchemaOf, ref } from 'yup';

import { Form } from 'components/forms/Form';
import { Input } from 'components/forms/Input';
import { SubmitButton } from 'components/forms/SubmitButton';

import { useForm } from 'hooks/useForm';
import { passwordPolicy } from 'consts/auth';
import { PasswordChangeInputSchema } from 'api/models/PasswordChangeInputSchema';
import { ErrorSchema } from 'api/models/ErrorSchema';

type PasswordChangeFormProps = {
  onSubmit: (values: PasswordChangeInputSchema, onSuccess?: () => void) => void;
  isLoading?: boolean;
  fieldErrors?: ErrorSchema<PasswordChangeInputSchema>['fields'];
};

const validationSchema: SchemaOf<PasswordChangeInputSchema> = object({
  old_password: string().required(),
  new_password: string().matches(passwordPolicy.regExp, passwordPolicy.errorMsg).required(),
  matching_new_password: string()
    .oneOf([ref('new_password')], 'Passwords must match')
    .required(),
});

export const PasswordChangeForm = ({ onSubmit, isLoading, fieldErrors }: PasswordChangeFormProps) => {
  const form = useForm<PasswordChangeInputSchema>({ validationSchema, fieldErrors });

  return (
    <Form form={form} onSubmit={(values) => onSubmit(values, () => form.reset())}>
      <Input type="password" name="old_password" label="Old password" isLabelOnTop={true} />
      <Input type="password" name="new_password" label="New password" isLabelOnTop={true} />
      <Input type="password" name="matching_new_password" label="Confirm new password" isLabelOnTop={true} />
      <Flex justifyContent="flex-end" mt={4}>
        <SubmitButton isLoading={isLoading}>Save</SubmitButton>
      </Flex>
    </Form>
  );
};
