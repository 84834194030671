export const routes = {
  ROOT: '/',
  AUTH: {
    LOGIN: '/auth/login',
    PASSWORD_SETUP: '/auth/password-setup',
    MFA_SETUP: '/auth/mfa-setup',
    MFA_VERIFICATION: '/auth/mfa-verification',
    PASSWORD_RESET: '/auth/password-reset',
    PASSWORD_RESET_CONFIRMATION: '/auth/password-reset/confirmation',
  },
  DEVICES: {
    ROOT: '/devices',
    DETAILS: '/devices/:deviceId',
    SETTINGS: '/devices/:deviceId/settings',
    FLIGHTS: '/devices/:deviceId/flights',
    HISTORY: '/devices/:deviceId/history',
    BLE_DEVICE_NOTICES: '/devices/:deviceId/ble-device-notices',
  },
  DEVICE_GROUPS: {
    ROOT: '/device-groups',
  },
  POIS: {
    ROOT: '/pois',
    DETAILS: '/pois/:poiId',
  },
  POI_GROUPS: {
    ROOT: '/poi-groups',
  },
  BLE_DEVICES: {
    ROOT: '/ble-devices',
    NOTICES: '/ble-devices/notices',
    DETAILS: '/ble-devices/:bleDeviceId',
    SETTINGS: '/ble-devices/:bleDeviceId/settings',
  },
  BLE_DEVICES_CONFIGURATIONS: {
    ROOT: '/ble-devices-configurations/',
  },
};
