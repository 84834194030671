import React from 'react';
import { FieldError } from 'react-hook-form';
import {
  FormControl as ChakraFormControl,
  FormControlProps as ChakraFormControlProps,
  FormErrorMessage,
  FormLabel,
  Text,
} from '@chakra-ui/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

type FormControlProps = ChakraFormControlProps & {
  children: React.ReactNode;
  error?: FieldError;
  label?: string;
  isLabelOnTop?: boolean;
};

const StyledFormControl = styled(ChakraFormControl, {
  shouldForwardProp: (propName) => propName !== 'isLabelOnTop',
})`
  ${(props) =>
    !props.isLabelOnTop &&
    css`
      > div {
        display: flex;
        align-items: center;
      }
      label {
        flex: 1 0 30%;
        margin-bottom: 0;
      }
      label + * {
        width: 100%;
      }
    `}
`;

export const FormControl = ({ children, error, label, isLabelOnTop, isRequired, ...props }: FormControlProps) => {
  return (
    <StyledFormControl isInvalid={!!error} isLabelOnTop={isLabelOnTop} mb={2} {...props}>
      <div>
        {label && (
          <FormLabel mb={1} fontSize="sm">
            {label}
            {isRequired && (
              <Text as="span" marginInlineStart={1} color="red.500">
                *
              </Text>
            )}
          </FormLabel>
        )}
        {children}
      </div>
      <FormErrorMessage display="flex" justifyContent="flex-end" mt={1} fontSize="xs">
        {error?.message}
      </FormErrorMessage>
    </StyledFormControl>
  );
};
