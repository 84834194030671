import React, { useEffect } from 'react';
import { Button } from '@chakra-ui/react';

import { RadioGroup } from './RadioGroup';
import { Form } from 'components/forms/Form';

import { useForm } from 'hooks/useForm';
import { DeviceFiltersSchema } from 'api/models/DeviceFiltersSchema';

type DeviceFiltersFormProps = {
  onSubmit: (values: DeviceFiltersSchema) => void;
  onReset: () => void;
  initialValues?: DeviceFiltersSchema;
};

const defaultValues: DeviceFiltersSchema = { activity: '', battery: '' };

export const DeviceFiltersForm = ({ onSubmit, onReset, initialValues }: DeviceFiltersFormProps) => {
  const form = useForm<DeviceFiltersSchema>({ defaultValues });

  const handleResetForm = () => {
    form.reset(defaultValues);
    onReset();
  };

  useEffect(() => {
    // Set initial values only once
    initialValues && form.reset(initialValues);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form form={form} onSubmit={onSubmit} submitOnChange={true}>
      <Button
        colorScheme="primaryScheme"
        variant="outline"
        size="xs"
        position="absolute"
        right={2}
        onClick={handleResetForm}
      >
        Reset
      </Button>
      <RadioGroup
        name="activity"
        label="Last update"
        options={[
          { label: '<15 mins', value: '28' },
          { label: '<45 mins', value: '45' },
          { label: '<12 hrs', value: '720' },
          { label: '<24 hrs', value: '1440' },
        ]}
        position="static"
      />
      <RadioGroup
        name="battery"
        label="Battery level"
        options={[
          { label: '>1%', value: '2' },
          { label: '>5%', value: '6' },
        ]}
        position="static"
      />
    </Form>
  );
};
