import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';

import { urls } from './urls';
import { axios } from './axios';
import { resolveUrl } from 'utils/resolveUrl';
import { BleDeviceSchema } from './models/BleDeviceSchema';
import { BleDeviceNoticesSchema } from './models/BleDeviceNoticesSchema';
import { BleDeviceSuggestionsSchema } from './models/BleDeviceSuggestionsSchema';
import { DateRangeSchema } from './models/DateRangeSchema';
import { BleDeviceInputSchema } from './models/BleDeviceInputSchema';
import { ErrorSchema } from './models/ErrorSchema';
import { BleDeviceResponseSchema } from './models/BleDeviceResponseSchema';
import { BleDeviceNoticeSchema } from './models/BleDeviceNoticeSchema';

const useBleDeviceQuery = (mac: string, onSuccess?: (data: BleDeviceSchema) => void) => {
  return useQuery<BleDeviceResponseSchema>(
    [urls.BLE_DEVICES.BASE, mac],
    async () => await axios.get(resolveUrl(urls.BLE_DEVICES.BASE, {}, { macs: [mac] })),
    {
      enabled: !!mac,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        /* We are using GET on the list of devices with filter on single mac  */
        onSuccess?.(data?.ble_devices?.[0]);
      },
    }
  );
};

const useBleDevicesQuery = (macs?: string[], onSuccess?: (data: BleDeviceResponseSchema) => void) => {
  return useQuery<BleDeviceResponseSchema>(
    [urls.BLE_DEVICES.BASE, macs?.join(',')],
    async () => await axios.get(resolveUrl(urls.BLE_DEVICES.BASE, {}, { macs })),
    {
      enabled: !!macs?.length,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        onSuccess?.(data);
      },
    }
  );
};

const useBleDevicePatch = (bleDeviceId?: string, onSuccess?: () => void) => {
  const client = useQueryClient();
  return useMutation<BleDeviceSchema, ErrorSchema, BleDeviceInputSchema>(
    (data: BleDeviceInputSchema) => axios.patch(resolveUrl(urls.BLE_DEVICES.DEVICE, { bleDeviceId }), data),
    {
      onSuccess: () => {
        client.invalidateQueries([urls.BLE_DEVICES.BASE, [bleDeviceId]]);
        onSuccess?.();
      },
    }
  );
};

const useBleDevicesNoticesQuery = (range?: DateRangeSchema, onSucces?: (data: BleDeviceNoticeSchema[]) => void) => {
  return useInfiniteQuery<BleDeviceNoticesSchema>(
    [urls.BLE_DEVICES.NOTICES, range],
    async ({ pageParam = 0 }) =>
      await axios.get(resolveUrl(urls.BLE_DEVICES.NOTICES, {}, { page: pageParam, ...range })),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      getNextPageParam: (lastPageData) => {
        const pagesNumber = Math.ceil(lastPageData.total / lastPageData.limit);
        const nextPage = lastPageData.page + 1;
        onSucces?.(lastPageData.notices);
        return nextPage < pagesNumber ? nextPage : undefined;
      },
    }
  );
};

const useBleDeviceNoticesQuery = (bleDeviceId?: string, range?: DateRangeSchema) => {
  return useInfiniteQuery<BleDeviceNoticesSchema>(
    [urls.BLE_DEVICES.DETAILS, bleDeviceId, range],
    async ({ pageParam = 0 }) =>
      await axios.get(
        resolveUrl(urls.BLE_DEVICES.DETAILS, { bleDeviceId }, { page: pageParam, ...range }, { noEncode: true })
      ),
    {
      enabled: !!bleDeviceId,
      getNextPageParam: (lastPageData) => {
        const pagesNumber = Math.ceil(lastPageData.total / lastPageData.limit);
        const nextPage = lastPageData.page + 1;
        return nextPage < pagesNumber ? nextPage : undefined;
      },
    }
  );
};

const useBleDeviceSuggestionsQuery = (query?: string) => {
  const queryParams = {
    query,
    fields: ['mac', 'name'],
  };

  return useQuery<BleDeviceSchema[]>(
    [urls.BLE_DEVICES.SUGGESTIONS, queryParams],
    async () => {
      const data: BleDeviceSuggestionsSchema = await axios.get(
        resolveUrl(urls.BLE_DEVICES.SUGGESTIONS, {}, queryParams)
      );
      let results: BleDeviceSchema[] = [];
      data.suggestions.forEach((suggestion) => results.push(suggestion.ble_device));
      return results;
    },
    {
      enabled: !!query,
      refetchOnWindowFocus: false,
    }
  );
};

export {
  useBleDevicesNoticesQuery,
  useBleDeviceNoticesQuery,
  useBleDeviceSuggestionsQuery,
  useBleDeviceQuery,
  useBleDevicesQuery,
  useBleDevicePatch,
};
