import React from 'react';
import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { IoMoon } from 'react-icons/io5';
import { RiBarcodeFill } from 'react-icons/ri';

import { EtdCounter } from 'components/EtdCounter';

import { formatDatetime, fromNow } from 'utils/datetime';
import { FlightSchema } from 'api/models/FlightSchema';
import { DeviceSchema } from 'api/models/DeviceSchema';

type DevicesListItemProps = {
  device: DeviceSchema;
  onClick: (device: DeviceSchema) => void;
};

export const DevicesListItem = ({ device, onClick }: DevicesListItemProps) => {
  const { flights, id, name, updated_at } = device;
  const flight: FlightSchema = flights[0];

  return (
    <Flex
      h={16}
      px={4}
      py={2}
      alignItems="center"
      cursor="pointer"
      _odd={{ bg: 'gray.100' }}
      _hover={{ bg: 'aquaLight' }}
      onClick={() => onClick(device)}
    >
      <Box width="50%" pr={2}>
        {flight ? (
          <Flex alignItems="center">
            <Box pr={4}>
              <EtdCounter etd={flight.etd} fontSize="xl" fontWeight="semibold" />
            </Box>
            <Box>
              <Text>{flight.iata}</Text>
              <Text fontSize="xs">{`${flight.origin_airport_code}-${flight.dest_airport_code}`}</Text>
            </Box>
          </Flex>
        ) : (
          <Text>{name || id}</Text>
        )}
      </Box>
      <Box fontSize="xs">
        {flight && (
          <>
            <Text>
              <Text as="span" fontWeight="semibold">
                ETD:
              </Text>{' '}
              {formatDatetime(flight.etd, flight.origin_timezone, 'd MMM H:mm')}
            </Text>
            <Text>
              <Text as="span" fontWeight="semibold">
                ETA:
              </Text>{' '}
              {formatDatetime(flight.eta, flight.dest_timezone, 'd MMM H:mm')}
            </Text>
          </>
        )}
        {!flight && name && (
          <Text>
            <RiBarcodeFill className="inline-icon" />
            <Text as="span" pl={1}>
              {id}
            </Text>
          </Text>
        )}
        <Text>
          <IoMoon className="inline-icon" />
          <Text as="span" pl={1}>
            {updated_at ? <Tooltip label={formatDatetime(updated_at)}>{fromNow(updated_at)}</Tooltip> : 'N/A'}
          </Text>
        </Text>
      </Box>
    </Flex>
  );
};
