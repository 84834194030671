import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';

import { axios } from './axios';
import { urls } from './urls';
import { useMapParams } from 'hooks/useMapParams';
import { usePoiFilters } from 'hooks/usePoiFilters';
import { resolveUrl } from 'utils/resolveUrl';
import { paginationPageSize } from 'consts/common';
import { PoisGeofenceSchema } from './models/PoisGeofenceSchema';

const usePoisGeofenceQuery = () => {
  const { mapParams } = useMapParams();
  const { poiFilters } = usePoiFilters();
  const params = { ...mapParams, ...poiFilters };

  return useQuery<PoisGeofenceSchema>(
    [urls.POIS.GEOFENCE, params],
    () => axios.get(resolveUrl(urls.POIS.GEOFENCE, {}, params)),
    {
      enabled: !!mapParams,
      keepPreviousData: true,
    }
  );
};

const usePoisGeofencePaginatedQuery = () => {
  const queryClient = useQueryClient();
  const { mapParams } = useMapParams();

  const clearData = () => queryClient.resetQueries(urls.POIS.GEOFENCE, { exact: true });

  const infinityQuery = useInfiniteQuery<PoisGeofenceSchema>(
    urls.POIS.GEOFENCE,
    async ({ pageParam = 1 }) => await axios.get(resolveUrl(urls.POIS.GEOFENCE, {}, { ...mapParams, page: pageParam })),
    {
      enabled: false,
      notifyOnChangeProps: ['data'],
      getNextPageParam: (lastPageData, allPagesData) => {
        const pagesNumber = Math.ceil(lastPageData.count / paginationPageSize);
        const nextPage = allPagesData.length + 1;
        return nextPage < pagesNumber ? nextPage : undefined;
      },
    }
  );

  return {
    ...infinityQuery,
    clearData,
  };
};

export { usePoisGeofenceQuery, usePoisGeofencePaginatedQuery };
