import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import { ErrorMessage } from 'components/ErrorMessage';
import { PasswordResetConfirmationForm } from 'components/forms/PasswordResetConfirmationForm';

import { usePasswordResetConfirmation } from 'api/auth';
import { AuthPasswordResetConfirmationInputSchema } from 'api/models/AuthPasswordResetConfirmationInputSchema';

export const PasswordResetConfirmation = () => {
  const { error, isLoading, mutate } = usePasswordResetConfirmation();

  const handleSubmit = ({ matching_password, ...values }: AuthPasswordResetConfirmationInputSchema) => {
    mutate({ ...values });
  };

  return (
    <>
      <Text mb={2} align="center" fontSize="xl" fontWeight="semibold">
        Confirm password reset
      </Text>
      <Text mb={4} align="center">
        You received an email with a verification code. Enter your new password and confirm with this code.
      </Text>
      <Box p={4} bg="white" boxShadow="base" borderRadius="base">
        <PasswordResetConfirmationForm onSubmit={handleSubmit} isLoading={isLoading} fieldErrors={error?.fields} />
        <ErrorMessage message={error?.error} />
      </Box>
    </>
  );
};
